import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Tabs, Breadcrumb, message, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import Headings from '../../../atoms/Headigs';
import Renew from './Renew';
import ChangePassword from './ChangePassword';
import EnableDisable from './EnableDisable';
import AssignIp from './AssignIp';
import ReleaseIp from './ReleaseIp';
import { LoadingOutlined } from '@ant-design/icons';
import {getResellerAccountDetail} from '../../AccountDetails/ducks/services'
const antIcon = <LoadingOutlined spin />;

import { getVPNAccountDetails } from '../ducks/services';
const items = (accountData, resellerData) => {
  let comp = [
    {
      key: '1',
      label: `Renew`,
      children: <Renew accountData={accountData}></Renew>,
    },
    {
      key: '2',
      label: `Enable/Disable`,
      children: <EnableDisable accountData={accountData}></EnableDisable>,
    },
    {
      key: '3',
      label: `Change Password`,
      children: <ChangePassword accountData={accountData}></ChangePassword>,
    },
    {
      key: '4',
      label: `Assign IP`,
      children: <AssignIp accountData={accountData}></AssignIp>,
    },
    {
      key: '5',
      label: `Release IP`,
      children: <ReleaseIp accountData={accountData}></ReleaseIp>,
    },
  ];
  // return comp
  return resellerData?.['dedicatedProtocolData']
    ? comp
    : comp.filter((item) => item.key !== '5' && item.key !== '4');
};
const ManageVPNAccount = () => {
  const [vpnAccountDetail, setVPNAccountDetails] = useState();
  const [resellerAccountDetails, setResellerAccountDetails] = useState();
  const [load, setLoad] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const params = {
      acc_id: id,
    };
    getVPNAccountDetails(params)
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setVPNAccountDetails(response?.data?.body);
          setLoad(false);
        } else {
          setLoad(false);
          message.error(response?.data?.header?.message);
        }
      })
      .catch((error) => {
        setLoad(false);
        message.error('Something went wrong');
      });
  }, [id]);

  useEffect(() => {
    getResellerAccountDetail()
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          let resellerAccDetail = response?.data?.body;
          setResellerAccountDetails(resellerAccDetail);
        }
      })
      .catch((error) => {
        message.error('Something went wrong');
        setLoad(false);
      });
  }, []);

  return (
    <Card className='design-card'>
      <Row gutter={[24, 30]}>
        {/* <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
            <Breadcrumb.Item href="/active-users">Active Users</Breadcrumb.Item>
            <Breadcrumb.Item>Manage Account</Breadcrumb.Item>
          </Breadcrumb>
        </Col> */}
        <Col span={12}>
          <Headings
            title='Manage User VPN Account'
            titleSize={2}
            description={`Manage vpn account of ${id}`}
            space={5}
            descClass='mb-0 c-gray'
          />
        </Col>
        <Col span={24}>
          <Spin indicator={antIcon} size='large' spinning={load}>
            <Tabs
              className='custom-tabs'
              defaultActiveKey='1'
              items={items(vpnAccountDetail, resellerAccountDetails)}
            ></Tabs>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default ManageVPNAccount;
