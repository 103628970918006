import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, message, Spin } from 'antd';
import { InputField } from '../../../../atoms/FormElement';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  getAccountDedicatedIp,
  releaseDedicatedIp,
} from '../../ducks/services';
const antIcon = <LoadingOutlined spin />;

const ReleaseIp = (props) => {
  const { accountData } = props;
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (accountData) {
      setLoad(true);
      setValue('username', accountData?.userDetails?.username);
      getAccountDedicatedIp(accountData?.userDetails?.username)
        .then((response) => {
          let data = response?.data?.body?.ip;
          setValue('dedicated_ip', data?.ip);
        })
        .catch((error) => message.error('Something went wrong'));
    }
    setLoad(false);
  }, []);

  const onSubmit = (value) => {
    setLoad(true);
    const payload = {
      vpnUsername: value?.username,
      dedicatedIp: value?.dedicated_ip,
    };
    releaseDedicatedIp(payload)
      .then((response) => {
        setLoad(false);
        let data = response?.data?.header;
        if (data.code == 200) {
          message.success(data?.message);
          history.push('/active-users');
        } else {
          message.error(data?.message);
        }
      })
      .catch((error) => {
        setLoad(false);
        message.error('Something went wrong');
      });
  };
  return (
    <Spin indicator={antIcon} size='large' spinning={load}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form layout='vertical' onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[24, 30]}>
              <Col span={12}>
                <InputField
                  label='Username'
                  fieldname='username'
                  control={control}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'username',
                    disabled: true,
                  }}
                  rules={{
                    required: 'username required',
                  }}
                  validate={errors.name && 'error'}
                  validMessage={errors.name && errors.name.message}
                />
              </Col>

              <Col span={12}>
                <InputField
                  label='Dedicated IP'
                  fieldname='dedicated_ip'
                  control={control}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'dedicated ip',
                    disabled: true,
                  }}
                  rules={{
                    required: 'field required',
                  }}
                  validate={errors.dedicated_ip && 'error'}
                  validMessage={
                    errors.dedicated_ip && errors.dedicated_ip.message
                  }
                />
              </Col>
              <Col span={24}>
                <Button type='primary' htmlType='submit' size='large'>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
export default ReleaseIp;
