
const allProtocols = [{
    "1": {
        "id": 1,
        "name": "PPTP",
        "number": 1,
        "multiport_range": "80",
        "slug": "PPTP",
        "attribute_key": "is_multiport:0, multiport_range:0"
    },
    "2": {
        "id": 2,
        "name": "TCP",
        "number": 8,
        "multiport_range": "80, 5500-30000",
        "slug": "TCP",
        "attribute_key": "is_multiport:1, multiport_range:80, 5500-30000"
    },
    "3": {
        "id": 3,
        "name": "UDP",
        "number": 9,
        "multiport_range": "15021, 5500-30000",
        "slug": "UDP",
        "attribute_key": "is_multiport:1, multiport_range:15021, 5500-30000"
    },
    "8": {
        "id": 8,
        "name": "L2TP",
        "number": 2,
        "multiport_range": "",
        "slug": "L2TP",
        "attribute_key": "is_multiport:0, multiport_range:0"
    },
    "9": {
        "id": 9,
        "name": "SSTP",
        "number": 79617,
        "multiport_range": "",
        "slug": "SSTP",
        "attribute_key": "is_multiport:0, multiport_range:0"
    },
    "10": {
        "id": 10,
        "name": "IKEV",
        "number": 3,
        "multiport_range": "",
        "slug": "IKEV",
        "attribute_key": "is_multiport:0, multiport_range:0"
    },
    "11": {
        "id": 11,
        "name": "Softether_SSTP",
        "number": 11,
        "multiport_range": "",
        "slug": "Softether_SSTP",
        "attribute_key": ""
    },
    "12": {
        "id": 12,
        "name": "Proxy",
        "number": 10,
        "multiport_range": "",
        "slug": "Proxy",
        "attribute_key": ""
    },
    "13": {
        "id": 13,
        "name": "IPSEC",
        "number": 12,
        "multiport_range": "",
        "slug": "IPSEC",
        "attribute_key": "is_multiport:0, multiport_range:0"
    },
    "14": {
        "id": 14,
        "name": "WireGuard",
        "number": 14,
        "multiport_range": "",
        "slug": "WireGuard",
        "attribute_key": "grid_dns_not_required:1"
    }
}]
export default allProtocols;
