import * as action_types from "./constants";

const initialState = {
  menu: false,
  drawer_menu: false,
  all_protocols: [],
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.MENU_STAT:
      return { ...state, menu: data };
    case action_types.DRAWER_MENU:
      return { ...state, drawer_menu: data };
    case action_types.ALL_PROTOCOLS:
      return { ...state, all_protocols: data };
    default:
      return state;
  }
};
