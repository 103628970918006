import React, { useState } from "react";
import Dashboard from "../../templates/Dashboard";
import ActiveUsers from "../../modules/ActiveUsers";
import ManageVPNAccount from "../../modules/ActiveUsers/ManageVPNAccount";
import DetailVPNAccount from "../../modules/ActiveUsers/DetailVPNAccount";
import GuestVpnUsers from "../../modules/GuestActiveUsers";
import { useKeycloak } from "@react-keycloak/web";
import { decodeToken } from 'react-jwt';
const Components = {
  ActiveUsers,
  ManageVPNAccount,
  DetailVPNAccount
};

const GuestComponents = {
  GuestVpnUsers
}

export default (props) => {
  const [loading, setLoading] = useState(false);
  const { keycloak, initialized } = useKeycloak();

  let token = decodeToken(keycloak?.token);
  let comp=token?.realm_access?.roles?.includes("paid_reseller") ?"ResellerAccountDetails":"GuestAccountDetails"
  const ActiveAccComp = Components[props.Comp];
  const GuestComp = GuestComponents['GuestVpnUsers']
  return (
    <Dashboard load={loading}>
      {
        token?.realm_access?.roles?.includes("paid_reseller") ? <ActiveAccComp /> :  <GuestVpnUsers />
      }
     
      
    </Dashboard>
  );
};
