import axios from "../../../../configs/axiosIntereptor";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getResellerPreferences = () => {
  return axios.get(`${BASE_URL}/api/app-preferences/`);
};

export const setResellerPreferences = (payload) => {
  return axios.put(`${BASE_URL}/api/app-preferences`, payload);
};

export const updateAppStatus = (payload) => {
  return axios.put(`${BASE_URL}/api/app-status`, payload);
};

export const getAppStatus = () => {
  return axios.get(`${BASE_URL}/api/app-status`);
};


export const getDomainVerifyStatus = (params) => {
  return axios.get(`${BASE_URL}/api/domain/`, {params});
};

export const setDomainVerifyStatus = (params) => {
  return axios.post(`${BASE_URL}/api/domain/`, {params});
};

