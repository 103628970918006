import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Dropdown,
  Button,
  message,
  Input,
  Space,
} from "antd";
import { useDispatch } from "react-redux";
import Headings from "../../atoms/Headigs";
import { Popup } from "../../atoms/Popup";
import ListCard from "../../molecules/ListCard";
import DeleteVPNAccountPopup from "./ManageVPNAccount/Components/DeleteVPNAccountPopUp";
import VPNAccountLogs from "./ManageVPNAccount/Components/VpnLogs";
import { getUserActivity } from "./ducks/services";
import { getResellerDetals } from "./ducks/action";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import moment from "moment";
const { Search } = Input;
const getColumnSearchProps = (dataIndex) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const searchInput = useRef(null);
      return (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
              close();
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                close();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
  };
};
const items = [
  {
    key: "1",
    label: (
      <Button type="link" className="c-black font-500">
        View
      </Button>
    ),
  },
  {
    key: "2",
    label: (
      <Button type="link" className="c-black font-500">
        Manage
      </Button>
    ),
  },
  {
    key: "3",
    label: (
      <Button type="link" className="c-black font-500">
        Delete
      </Button>
    ),
  },
  {
    key: "4",
    label: (
      <Button type="link" className="c-black font-500">
        Sessions
      </Button>
    ),
  },
];

const deleteVPNAccountPopup = ({
  userDetails,
  popUpVisibility,
  setPopUpVisibility,
}) => {
  const popupContent = {
    closable: false,
    visibility: popUpVisibility,
    class: "popup-modal",
    width: 583,
    content: (
      <DeleteVPNAccountPopup
        accountDetails={userDetails}
        onClose={() => setPopUpVisibility(false)}
      />
    ),
  };
  return { ...popupContent };
};

const vpnAccountLogsPopup = ({
  userDetails,
  logsPopUpVisibility,
  setLogsPopUpVisibility,
}) => {
  const popupContent = {
    closable: true,
    visibility: logsPopUpVisibility,
    class: "black-modal",
    width: 1500,
    onCancel: () => setLogsPopUpVisibility(false),
    content: (
      <VPNAccountLogs
        accountDetails={userDetails}
        onClose={() => setLogsPopUpVisibility(false)}
      />
    ),
  };
  return { ...popupContent };
};

const ActiveUsers = () => {
  const [load, setLoad] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [popUpVisibility, setPopUpVisibility] = useState(false);
  const [logsPopUpVisibility, setLogsPopUpVisibility] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [searchVal, setSearchVal] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();

  const popupContent = deleteVPNAccountPopup({
    userDetails,
    popUpVisibility,
    setPopUpVisibility,
  });

  const logspopupContent = vpnAccountLogsPopup({
    userDetails,
    logsPopUpVisibility,
    setLogsPopUpVisibility,
  });

  const ListCol = [
    {
      title: "VPN Account Name",
      dataIndex: "username",
      key: "username",
      sorter: true,
    },
    {
      title: "UUID",
      dataIndex: "uuid",
      key: "uuid",
      sorter: true,
      ...getColumnSearchProps("uuid"),
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD/MMM/YYYY"),
      ...getColumnSearchProps("created_at"),
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      key: "account_type",
      ...getColumnSearchProps("account_type"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (data) => (data == "1" ? "Active" : "Inactive"),
      ...getColumnSearchProps("status"),
    },
    {
      title: "Subscription Type",
      dataIndex: "subscription_type",
      key: "subscription_type",
      ...getColumnSearchProps("subscription_type"),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "left",
      render: (text, record) => {
        return (
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "2") {
                  history.push(`/active-users/manage/${record?.username}`);
                } else if (key === "1") {
                  history.push(`/active-users/detail/${record?.username}`);
                } else if (key === "3") {
                  setUserDetails(record);
                  setPopUpVisibility(true);
                  setLogsPopUpVisibility(false);
                } else if (key === "4") {
                  setUserDetails(record);
                  setLogsPopUpVisibility(true);
                  setPopUpVisibility(false);
                }
              },
            }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const onTableChange = (pagination, filters, sorter) => {
    setLoad(true)
    let order =
      sorter?.order == "descend"
        ? "DESC"
        : sorter?.order == "ascend"
        ? "ASC"
        : "";
    setLoad(true);
    setPage(pagination.current);
    getUserActivity(
      pagination.current,
      sorter?.field ? sorter?.field : "",
      order,
      searchVal
    )
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setRowData(response?.data?.body);
        } else {
        }
      })
      .catch((error) => message.error("Something went wront"));
    setLoad(false);
  };

  const onSearch = (value, event) => {
    setLoad(true);
    getUserActivity(1, "", "", value)
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setRowData(response?.data?.body);
          setLoad(false);
        } else {
          setLoad(false);
          // message.error(response?.data?.header?.message);
        }
      })
      .catch((error) => message.error("Something went wrong"));
    setSearchVal(value);
  };

  useEffect(() => {
    getUserActivity(1, "", "", "")
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setRowData(response?.data?.body);
          setLoad(false);
        } else {
          message.error(response?.data?.header?.message);
          setLoad(false);
        }
      })
      .catch((error) => message.error("Something went wrong"));
    dispatch(getResellerDetals());
  }, []);

  return (
    <>
      <Card className="design-card">
        <Row gutter={[24, 30]} justify={"space-between"}>
          {/* <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
              <Breadcrumb.Item>Active Users</Breadcrumb.Item>
            </Breadcrumb>
          </Col> */}
          <Col span={12}>
            <Headings
              title="VPN Users"
              titleSize={2}
              description={`Manage your VPN Users`}
              space={5}
              descClass="mb-0 c-gray"
            />
          </Col>
          <Col span={8}>
            <Search
              placeholder="search vpn account"
              enterButton="Search"
              size="large"
              onSearch={onSearch}
            />
          </Col>
          <Col span={24}>
            <ListCard
              ListCol={ListCol}
              ListData={rowData?.data}
              onChange={onTableChange}
              listClass="nospace-card"
              loading={load}
              pagination={{
                total: rowData?.total_records,
                defaultCurrent: page,
                showSizeChanger: false,
                pageSize: 10,
              }}
            />
          </Col>
        </Row>
      </Card>
      <Popup {...popupContent} />
      <Popup {...logspopupContent} />
    </>
  );
};

export default ActiveUsers;
