import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Breadcrumb,
  Typography,
  message,
  Slider,
  Divider,
  Space,
  List,
  Avatar,
  Button,
  Spin,
} from "antd";
import Headings from "../../atoms/Headigs";
import { CheckCircleFilled } from "@ant-design/icons";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./checkoutForm";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import axios from "../../../configs/axiosIntereptor";
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "../../../assets/utils/common_functions";

const { Text, Title, Paragraph, Link } = Typography;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const STRIPE_KEY = process.env.STRIPE_KEY;

const PaymentInformation = (props) => {
  const [clientSecret, setClientSecret] = useState("");
  const [amount, setAmount] = useState();
  const antIcon = <LoadingOutlined spin />;
  const stripeAmount = useSelector((state) => state.stripeReducer.amount);
  // const userBase = useSelector((state) => state.stripeReducer.users);
  const userBase = parseInt(getLocalStorage("stripeUsers", true));
  const history = useHistory();

  const orderSummaryList = [
    "Customizable White-Label Design Templates",
    "Backend Infrastructure",
    "SDK Integration",
    "Free App Store Setup",
    "Billing / End-client Area",
    "Compliance and Data Privacy",
    "Dedicated Account Manager",
    "24 / 7 Support",
  ];

  const stripePromise = loadStripe(`${STRIPE_KEY}`);

  const body = {
    users: userBase,
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  useEffect(() => {
    const fetchClientSecret = async () => {
      axios
        .post(`${BASE_URL}/api/payment/create-payment-intent`, body)
        .then((response) => {
          setClientSecret(response?.data?.body?.client_secret);
          setAmount(response?.data?.body?.amount);
        })
        .catch((error) => {
          if (error.response.status === 500) {
            message.error("Something went wrong on server");
            history.push("/payment-estimate");
          } else {
            message.error(error.response.data.header.message);
            history.push("/payment-estimate");
          }
        });
    };

    if (body.users >= 500) {
      fetchClientSecret();
    } else {
      history.push("/payment-estimate");
      message.error("Please refill your information again.");
    }
  }, []);

  return (
    <Card className="design-card-payment" bordered={true}>
      <Row gutter={[24, 24]}>
        <Col span={11}>
          {/* <Col> */}
          <Headings
            title="Order Summary"
            titleSize={2}
            space={5}
            descClass="smallFont12"
          />

          <Title className="text-step payment-text-color" level={1}>
            {amount ? (
              `$${amount/100}`
            ) : (
              <Spin indicator={antIcon} size="small" spinning={true} />
            )}
          </Title>

          <List
            split={false}
            itemLayout="horizontal"
            dataSource={orderSummaryList}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<CheckCircleFilled style={{ color: "#6F5BF6" }} />}
                  description={item}
                />
              </List.Item>
            )}
          />
          {/* </Col> */}
        </Col>
        <Col span={2}>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col span={11}>
          <Col>
            <Headings
              title="Payment Information"
              titleSize={2}
              description={`Enter card details.`}
              space={5}
              descClass="fontSize16Medium"
            />
          </Col>
          {clientSecret ? (
            <Elements options={options} stripe={stripePromise}>
              <CheckoutForm clientSecret={clientSecret} />
            </Elements>
          ) : (
            <Space
              direction="vertical"
              size={"large"}
              className="w-100 h-auto py-5"
              align="center"
            >
              <Spin indicator={antIcon} size="large" spinning={true} />
            </Space>
          )}

          <Space className="w-100" direction="vertical" size={10}>
            <Paragraph className="c-gray center-align-text px-2 smallFont12 mt-2 ">
              By Continuing, You Agree To Our{" "}
              <Text className="text-base smallFont12">
                <Link href="http://purewl.com/terms" target="_blank">
                  Terms And Conditions
                </Link>
              </Text>
              ,{" "}
              <Text className="text-base smallFont12">
                <Link href="http://purewl.com/privacy-policy" target="_blank">
                  Privacy Policy.
                </Link>
              </Text>
            </Paragraph>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};
export default PaymentInformation;
