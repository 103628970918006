import React, { useState, useEffect } from "react";
import {Card, Row, Col, Breadcrumb, Form, Button, Spin, message, notification} from "antd";
import Headings from "../../atoms/Headigs";
import { useForm } from "react-hook-form";
import { InputField, InputPassword } from "../../atoms/FormElement";
import { getResellerProfile, updateResellerProfile } from "./ducks/services";
import { LoadingOutlined } from "@ant-design/icons";
import {useSelector} from "react-redux";
const antIcon = <LoadingOutlined spin />;

const Profile = () => {
  const [load, setLoad] = useState(true);
  const userData = useSelector((state) => state.userReducer.userData);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const confPassword = watch("password");

  useEffect(() => {
    getResellerProfile()
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          const profileData = response?.data?.body;
          setValue("name", profileData?.name);
          setValue("description", profileData?.description);
          setValue("firstname", profileData?.firstName);
          setValue("lastname", profileData?.lastName);
          setValue("email", profileData?.email);
          setValue(
            "phonenumber",
            profileData?.phonenumber ? profileData?.phonenumber : ""
          );
          setLoad(false);
        } else {
          message.error(response?.headers?.message);
          setLoad(false);
        }
      })
      .catch((error) => {
        setLoad(false);
        message.error("Something went down");
      });
  }, []);

  const onSubmit = (values) => {
    setLoad(true);
    const payload = { ...values };
    updateResellerProfile(payload)
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          message.success("Profile updated successfully")
        } else {
          notification.error({
            message: 'Update Profile Failed',
            description: 'The provided information is incorrect',
            placement: 'bottomRight',
          });
        }
        setLoad(false);
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: error?.response?.data?.header?.message ?? error.message,
          placement: 'bottomRight',
        });
        setLoad(false);
      });
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Card className="design-card">
        <Row gutter={[24, 30]}>
          {/* <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item>Profile</Breadcrumb.Item>
            </Breadcrumb>
          </Col> */}
          <Col span={24}>
            <Headings
              title="Profile"
              titleSize={2}
              description={`Manage your organization from a single platform`}
              space={5}
              descClass="mb-0 c-gray"
            />
          </Col>
          <Col span={24}>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Row gutter={[24, 30]}>
                <Col span={12}>
                  <InputField
                    label="Username"
                    fieldname="name"
                    control={control}
                    initValue=""
                    iProps={{
                      size: "large",
                      placeholder: "username",
                      disabled:true
                    }}
                    rules={{
                      required: "username required",
                    }}
                    validate={errors.username && "error"}
                    validMessage={errors.username && errors.username.message}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Description"
                    fieldname="description"
                    control={control}
                    initValue=""
                    iProps={{
                      size: "large",
                      placeholder: "description",
                    }}
                    rules={{
                      required: "descriptioin required",
                    }}
                    validate={errors.description && "error"}
                    validMessage={
                      errors.description && errors.description.message
                    }
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Firstname"
                    fieldname="firstname"
                    control={control}
                    initValue=""
                    iProps={{
                      size: "large",
                      placeholder: "firstname",
                    }}
                    rules={{
                      required: "firstname required",
                    }}
                    validate={errors.firstname && "error"}
                    validMessage={errors.firstname && errors.firstname.message}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Lastname"
                    fieldname="lastname"
                    control={control}
                    initValue=""
                    iProps={{
                      size: "large",
                      placeholder: "lastname",
                    }}
                    rules={{
                      required: "lastname required",
                    }}
                    validate={errors.lastname && "error"}
                    validMessage={errors.lastname && errors.lastname.message}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Email"
                    fieldname="email"
                    control={control}
                    initValue=""
                    iProps={{
                      size: "large",
                      placeholder: "email",
                      disabled: true,
                    }}
                    rules={{
                      required: "email required",
                    }}
                    validate={errors.email && "error"}
                    validMessage={errors.email && errors.email.message}
                  />
                </Col>
                <Col span={12}>
                </Col>
                <Col span={12}>
                  <InputPassword
                    label="Password"
                    fieldname="password"
                    control={control}
                    initValue=""
                    iProps={{
                      size: "large",
                      placeholder: "Password",
                    }}
                    rules={{
                      required: "password required",
                    }}
                    validate={errors.password && "error"}
                    validMessage={errors.password && errors.password.message}
                  />
                </Col>
                <Col span={12}>
                  <InputPassword
                    label="Confirm Password"
                    fieldname="confirmpassword"
                    control={control}
                    initValue=""
                    iProps={{
                      size: "large",
                      placeholder: "Confirm Password",
                    }}
                    rules={{
                      required: "confirm password required",
                      validate: (value) =>
                        value === confPassword || "The passwords do not match",
                    }}
                    validate={errors.confirmpassword && "error"}
                    validMessage={
                      errors.confirmpassword && errors.confirmpassword.message
                    }
                  />
                </Col>
            { userData?.totp ?
                      <Col span={12}>
                        <InputField
                            label="OTP"
                            fieldname="totp"
                            control={control}
                            initValue=""
                            iProps={{
                              size: "small",
                              placeholder: "otp",
                            }}
                            validate={errors.totp && "error"}
                            validMessage={errors.totp && errors.totp.message}
                        />
                      </Col> :<Col span={12}></Col>}

              </Row>
              <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{marginTop:"9px",width:"49%"}}>
                Update
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default Profile;
