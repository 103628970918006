import axios from "../../../../configs/axiosIntereptor";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getResellerAccountDetail = () => {
  return axios.get(`${BASE_URL}/api/profile/account-details`);
};

export const getResellersAllowedCitiesProtocols = (payload) => {
  return axios.post(`${BASE_URL}/api/profile/city-protocols`, payload);
};
