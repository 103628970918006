import { combineReducers } from "redux";
import global from "../../app/Pages/Dashboard/ducks/reducers";
import activeUsers from "../../app/modules/ActiveUsers/ducks/reducer";
import additionalDetail from "../../app/modules/AdditionalDetail/ducks/reducer";
import stripeReducer from '../../app/Pages/PaymentEstimate/ducks/reducers'
import userReducer from '../../app/modules/Login/ducks/reducer'
const rootReducer = combineReducers({
  global,
  activeUsers,
  additionalDetail,
  stripeReducer,
  userReducer
});
export default rootReducer;
