import React from "react";
import axios from "axios";
import { getLocalStorage } from "../assets/utils/common_functions";
const baseUrl = process.env.REACT_APP_BASE_URL;
const KEY_CLOAK_ENV = process.env.KEY_CLOAK_ENV;
const KEY_CLOAK_BASEURL = process.env.KEY_CLOAK_BASEURL;
const KEY_CLOAK_REALM = process.env.KEY_CLOAK_REALM;

const authInterceptors = axios.create({
  baseURL: baseUrl,
});
authInterceptors.interceptors.request.use(
  async (config) => {
    // const authInfo = JSON.parse(localStorage.getItem("akjdnd"));
    const authInfo = getLocalStorage('authToken', true)
  
    if (authInfo) {
      config.headers["Authorization"] = `Bearer ${authInfo}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
authInterceptors.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error?.response?.status == 440) {
      localStorage.clear();

       window.location.replace(
        `${KEY_CLOAK_BASEURL}/realms/${KEY_CLOAK_REALM}/protocol/openid-connect/auth?client_id=purewl-console&scope=openid%20profile%20email&redirect_uri=${KEY_CLOAK_ENV}&response_type=code`
      );


    }
    return Promise.reject(error);
  }
);
export default authInterceptors;
