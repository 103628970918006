import axios from "../../../../configs/axiosIntereptor";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getResellerNetworkStats = () => {
  return axios.get(`${BASE_URL}/api/reports/network-stats`);
};

export const getSourceCountry = () => {
  return axios.get(`${BASE_URL}/api/reports/source-country`);
};

export const getDestinationCountry = () => {
  return axios.get(`${BASE_URL}/api/reports/destination-country`);
};
