import React, { useState } from "react";
import Dashboard from "../../templates/Dashboard";
import SubUsers from "../../modules/SubUsers";
import GuestReports from "../../modules/GuestReports"
import { useKeycloak } from "@react-keycloak/web";
import { decodeToken } from 'react-jwt';
import ManagePermissions from "../../modules/SubUsers/Managepermissions";
const Components = {
    SubUsers,
    GuestReports,
    ManagePermissions
};
export default (props) => {
    const { keycloak, initialized } = useKeycloak();
    let token = decodeToken(keycloak?.token);
    let comp=token?.realm_access?.roles?.includes("paid_reseller") ?"SubUsers":"GuestReports"
    const [loading, setLoading] = useState(false);
    const SubUsersComp = Components[props.Comp];
    return (
        <Dashboard load={loading}>
            <SubUsersComp />
        </Dashboard>
    );
};

