import React, { useEffect, useState } from "react";
import { Spin, Row, Col, Space, Typography, message } from "antd";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getResellerPreferences,
  setResellerPreferences,
} from "../AdditionalDetail/ducks/services";
import { updateStorageJSON } from "../AdditionalDetail/ducks/action";
import Login from "../../templates/Login";
import axios from "../../../configs/axiosIntereptor";
import { removeLocalStorage } from "../../../assets/utils/common_functions";
const { Text } = Typography;
const antIcon = <LoadingOutlined spin style={{ fontSize: "75px" }} />;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const ATOM_API_GATEWAY_URL = process.env.ATOM_API_GATEWAY_URL;
const PaymentSuccess = (props) => {
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};
  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });
  const body = {
    payment_intent: queryParams?.payment_intent,
    payment_intent_client_secret: queryParams?.payment_intent_client_secret,
    status: queryParams?.redirect_status,
  };
  const resellerBody = {
    sTitle: "new vpn",
    sDescription: "atom reseller",
    sSubscriptionType: "permanent",
  };
  const appStatusBody = {
    key: "payment",
    status: "complete",
  };
  useEffect(() => {
    if (queryParams) {
      setLoad(true);
      axios.post(`${BASE_URL}/api/payment/store`, body).then((response) => {
        if (response?.data?.header?.code === 200) {
          // axios.get('https://mocki.io/v1/46541899-28ca-4a6c-825a-2897476ca246')
          axios
            .post(`${BASE_URL}/api/reseller`, resellerBody)
            .then((response) => {
              if (response?.data?.header?.code === 200) {
                getResellerPreferences().then((res) => {
                  if (res?.data?.header?.code == 200) {
                    let updateRes = JSON.parse(res?.data?.body?.json);
                    var secretKeyMap = {};
                    response?.data?.body?.SecretKeys.forEach(function (item) {
                      if (item.remark) {
                        secretKeyMap[item.remark] = item.secret_key;
                      }
                    });
                    for (var platform in updateRes.keys) {
                      if (
                        updateRes.keys.hasOwnProperty(platform) &&
                        secretKeyMap[platform]
                      ) {
                        updateRes.keys[platform].atom_key =
                          secretKeyMap[platform];
                      }
                    }
                    dispatch(updateStorageJSON(updateRes));
                    setResellerPreferences({
                      source: "console",
                      json: JSON.stringify(updateRes),
                    }).then((res) => {
                      if (res?.data?.header?.code == 200) {
                        axios
                          .put(`${BASE_URL}/api/app-status`, appStatusBody)
                          .then((res) => {
                            if (res?.data?.header?.code === 200) {
                              setLoad(false);
                              history.push("/dashboard");
                              removeLocalStorage("stripeUsers", true);
                            }
                          });
                      }
                    });
                  }
                });
              } else if (response?.data?.header?.code == 20100) {
                axios
                  .put(`${BASE_URL}/api/app-status`, appStatusBody)
                  .then((res) => {
                    if (res?.data?.header?.code === 200) {
                      setLoad(false);
                      removeLocalStorage("stripeUsers", true);
                      history.push("/dashboard");
                    }
                  })
                  .catch((error) => {
                    message.error("Something went wrong");
                    removeLocalStorage("stripeUsers", true);
                    history.push("/dashboard");
                  });
              }
            })
            .catch((error) => {
              message.error("Something went wrong");
              removeLocalStorage("stripeUsers", true);
              history.push("/dashboard");
            });
        }
      });
    }
  }, []);
  return (
    <Login load={false}>
      <Row gutter={24}>
        <Col span={24}>
          <Space
            direction="vertical"
            align="center"
            className="w-100"
            size={"large"}
          >
            <Text>Please wait while we configure..</Text>
            <Spin indicator={antIcon} size="large" spinning={load}></Spin>
          </Space>
        </Col>
      </Row>
    </Login>
  );
};
export default PaymentSuccess;
