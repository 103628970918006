import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Breadcrumb, message } from 'antd';
import { useParams } from 'react-router-dom';
import Headings from '../../../atoms/Headigs';
import DetailTag from '../../../atoms/DetailTag';
import {
  EmailSvgIcon,
  ExpireTimeSvgIcon,
  MultiLoginSvgIcon,
  ClockSvgIcon,
} from '../../../atoms/CustomIcons';
import moment from 'moment';
import { getVPNAccountDetails } from '../ducks/services';
const DetailVPNAccount = () => {
  const [vpnDetail, setVPNDetail] = useState();
  const { id } = useParams();

  useEffect(() => {
    const params = {
      acc_id: id,
    };
    getVPNAccountDetails(params)
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setVPNDetail(response?.data?.body);
        } else {
          message.error(response?.data?.header?.message);
        }
      })
      .catch((error) => message.error('Something went wrong'));
  }, [id]);

  return (
    <Card className='design-card'>
      <Row gutter={[24, 30]}>
        {/* <Col span={24}>
          <Breadcrumb>
          <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
          <Breadcrumb.Item href="/active-users">Active Users</Breadcrumb.Item>
            <Breadcrumb.Item>Account Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col> */}
        <Col span={24}>
          <Headings
            title={`User ID: ${id}`}
            titleSize={2}
            description={`Member since ${moment(
              vpnDetail?.userDetails?.created_at
            ).format('LL')}`}
            space={5}
            descClass='mb-0 c-gray'
          />
        </Col>
        <Col span={12}>
          <DetailTag
            key={1}
            tagTitle='Email Address'
            icon={<EmailSvgIcon />}
            tag={vpnDetail?.userDetails?.email}
          />
        </Col>
        <Col span={12}>
          <DetailTag
            key={2}
            icon={<ExpireTimeSvgIcon />}
            tagTitle='Expire At'
            tag={`${moment(vpnDetail?.userDetails?.expires_at).format('LL')}`}
          />
        </Col>
        <Col span={12}>
          <DetailTag
            key={3}
            icon={<MultiLoginSvgIcon />}
            tagTitle='Multi Logins'
            tag={vpnDetail?.preferences?.shared?.multi_login?.value}
          />
        </Col>
        <Col span={12}>
          <DetailTag
            key={4}
            icon={<ClockSvgIcon />}
            tagTitle='Session Limit'
            tag={vpnDetail?.preferences?.shared?.session_limit?.value}
          />
        </Col>
        <Col span={24}>
          <DetailTag
            key={5}
            icon={<ClockSvgIcon />}
            tagTitle='Countries'
            ctag={vpnDetail?.preferences?.shared?.country}
          />
        </Col>
        <Col span={24}>
          <DetailTag
            key={6}
            icon={<ClockSvgIcon />}
            tagTitle='Protocol'
            ptag={vpnDetail?.preferences?.shared?.protocol}
          />
        </Col>
      </Row>
    </Card>
  );
};
export default DetailVPNAccount;
