import React from "react";
import { Card, Row, Col, Image, Typography, Space, Button } from "antd";
import Headings from "../../atoms/Headigs";
import dummyVpnUsers from "../../../assets/img/dummyVpnUsers.png";

const SCHEDULE_CALL_LINK = process.env.SCHEDULE_CALL_LINK

const GuestVpnUsers = () => {
  const { Paragraph } = Typography;
  const handleButtonLink = () => {
    window.open(SCHEDULE_CALL_LINK, '_blank');
  }
  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        <Col span={12}>
          <Headings
            title="Vpn Users"
            titleSize={2}
            description={`Manage your VPN Accounts`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={24} className="dummy-image-container-guest">
          <Image
            className="dummy-image-border"
            preview={false}
            width={1500}
            height={420}
            src={dummyVpnUsers}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Paragraph
          className="c-gray fontSize12Small"
        >
          The VPN Users section is where you can manage and oversee the users of
          your VPN services. Within this section, you can effortlessly monitor
          your VPN accounts, gaining insights into the number of accounts, their
          account types, subscription type, and their current status.
        </Paragraph>
      </Col>
      <Space>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-1-5"
            size="large"
            onClick={() => handleButtonLink()}
          >
            Schedule Call
          </Button>
        </Space>
    </Card>
  );
};

export default GuestVpnUsers;
