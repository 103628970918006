import React from "react";
import { Card, Row, Col, Image, Typography, Space, Button } from "antd";
import Headings from "../../atoms/Headigs";
import dummyConnectedUsers from "../../../assets/img/dummyConnectedUsers.png";

const SCHEDULE_CALL_LINK = process.env.SCHEDULE_CALL_LINK

const GuestConnectedUsers = () => {
  const { Paragraph } = Typography;
  const handleButtonLink = () => {
    window.open(SCHEDULE_CALL_LINK, '_blank');
  }
  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        <Col span={12}>
          <Headings
            title="Connected Users"
            titleSize={2}
            description={`Find out about the connected users on your network, including their details.`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col
          span={24}
          className="dummy-image-container-guest"
        >
          <Image
            className="dummy-image-border"
            preview={false}
            width={1500}
            height={420}
            src={dummyConnectedUsers}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Paragraph
          className="c-gray fontSize12Small"
        >
          The Connected Users section allows you to actively monitor and manage users currently connected to your network. It provides real-time information about your users, their connection status, connected country, and relevant details to ensure network performance.
        </Paragraph>
      </Col>
      <Space>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-1-5"
            size="large"
            onClick={() => handleButtonLink()}
          >
            Schedule Call
          </Button>
        </Space>
    </Card>
  );
};

export default GuestConnectedUsers;
