import React from "react";
import { Layout, Card, Typography, Spin } from "antd";
import Navigation from "../../molecules/Navigation";
import { useMediaQuery } from "react-responsive";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import CHeader from "../../atoms/CHeader";

const { Content, Sider } = Layout;
const antIcon = <LoadingOutlined spin />;

export default (props) => {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1650px)" });
  const menuStat = useSelector((state) => state.global.menu);
  return (
    <Spin indicator={antIcon} size="large" spinning={props.load}>
      <Layout>
        <CHeader />
        <Layout>
          <Sider
            trigger={null}
            width={isBigScreen ? 280 : 240}
            collapsedWidth={90}
            collapsible
            collapsed={menuStat}
          >
            <Card className="side-card" bordered={false}>
              <Navigation />
            </Card>
          </Sider>
          <Layout className="base-layout">
            <Content>
              <Card
                bordered={false}
                style={{
                  overflowX: "hidden",
                  background: "transparent",
                  height: "calc(100vh - 70px)",
                  padding: "5px",
                }}
                bodyStyle={{ padding: 0 }}
              >
                {props.children}
              </Card>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Spin>
  );
};
