import Roles from "../../configs/Roles";

const titles = {
    SubUsers: 'SubUsers'
};

export const SubUsers = [
    {
        icon: 'UsersSvgIcon',
        component: 'SubUsers',
        path: '/acl',
        title: titles.SubUsers,
        key: 'subusers',
        menu: 'Sub Users',
        parent: false,
    },
    {
        component: 'ManagePermissions',
        path: '/sub-users/manage-permissions/:id',
        title: titles.SubUsers,
        key: 'subusers2',
        menu: 'Sub Users',
        parent: false,
        permission:[Roles.RESELLER]

    },
];
