import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tabs, Breadcrumb, message, Spin, Empty } from "antd";
import { BidirectionalBar } from "@ant-design/plots";
import Headings from "../../atoms/Headigs";
import {
  getResellerNetworkStats,
  getSourceCountry,
  getDestinationCountry,
} from "./ducks/services";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined spin />;

const items = (networkStats, sourceDestinationCountry) => {
  const data = [];
  sourceDestinationCountry.forEach((item) => {
    const countryData = data.find(
      (d) => d.country === (item.source || item.destination)
    );
    if (!countryData) {
      data.push({
        country: item.source || item.destination,
        source: item.source ? item.total_users : 0,
        destination: item.destination ? item.total_users : 0,
      });
    } else {
      if (item.source) {
        countryData.source = item.total_users;
      } else if (item.destination) {
        countryData.destination = item.total_users;
      }
    }
  });
  const config = {
    data,
    xField: "country",
    height: "200",
    xAxis: {
      position: "bottom",
    },
    interactions: [
      {
        type: "active-region",
      },
    ],
    yField: ["source", "destination"],
    tooltip: {
      shared: true,
      showMarkers: false,
    },
  };
  let comp = [
    {
      key: "1",
      label: `Connected User Statistics`,
      children: (
        <Card className="side-panel align-center">
          {config?.data?.length ? <BidirectionalBar {...config} /> : <Empty />}
          {/* <BidirectionalBar {...config} />  */}
        </Card>
      ),
    },
    {
      key: "2",
      label: `Network Statistics`,
      children: (
        <Card className="side-panel">
          <iframe
            style={{ width: "100%", height: "100%", border: "none" }}
            src={networkStats?.mixpanel_url}
          />
        </Card>
      ),
    },
  ];
  return networkStats?.mixpanel_url ? comp : [comp[0]];
};

const Reports = () => {
  const [load, setLoad] = useState(true);
  const [networkStats, setNetworkStats] = useState();
  const [sourceDestinationCountry, setSourceDestinationCountry] = useState([]);

  useEffect(() => {
    getResellerNetworkStats().then((response) => {
      if (response?.data?.header?.code == 200) {
        let resData = response?.data?.body;
        setNetworkStats(resData);
      } else {
        message.error(response?.data?.header?.message);
      }
    }).catch((error) => message.error("Something went wrong"));
    getSourceCountry()
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          getDestinationCountry()
            .then((response2) => {
              if (
                response2?.data?.header?.code == 200 &&
                response2?.data?.body?.length
              ) {
                let data = [
                  ...response?.data?.body?.data,
                  ...response2?.data?.body,
                ];
                setSourceDestinationCountry(data);
              } else {
                message.error(response2?.data?.header?.message);
              }
            })
            .catch((error) => message.error("Something went wrong"));
        }
        setLoad(false);
      })
      .catch((error) => message.error("Something went wrong"));
  }, []);

  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        {/* <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
            <Breadcrumb.Item>Reports</Breadcrumb.Item>
          </Breadcrumb>
        </Col> */}
        <Col span={12}>
          <Headings
            title="Statistical Reports"
            titleSize={2}
            description={`Visualize Statistical Reports`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={24}>
          <Spin indicator={antIcon} size="large" spinning={load}>
            <Tabs
              className="custom-tabs"
              defaultActiveKey="1"
              items={items(networkStats, sourceDestinationCountry)}
            ></Tabs>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};
export default Reports;
