
const titles = {
    PaymentInformation: 'PaymentInformation',
  };
  
  export const PaymentInformation = [ 
    {
      icon:'KeySvgIcon',
      component: 'PaymentInformation',
      path: '/payment-information',
      title: titles.PaymentInformation,
      parent: false,
    },
    {
      icon:'KeySvgIcon',
      component: 'PaymentSuccess',
      path: '/payment-information/payment-success',
      title: titles.PaymentInformation,
      parent: false,
    },
  ]