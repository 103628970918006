import React from "react";
import { Layout, Row, Col, Typography, Image, Space, Spin, Card } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  LoginLogo,
  PaymentMethodIcons,
  ProtectIcon,
} from "../../atoms/CustomIcons";
import CHeader from "../../atoms/CHeader";

const { Title, Text, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin />;
const { Content, Footer } = Layout;
export default (props) => {
  return (
    <Layout style={{ height: "100vh" }}>
      <CHeader />
      <Content style={{ padding: 30, marginTop: 50 }}>
        <Card
          bordered={true}
          className="design-card"
          bodyStyle={{ paddingLeft: 200, paddingRight: 200, paddingTop: 70 }}
        >
          {props.children}
          <Footer
            style={{ backgroundColor: "transparent", textAlign: "center" }}
          >
            <Col span={24} style={{alignItems: 'center', justifyContent: 'center'}}>
              <Row>
                <Space direction="horizontal" style={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                  <ProtectIcon />
                  <Text>
                    Safety payment provided by stripe. The standard VAT rate may
                    be charged, Following the law of your country
                  </Text>
                </Space>
              </Row>
              <Space direction="vertical" size={'middle'} style={{marginTop: 20}}>
                <PaymentMethodIcons />
              </Space>
            </Col>
          </Footer>

          {/* <Space>
            <PaymentMethodIcons />
          </Space> */}
        </Card>
      </Content>
    </Layout>
  );
};
