import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tabs, Breadcrumb, message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Headings from "../../atoms/Headigs";
import SharedServices from "./SharedServices";
import { getResellerAccountDetail } from "./ducks/services";
const antIcon = <LoadingOutlined spin />;

const items = (resellerData) => {
  let comp = [
    {
      key: "1",
      label: `Shared Services`,
      children: (
        <SharedServices
          countryProtocols={resellerData?.sharedProtocolData?.countryProtocols}
          sharedCities={resellerData?.sharedProtocolData?.sharedCities}
          totalAccounts={resellerData?.sharedProtocolData?.totalAccounts}
          multiLogins={resellerData?.sharedProtocolData?.multiLogins}
          sessionLimit={resellerData?.sharedProtocolData?.sessionLimit}
          tagType="oShared"
        ></SharedServices>
      ),
    },
    {
      key: "2",
      label: `Dedicated Services`,
      children: (
        <SharedServices
          countryProtocols={
            resellerData?.dedicatedProtocolData?.countryProtocols
          }
          sharedCities={resellerData?.dedicatedProtocolData?.sharedCities}
          totalAccounts={resellerData?.dedicatedProtocolData?.totalAccounts}
          multiLogins={resellerData?.dedicatedProtocolData?.multiLogins}
          sessionLimit={resellerData?.dedicatedProtocolData?.sessionLimit}
          tagType="oDedicated"
        ></SharedServices>
      ),
    },
    {
      key: "3",
      label: `Dedicated Network Services`,
      children: (
        <SharedServices
          countryProtocols={
            resellerData?.dedicatedNetworkProtocolData?.countryProtocols
          }
          sharedCities={
            resellerData?.dedicatedNetworkProtocolData?.sharedCities
          }
          totalAccounts={
            resellerData?.dedicatedNetworkProtocolData?.totalAccounts
          }
          multiLogins={resellerData?.dedicatedNetworkProtocolData?.multiLogins}
          sessionLimit={
            resellerData?.dedicatedNetworkProtocolData?.sessionLimit
          }
          tagType="oDedicatedNetwork"
        ></SharedServices>
      ),
    },
  ];

  return resellerData?.dedicatedProtocolData?.countryProtocols.length
    ? comp
    : [comp[0]];
};

const AccountDetails = () => {
  const [resellerData, setResellerData] = useState();
  const [load, setLoad] = useState(true);
  useEffect(() => {

    getResellerAccountDetail()
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          let resellerAccDetail = response?.data?.body;
          setResellerData(resellerAccDetail);
        } else {
          // message.error(response?.data?.headers?.message);
        }
        setLoad(false);
      })
      .catch((error) => {
        message.error("Something went wrong");
        setLoad(false);
      });
  }, []);
  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        {/* <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Account Details</Breadcrumb.Item>
          </Breadcrumb>
        </Col> */}
        <Col span={12}>
          <Headings
            title="Account Details"
            titleSize={2}
            description={`Manage your Account Details`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={24}>
          <Spin indicator={antIcon} size="large" spinning={load}>
            <Tabs
              className="custom-tabs"
              defaultActiveKey="1"
              items={items(resellerData)}
            ></Tabs>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};

export default AccountDetails;
