import { Card, Col, Row, Typography } from "antd";
import React from "react";
import ActiveAccount from "./Components/ActiveAccount/index";
import ActiveUser from "./Components/ActiveUser/index";
import Invoices from "./Components/Invoices/index";

const Billing = () => {
  const userData = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <Card className="design-card">
      <Typography.Title level={3} style={{ marginBottom: "30px" }}>
        Estimated Bill
      </Typography.Title>
      <Row gutter={[16, 16]}>
        {userData?.subscription === "active-account-based" ? (
          <Col span={24}>
            <ActiveUser />
          </Col>
        ) : userData?.subscription === "connection-based" ? (
          <Col span={24}>
            <ActiveAccount />
          </Col>
        ) : (
          <Col span={24}>
            <ActiveAccount />
          </Col>
        )}
      </Row>
      <Typography.Title
        level={3}
        style={{ marginTop: "30px", marginBottom: "30px" }}
      >
        Invoices
      </Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Invoices />
        </Col>
      </Row>
    </Card>
  );
};

export default Billing;
