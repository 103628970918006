import * as action_types from "./constant";
export const updateStorageJSON = (json) => (dispatch) => {
  try {
    dispatch({
      type: action_types.ACCOUNT_DETAILS_JSON,
      data: json,
    });
  } catch (error) {}
};

// export const getResellerPreferences = (params) => async (dispatch) => {
//   try {
//     const {
//       data: { data },
//     } = await axios.get(`${BASE_URL}/api/app-preferences/get?uuid=${params}`);
//     dispatch({
//       type: action_types.VPNACCOUNT_DETAILS,
//       data: data,
//     });
//   } catch (error) {}
// };
