import React, { useState, useEffect } from "react";
import { Typography, DatePicker, Button, Empty, Spin, message } from "antd";
import { Column } from "@ant-design/plots";
import { getBillingEstimation } from "../../ducks/services";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const index = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const firstDayOfMonth = new Date(currentYear, currentMonth, 2);
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 1);
  const formattedFirstDate = firstDayOfMonth.toISOString().slice(0, 10);
  const formattedLastDate = lastDayOfMonth.toISOString().slice(0, 10);

  const [startDate, setStartDate] = useState(formattedFirstDate);
  const [endDate, setEndDate] = useState(formattedLastDate);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);


  const handleDateChange = (dates, dateStrings) => {
    getBillingEstimation(dateStrings[0], dateStrings[1])
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setData(response?.data?.body?.data);
        } else {
          // message.error(response?.data?.header?.message);
        }
        setLoading(false);
      })
      .catch((error) => message.error("Something went wrong"));
  };

  useEffect(() => {
    getBillingEstimation(startDate,endDate)
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setData(response?.data?.body?.data);
        } else {
          // message.error(response?.data?.header?.message);
        }
        // setLoading(false);
      })
      .catch((error) => message.error("Something went wrong"));
      setLoading(false);

  }, []);

  const config = {
    data,
    isGroup: true,
    xField: "month",
    yField: "total_cost_of_accounts",
    seriesField: "name",
    label: {
      position: "middle",
      // 'top', 'middle', 'bottom'
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };

  return (
    <div className="col-design col-design3">
      <Typography style={{ marginTop: "10px", marginLeft: "15px" }}>
        <p style={{ fontSize: "14px", fontWeight: "700" }}>Active Account</p>
      </Typography>
      <Typography style={{ marginTop: "10px", marginLeft: "15px" }}>
        <DatePicker.RangePicker onChange={handleDateChange} />
        {/* <Button onClick={fetchData}>Fetch Data</Button> */}
      </Typography>
      {loading ? ( // Check if loading is true
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Spin size="large" />
        </div>
      ) : data && data.length > 0 ? (
        <Column {...config} style={{ padding: "15px" }} />
      ) : (
        <Empty description="No Record Found" className="no-record-found" />
      )}
    </div>
  );
};

export default index;
