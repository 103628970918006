import React from "react";
import { Card, Row, Col, Image, Typography, Space, Button } from "antd";
import Headings from "../../atoms/Headigs";
import dummyBilling from "../../../assets/img/dummyBilling.png";

const SCHEDULE_CALL_LINK = process.env.SCHEDULE_CALL_LINK


const GuestReports = () => {
  const { Paragraph } = Typography;
  const handleButtonLink = () => {
    window.open(SCHEDULE_CALL_LINK, '_blank');
  }
  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        <Col span={12}>
          <Headings
            title="Estimated Bill"
            titleSize={2}
            description={`Manage your VPN Accounts Billing`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={24} className="dummy-image-container-guest">
          <Image
            className="dummy-image-border"
            preview={false}
            width={1500}
            height={420}
            src={dummyBilling}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Paragraph
          className="c-gray fontSize12Small"
        >
          Easily track your monthly expected costs and view detailed invoices.
          Access and manage your billing information conveniently from a
          dedicated dashboard
        </Paragraph>
      </Col>
      <Space>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-1-5"
            size="large"
            onClick={() => handleButtonLink()}
          >
            Schedule Call
          </Button>
        </Space>
    </Card>
  );
};

export default GuestReports;
