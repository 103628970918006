import React from "react";
import { Layout, Row, Col, Typography, Image, Space, Spin, Card } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { LoginLogo } from "../../atoms/CustomIcons";

const { Title, Text, Paragraph } = Typography;
const antIcon = <LoadingOutlined spin size='500px'/>;

export default (props) => {
  return (
    <Spin indicator={antIcon} size="large" spinning={props.load}>
      <Layout className="login-layout">
        <Row align="middle" justify="center" gutter={24}>
          <Col flex="0 0 536px">
            <Row gutter={[24, 50]}>
              <Col span={24} className="text-center">
                <LoginLogo />
              </Col>
              <Col span={24}>
                <Card bordered={false} className="login-card">
                  <Col span={24}>{props.children}</Col>
                </Card>
              </Col>
              <Col span={24}></Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    </Spin>
  );
};