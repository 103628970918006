import * as action_type from "./constant";

const initialState = {
  vpnAccountDetails: [],
  resellerDetails: {},
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_type.VPNACCOUNT_DETAILS:
      return { ...state, vpnAccountDetails: data };
    case action_type.RESELLER_DETAILS:
      return { ...state, resellerDetails: data };
    default:
      return state;
  }
};
