import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Dropdown,
  Button,
  message,
  Input,
  Space,
  Typography,
} from "antd";
import Headings from "../../atoms/Headigs";
import ListCard from "../../molecules/ListCard";
import { getResellerSecretKeys } from "./ducks/services";
import {
  MoreOutlined,
  SearchOutlined,

} from "@ant-design/icons";
const { Text } = Typography;

import moment from "moment";
const getColumnSearchProps = (dataIndex) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const searchInput = useRef(null);
      return (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
              close();
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                close();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
  };
};
const items = [
  {
    key: "1",
    label: (
      <Button type="link" className="c-black font-500">
        View
      </Button>
    ),
  },
  {
    key: "2",
    label: (
      <Button type="link" className="c-black font-500">
        Manage
      </Button>
    ),
  },
  {
    key: "3",
    label: (
      <Button type="link" className="c-black font-500">
        Delete
      </Button>
    ),
  },
];

const KeyManagment = () => {
  const [load, setLoad] = useState(true);
  const [rowData, setRowData] = useState([]);

  const ListCol = [
    {
      title: "Secret Key",
      dataIndex: "secretKey",
      key: "secretKey",
      width:450,
      ellipsis: true,
      render: (data) => (
        <Card bordered={false} className="copyable-card">
          <Text ellipsis copyable>
            {data}
          </Text>
        </Card>
      ),
      ...getColumnSearchProps("secretKey"),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      ...getColumnSearchProps("remarks"),
    },
    {
      title: "Created Date",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (date) => moment(date).format("DD/MMM/YYYY"),
      ...getColumnSearchProps("dateCreated"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (data) => (data == "1" ? "Active" : "Inactive"),
      ...getColumnSearchProps("status"),
    },
      //Commenting for future use
/*   {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "left",
      render: (text, record) => {
        return (
          <Dropdown
            menu={{
              items,
              onClick: ({ key }) => {
                if (key === "2") {
                } else if (key === "1") {
                }
              },
            }}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },*/
  ];

  useEffect(() => {
    getResellerSecretKeys()
      .then((response) => {
        if (response?.data?.header?.code==200) {
          setRowData(response?.data?.body?.secretKeys);
        } else {
          setRowData([]);
        }
        setLoad(false);
      })
      .catch((error) => message.error("Something went wrong"));
  }, []);

  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        {/* <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
              <Breadcrumb.Item>Active Users</Breadcrumb.Item>
            </Breadcrumb>
          </Col> */}
        <Col span={12}>
          <Headings
            title="API Secret Keys Managment"
            titleSize={2}
            description={`Manage your api secret keys`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={12}></Col>
        <Col span={24}>
          <ListCard
            ListCol={ListCol}
            ListData={rowData}
            listClass="nospace-card"
            loading={load}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default KeyManagment;
