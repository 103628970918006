import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Typography,
  Space,
  Button,
  Steps,
  Avatar,
  Spin,
} from "antd";
import Headings from "../../atoms/Headigs";
import {
  AdditionalDetalsIcon,
  AndroidIcon,
  DesignSvgIcon,
  DevelopmentIcon,
  GoLiveIcon,
  IosIcon,
  PaymentIcon,
  UatIcon,
} from "../../atoms/CustomIcons";
import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Lottie from "lottie-react";
import appreview from "../../../assets/lottie/appreview.json";
import Dashboard from "../../templates/Dashboard";

const AppReviewScreen = (props) => {
  const { Paragraph, Text, Title } = Typography;
  return (
    <Col span={24}>
      <Headings
        title="App Review"
        titleSize={2}
        description={`Your apps are currently undergoing review on the PlayStore and the App Store.`}
        space={5}
      />

      <Row style={{ marginTop: 60, marginBottom: 60 }} justify={"space-evenly"}>
        <Card
          className="small-card"
          bordered
          style={{
            width: 341,
            // height: 330,
            padding: 10,
            // display: "flex",
            // flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Col span={24}>
            <Row justify={"center"}>
              <AndroidIcon />
              <Title level={2}>Android</Title>
            </Row>
            <Paragraph className="c-gray center-align-text fontSize12Small mt-2">
              The APK has been shared via email. Please use the provided link to
              download it and access it with your registered email address.
            </Paragraph>
            <Lottie
              animationData={appreview}
              loop={true}
            //   style={{ width: "20%", height: "20%" }}
            />
          </Col>
        </Card>
        <Card
          className="small-card"
          bordered
          style={{
            width: 341,
            // height: 330,
            padding: 10,
            // display: "flex",
            // flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Col span={24}>
            <Row justify={"center"}>
              <IosIcon />
              <Title level={2}>iOS</Title>
            </Row>
            <Paragraph className="c-gray center-align-text fontSize12Small mt-2">
              Your app can be found on TestFlight. Access to this version
              alongside redeem code has been shared with you via your registered
              email address.
            </Paragraph>
            <Lottie
              animationData={appreview}
              loop={true}
            //   style={{ width: "20%", height: "20%" }}
            />
          </Col>
        </Card>
      </Row>

      <Paragraph className="c-gray center-align-text px-2 fontSize16Medium ">
        Your will be notified as soon as your app is ready for UAT. Please
        reachout to our support
        <Text className="text-base defaultFont"> support@purewl.com</Text> for
        any concern or query.
      </Paragraph>
    </Col>
  );
};
export default AppReviewScreen;
