
const titles = {
    DevelopmentScreen: 'DevelopmentScreen',
  };
  
  export const StatusScreens = [ 
    {
      icon:'KeySvgIcon',
      component: 'DevelopmentScreen',
      path: '/development',
      title: titles.DevelopmentScreen,
      parent: false,
    },
    // {
    //   icon:'KeySvgIcon',
    //   component: 'PaymentSuccess',
    //   path: '/payment-information/payment-success',
    //   title: titles.PaymentInformation,
    //   parent: false,
    // },
  ]
