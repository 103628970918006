import React, {useEffect, useState} from "react";
import {Table, Button, Modal, Row, Col, Form, Input, Dropdown} from 'antd';
import SuccessOrErrorModalComponent from './successErrorModal'
import {MoreOutlined} from "@ant-design/icons";
import ManagePermissions from "./Managepermissions/index";
import {getResellerSubUserDetail, createSubUser, sendEmailInvite} from "./ducks/services";
import { PlusOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import {Popup} from "Atoms/Popup";
import DeactivateUserAccount from "./DeactivateUserAccount/index";
import DeleteUserAccount from "./DeleteUserAccount/index";
import MultipleEmailInput from "./emailForm";
import SendInviteAgain from "./SendInviteAgain";

const managePermissionPopup = ({
                                   popUpVisibility,
                                   setPopUpVisibility,
                                   subUserId
                               }) => {
const popupContent = {
        closable: false,
        visibility: popUpVisibility,
        class: "popup-modal",
        width: 583,
        content: (
            <ManagePermissions subUserId={subUserId}
                               onClose={() => setPopUpVisibility(false)}
            />
        ),
    };
    return { ...popupContent };
};
const deleteUserAccountPopup = ({
                                        subUserEmail,
                                        popUpViewDelete,
                                        setPopUpViewDelete,
                                    }) => {
    const popupContent = {
        closable: false,
        visibility: popUpViewDelete,
        class: "popup-modal",
        width: 583,
        content: (
            <DeleteUserAccount
                subUserEmail={subUserEmail}
                onClose={() => setPopUpViewDelete(false)}
            />
        ),
    };
    return { ...popupContent };
};
const deactivateUserAccountPopup = ({
                                        subUserEmail,
                                   popUpViewDeactivate,
                                   setPopUpViewDeactivate,
                               }) => {
    const popupContent = {
        closable: false,
        visibility: popUpViewDeactivate,
        class: "popup-modal",
        width: 583,
        content: (
            <DeactivateUserAccount
                subUserEmail={subUserEmail}
                onClose={() => setPopUpViewDeactivate(false)}
            />
        ),
    };
    return { ...popupContent };
};
const sendInviteAgainPopup = ({
                                        subUserEmail,
                                        popUpSendInvite,
                                        setPopUpSendInvite,
                                    }) => {
    const popupContent = {
        closable: false,
        visibility: popUpSendInvite,
        class: "popup-modal",
        width: 583,
        content: (
            <SendInviteAgain
                subUserEmail={subUserEmail}
                onClose={() => setPopUpSendInvite(false)}
            />
        ),
    };
    return { ...popupContent };
};
const SubUsers = () => {
    const [data, setData] = useState([]);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isSuccessErrorFormVisible, setIsSuccessErrorFormVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [subUserId, setSubUserId] = useState(null);
    const [subUserEmail, setSubUserEmail] = useState(null);
    const [form] = Form.useForm(); // Create a form instance
    const [popUpVisibility,setPopUpVisibility] = useState(false)
    const [popUpViewDelete,setPopUpViewDelete] = useState(false)
    const [popUpViewDeactivate,setPopUpViewDeactivate] = useState(false)
    const [popUpSendInvite,setPopUpSendInvite] = useState(false)
    const popupContent = managePermissionPopup({
        popUpVisibility,
        setPopUpVisibility,
        subUserId
    });
    const deactivateUserPopupContent = deactivateUserAccountPopup({
        popUpViewDeactivate,
        setPopUpViewDeactivate,
        subUserEmail
    });
    const deleteUserPopupContent = deleteUserAccountPopup({
        popUpViewDelete,
        setPopUpViewDelete,
        subUserEmail
    });
    const sendInvitePopUpContent = sendInviteAgainPopup({
        popUpSendInvite,
        setPopUpSendInvite,
        subUserEmail
    });
    const itemsWithPending = [
        {
            key: "1",
            label: (
                <Button type="link" className="c-black font-500">
                    Manage Permissions
                </Button>
            ),
        },
        {
            key: "2",
            label: (
                <Button type="link" className="c-black font-500">
                    Deactivate User
                </Button>
            ),
        },
        {
            key: "3",
            label: (
                <Button type="link" className="c-black font-500">
                    Delete User
                </Button>
            ),
        },
        {
            key: "4",
            label: (
                <Button type="link" className="c-black font-500">
                    Send Invite Again
                </Button>
            ),
        }
    ];
    const itemsWithActive = [
        {
            key: "1",
            label: (
                <Button type="link" className="c-black font-500">
                    Manage Permissions
                </Button>
            ),
        },
        {
            key: "2",
            label: (
                <Button type="link" className="c-black font-500">
                    Deactivate User
                </Button>
            ),
        },
        {
            key: "3",
            label: (
                <Button type="link" className="c-black font-500">
                    Delete User
                </Button>
            ),
        }
    ];
    useEffect(() => {
        fetchResellerSubUsersData();
    }, [popUpViewDeactivate,popUpViewDelete,setPopUpSendInvite]);

    const fetchResellerSubUsersData = async () => {
        try {
            const response =  await getResellerSubUserDetail();
            if(response?.status == 200) {
                // Replace 'your-api-endpoint' with your actual API endpoint
                const {data} = response;
                //const sortedData = [...data.data].sort((a, b) => new Date(a.createdTimestamp) - new Date(b.createdTimestamp));

                setData(data?.data ?? []);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const handleOpenForm = (record) => {
        // You can perform any necessary actions here before opening the form
        setIsFormVisible(true);
    };
    const handleItemClick = (key,record) => {
        setSubUserId(record?.id)
        setSubUserEmail(encodeURIComponent(record?.email))

        if (key === '1') {
            setPopUpVisibility(true);
            // Assume you have userId stored in some variable or state
            // Set your user id here
            // opens a popup and send userID in that popup
        }
        else if (key === '2') {
            setPopUpViewDeactivate(true);

            // Assume you have userId stored in some variable or state
            // Set your user id here
            // opens a popup and send userID in that popup
        }
        else if(key==='3'){
            setPopUpViewDelete(true)
        }
        else if(key==='4'){
            setPopUpSendInvite(true)
        }
    };

    const handleOk = () => {
        // Handle any logic here when user clicks OK
        setIsSuccessErrorFormVisible(false);
    };
    const handleCloseForm = () => {
        setIsFormVisible(false);
        setIsSuccessErrorFormVisible(false);
        setErrorMessage('');
        setModalTitle('');
        // Update the list of subusers when the modal is closed
        fetchResellerSubUsersData()
            .then((data) => setData(data.data ?? []))
            .catch((error) => console.error('Error fetching subusers:', error))
    };
    const handleSuccessErrorCloseForm = () => {
        setIsSuccessErrorFormVisible(false);
        setErrorMessage('');
        setModalTitle('');
    };
    const handleFormSubmit = async (emails) => {
        try {
            const payload = { emails: emails };
            // Make a POST request to your API endpoint with the form values
            const {data} = await sendEmailInvite(payload);
            if (data.status == 200) {
                fetchResellerSubUsersData()
                    .then((data) => setData(data.data ?? []))
                    .catch((error) => console.error('Error fetching subusers:', error))
                // Optionally, you can handle the response or perform additional actions
                setIsFormVisible(false); // Close the modal after successful submission
                notification.success({
                    message: 'Invite has been sent successfully',
                    description: 'Invite has been sent successfully.',
                    placement: 'bottomRight' // Adjust the placement as needed
                });
                form.resetFields();

            }
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Failed to Add User',
                description: error?.response?.data?.message ?? 'An error occurred while adding the user. Please try again later.',
                placement: 'bottomRight' // Adjust the placement as needed
            });
        }
    };
    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (_, record) => record.username ?? '-'

        },
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            render: (_, record) => record.firstName ? `${record.firstName} ${record.lastName}` : "-",
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            align: "left",
            render: (_, record) => {
                    return (
                        record.status == 'pending' || record.status == 'Pending' ?
                        <Dropdown
                            menu={{
                                items: itemsWithPending,
                                onClick: ({ key }) => handleItemClick(key, record),
                            }}
                            placement="bottomRight"
                            arrow={{ pointAtCenter: true }}
                        >
                            <MoreOutlined />
                        </Dropdown> : <Dropdown
                                menu={{
                                    items: itemsWithActive,
                                    onClick: ({ key }) => handleItemClick(key, record),
                                }}
                                placement="bottomRight"
                                arrow={{ pointAtCenter: true }}
                            >
                                <MoreOutlined />
                            </Dropdown>
                    );
        }
        }
    ];
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Button type="primary" onClick={() => setIsFormVisible(true)}
                            style={{
                                float:'right',
                                width: 'auto',
                                textAlign: 'left',
                                display: 'flex',
                                alignItems: 'center',
                                margin: '10px 60px 0px 0px'
                                // Align icon and text vertically
                            }}>
                        <PlusOutlined style={{ marginRight: '8px' }} />
                        <span style={{margin: 'auto'}}>Send Invites</span>
                    </Button>
                    <SuccessOrErrorModalComponent
                        visible={isSuccessErrorFormVisible}
                        title={modalTitle}
                        onCancel={handleSuccessErrorCloseForm}
                        onOk={handleOk}
                        errorMessage={errorMessage}
                        successMessage={successMessage}/>
                    <Modal
                        title="Add Multiple Emails"
                        open={isFormVisible}
                        onCancel={handleCloseForm}
                        footer={null}
                    ><MultipleEmailInput handleFormSubmit={handleFormSubmit}/>
                    </Modal>
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '16px' }}>ACL (Access Control List)</h1>
                    <Table dataSource={data} columns={columns} />
                </Col>
            </Row>
            <Popup {...popupContent} />
            <Popup {...deactivateUserPopupContent} />
            <Popup {...deleteUserPopupContent} />
            <Popup {...sendInvitePopUpContent} />

        </div>
    );
};

export default SubUsers;
