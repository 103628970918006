import axios from "../../../../configs/axiosIntereptor";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUserActivity = () => {
  return axios.get(`${BASE_URL}/api/billing/connected-users-connection-info`);
};

export const getResellerAccActivity = () => {
  return axios.get(`${BASE_URL}/api/billing/connected-users-count`);
};

export const getProtocolStats = () => {
  return axios.get(`${BASE_URL}/api/dashboard/protocol-list`);
};
