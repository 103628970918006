
const titles = {
  Login: 'Login',
};

export const Login = [ 
  {
    icon:'UserNavSvgIcon',
    component: 'Login',
    path: '/login',
    title: titles.Login,
    key: 'login',
    menu: 'Login',
    parent: false,

  },
]