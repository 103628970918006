import Roles from "../../configs/Roles";

const titles = {
    ConnectedUsers: 'ConnectedUsers',
};

export const ConnectedUsers = [
    {
        icon: 'UsersSvgIcon',
        component: 'ConnectedUsers',
        path: '/connected-users',
        title: titles.ConnectedUsers,
        key: 'ConnectedUsers',
        menu: 'Connected Users',
        parent: true,
        // permission:[Roles.RESELLER]
    },
];