import React, { useEffect, useState } from 'react';
import {Row, Col, Spin, Space, message} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useKeycloak } from '@react-keycloak/web';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLocalStorage } from '../../../assets/utils/common_functions';
import {
  getUserInfoFromKeyCloak
} from "./ducks/services";
import { setUserData } from './ducks/action';
const antIcon = <LoadingOutlined spin style={{ fontSize: '75px' }} />;
const KEY_CLOAK_ENV = process.env.KEY_CLOAK_ENV;
const KEY_CLOAK_BASEURL = process.env.KEY_CLOAK_BASEURL;
const KEY_CLOAK_REALM = process.env.KEY_CLOAK_REALM;
const KEY_CLOAK_CLIENT_ID = process.env.KEY_CLOAK_CLIENT_ID
export default (props) => {
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const history = useHistory();
  if (keycloak.authenticated) {
    let userDetail = {
      keycloak_token: keycloak?.token
    };
   
    // localStorage.setItem('akjdnd',
    //   JSON.stringify(keycloak?.token)
    // );
    setLocalStorage('authToken', keycloak?.token, true)
    setTimeout(() => {
      history.push('/dashboard');
    }, 3000);
  }
  else {
    return window.location.replace(
      `${KEY_CLOAK_BASEURL}/realms/${KEY_CLOAK_REALM}/protocol/openid-connect/auth?client_id=${KEY_CLOAK_CLIENT_ID}&scope=openid%20profile%20email&redirect_uri=${KEY_CLOAK_ENV}&response_type=code`
    );
  }
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Space direction='vertical' align='center' className='w-100'>
          <Spin indicator={antIcon} size='large' spinning={load}></Spin>
        </Space>
      </Col>
    </Row>

    // <Row gutter={[24, 50]}>
    //   <Col span={24}>
    //     <Space direction="vertical" align="center" className="w-100">
    //       <Title level={1} className="mb-0">
    //         Sign In
    //       </Title>
    //       <Text className="c-gray">Sign in with your email and password</Text>
    //     </Space>
    //   </Col>
    //   <Col span={24}>
    //     <Form layout="vertical" onFinish={handleSubmit(onLoginFormSubmit)}>
    //       <InputField
    //         label="Email Address"
    //         fieldname="email"
    //         control={control}
    //         initValue=""
    //         iProps={{
    //           size: "large",
    //           placeholder: "username",
    //         }}
    //         rules={{
    //           required: "Please input username",
    //           pattern: {
    //             value: /(?=^.{1,50}$)^\S*$/,
    //             message: "Input username without space!",
    //           },
    //         }}
    //         validate={errors.username && "error"}
    //         validMessage={errors.username && errors.username.message}
    //       />
    //       <InputPassword
    //         label="Password"
    //         fieldname="password"
    //         control={control}
    //         initValue=""
    //         iProps={{
    //           size: "large",
    //           placeholder: "password",
    //         }}
    //         rules={{
    //           required: "Please enter password",
    //         }}
    //         validate={errors.password && "error"}
    //         validMessage={errors.password && errors.password.message}
    //       />
    //       <Button
    //         type="primary"
    //         htmlType="submit"
    //         className="mt-1-5"
    //         size="large"
    //       >
    //         Sign In
    //       </Button>
    //     </Form>
    //   </Col>
    // </Row>
  );
};
