import React, { useState, useEffect } from "react";
import { Row, Card, Col, Spin, Typography, message, Empty } from "antd";
import { useDispatch } from "react-redux";
import { Pie, Area } from "@ant-design/plots";
import Headings from "../../atoms/Headigs";
import FigureChip from "../../atoms/FigureChip";
import {
  getUserActivity,
  getResellerAccActivity,
  getProtocolStats,
} from "./ducks/services";
import {
  DashboardActivityIcon,
  DashboardNewAccountIcon,
} from "../../atoms/CustomIcons";
import { getAllResellersProtocols } from "../../Pages/Dashboard/ducks/actions";
import { LoadingOutlined } from "@ant-design/icons";
import {setUserData} from "Modules/Login/ducks/action";

const antIcon = <LoadingOutlined spin />;

const { Title } = Typography;
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const [userActivity, setUserActivity] = useState(0);
  const [resellerAccActivity, setResellerAccActivity] = useState("");
  const [protocolData, setProtocolData] = useState([]);
  const [connectedUserData, setConnectedUserData] = useState([]);
  const [load, setLoad] = useState(true);

  const fetchData = () => {
    getProtocolStats()
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          const protoColData = response?.data?.body?.data;
          const chartData = {
            timePeriod: new Date().toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            }),
            value: protoColData[protoColData?.length - 1]?.value,
          };
          setConnectedUserData((prevArray) => [...prevArray, chartData]);
          setProtocolData(protoColData.filter((obj) => obj.type !== "Online"));
        } else {
          // message.error(response?.data?.header?.message);
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
      });
    setLoad(false);
  };

  useEffect(() => {
    dispatch(setUserData()); // Dispatch the action to set user data in Redux
/*    getUserInfoFromKeyCloak().then((response) => {
      if (response?.data?.status == 200) {
        let resData = response?.data?.data;
        dispatch(setUserData(resData)); // Dispatch the action to set user data in Redux
        //want to set this data on reduce
      } else {
        message.error(response?.data?.header?.message);
      }
    }).catch((error) => message.error("Something went wrong"));*/
    getResellerAccActivity()
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setResellerAccActivity(response?.data?.body);
        } else {
          // message.error(response?.data?.header?.message);
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
      });
    getUserActivity()
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          let timeDifference =
            response?.data?.body?.lastActivity &&
            new Date() - new Date(response?.data?.body?.lastActivity);
          setUserActivity(Math.floor(timeDifference / (1000 * 60 * 60)));
        } else {
          // message.error(response?.data?.header?.message);
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
      });
    dispatch(getAllResellersProtocols());
    getProtocolStats()
    .then((response) => {
      if (response?.data?.header?.code == 200) {
        const protoColData = response?.data?.body?.data;
        const chartData = {
          timePeriod: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }),
          value: protoColData[protoColData?.length - 1]?.value,
        };
        setConnectedUserData((prevArray) => [...prevArray, chartData]);
        setProtocolData(protoColData.filter((obj) => obj.type !== "Online"));
      } else {
        // message.error(response?.data?.header?.message);
      }
    })
    .catch((error) => {
      message.error("Something went wrong");
    });
  setLoad(false);
  }, []);

  // useEffect(() => {
  //   fetchData();
  //   const interval = setInterval(fetchData, 30 * 1000);
  //   return () => clearInterval(interval);
  // }, []);

  const config = {
    data: protocolData,
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    pieStyle: {
      fillOpacity: 0.7,
      lineWidth: 0,
    },
    label: {
      type: "outer",
      content: "{name} {percentage}",
    },
  };
  const config1 = {
    data: connectedUserData,
    xField: "timePeriod",
    yField: "value",
    smooth: true,
    yAxis: {
      tickCount: 5,
    },
    xAxis: {
      nice: true,
      title: {
        text: "Timestamp",
        style: {
          fontSize: 16,
        },
      },
    },
    yAxis: {
      nice: true,
      title: {
        text: "Users",
        style: {
          fontSize: 16,
        },
      },
    },
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#D4CCFF 1:#D4CCFF",
      };
    },
  };

  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        {/* <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
        </Col> */}
        <Col span={24}>
          <Headings
            title="Dashboard"
            titleSize={2}
            description={`Manage your organization from a single platform`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={8}>
          <FigureChip
            icon={<DashboardActivityIcon />}
            stats={`${userActivity}`}
            statsTitle=" Hours ago"
            description="Last Activity"
          />
        </Col>
        <Col span={8}>
          <FigureChip
            icon={<DashboardNewAccountIcon />}
            stats={`${resellerAccActivity?.users_in_interval}`}
            statsTitle=""
            description="Last Created Accounts"
          />
        </Col>
        <Col span={8}>
          <FigureChip
            icon={<DashboardNewAccountIcon />}
            stats={`${resellerAccActivity?.total_records}`}
            statsTitle=""
            description="Total Accounts"
          />
        </Col>
        <Col span={12}>
          <Card
            className="custom-card-graph"
            title={<Title level={2}>Online Now</Title>}
          >
            <Spin indicator={antIcon} size="large" spinning={load}>
              {config?.data?.length ? <Pie {...config} /> : <Empty/>}
            </Spin>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            className="custom-card-graph"
            title={<Title level={2}>Connected User</Title>}
          >
            <Spin indicator={antIcon} size="large" spinning={load}>
              {config?.data?.length ? <Area {...config1} /> : <Empty />}
            </Spin>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};
export default Dashboard;
