import React, { useState } from "react";
import Dashboard from "../../templates/Dashboard";
import DownloadAtomSDKs from "../../modules/DownloadSDK";

export default (props) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dashboard load={loading}>
      <DownloadAtomSDKs />
    </Dashboard>
  );
};
