import * as action_types from "./constants";
import axios from "../../../../configs/axiosIntereptor";
import allProtocols from "../../../../configs/allProtocols";
export const updateMenu = (stat) => {
  return (dispatch) => {
    dispatch({
      type: action_types.MENU_STAT,
      data: stat,
    });
  };
};

export const updateDrawerMenu = (bool) => {
  return (dispatch) => {
    dispatch({
      type: action_types.DRAWER_MENU,
      data: bool,
    });
  };
};

export const getAllResellersProtocols = () => async (dispatch) => {
  try {
    dispatch({
      type: action_types.ALL_PROTOCOLS,
      data: allProtocols,
    });
  } catch (error) {}
};
