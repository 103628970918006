import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Collapse,
  Space,
  Typography,
  Descriptions,
  Tag,
  Avatar,
  message,
} from "antd";
import DetailTag from "../../../atoms/DetailTag";
import {
  EmailSvgIcon,
  MultiLoginSvgIcon,
  ClockSvgIcon,
  WorldSvgIcon,
} from "../../../atoms/CustomIcons";
import { getResellersAllowedCitiesProtocols } from "../ducks/services";
const { Panel } = Collapse;
const { Text, Title } = Typography;
const SharedServices = (props) => {
  const {
    countryProtocols,
    sharedCities,
    totalAccounts,
    multiLogins,
    sessionLimit,
    tagType,
  } = props;

  const [citiesProtocols, setCitiesProtocols] = useState([]);
  useEffect(() => {
    const payload = {
      sCityIds: `[${sharedCities}]`,
    };
    sharedCities &&
      getResellersAllowedCitiesProtocols(payload).then((res) => {
        let citiesData = res?.data?.body;
        const citiesProt = Object.entries(citiesData[tagType]).map(
          ([city, sName]) => ({
            city,
            sName,
          })
        );
        setCitiesProtocols(citiesProt);
      }).catch((error)=>message.error('Something went wrong'));
  }, [sharedCities]);

  return (
    <Card className="">
      <Row gutter={[24, 24]}>
        <Col span={8}>
          <DetailTag
            tagTitle="Accounts"
            icon={<EmailSvgIcon />}
            tag={totalAccounts}
          />
        </Col>
        <Col span={8}>
          <DetailTag
            tagTitle="Multi Login"
            icon={<MultiLoginSvgIcon />}
            tag={multiLogins}
          />
        </Col>
        <Col span={8}>
          <DetailTag
            tagTitle="Session Limits"
            icon={<ClockSvgIcon />}
            tag={sessionLimit}
          />
        </Col>

        <Col span={24}>
          <Space direction="vertical" gap={10} className="w-100">
            <Collapse expandIconPosition="end">
              <Panel
                header={
                  <Space align="center">
                    <WorldSvgIcon />
                    <Title className="mb-0" level={5}>
                      Country
                    </Title>
                  </Space>
                }
                key="1"
              >
                <Descriptions className="reqData" bordered column={1}>
                  <Descriptions.Item label="Country Name">
                    Protocols
                  </Descriptions.Item>
                  {countryProtocols?.length
                    ? countryProtocols?.map((value, index) => (
                        <Descriptions.Item
                          key={index}
                          label={
                            <Space>
                              <Avatar
                                style={{ margin: "5px" }}
                                gap={20}
                                size={"small"}
                                src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value?.sIso2}.svg`}
                              />
                              <Text> {value?.sCountry}</Text>
                            </Space>
                          }
                        >
                          {value?.protocols?.map((value2, index2) => (
                            <Tag key={index2} color="#F1EEFF">
                              {value2}
                            </Tag>
                          ))}
                        </Descriptions.Item>
                      ))
                    : ""}
                </Descriptions>
              </Panel>
            </Collapse>
            <Collapse expandIconPosition="end">
              <Panel
                header={
                  <Space align="center">
                    <WorldSvgIcon />
                    <Title className="mb-0" level={5}>
                      Cities
                    </Title>
                  </Space>
                }
                key="1"
              >
                <Descriptions className="reqData" bordered column={1}>
                  <Descriptions.Item label="City Name">
                    Protocols
                  </Descriptions.Item>
                  {citiesProtocols?.length
                    ? citiesProtocols.map((value, index) => (
                        <Descriptions.Item
                          key={index}
                          label={
                            <Space>
                              <Text> {value?.city}</Text>
                            </Space>
                          }
                        >
                          {value?.sName?.map((value2, index2) => (
                            <Tag key={index2} color="#F1EEFF">
                              {value2?.sName}
                            </Tag>
                          ))}
                        </Descriptions.Item>
                      ))
                    : ""}
                </Descriptions>
              </Panel>
            </Collapse>
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default SharedServices;
