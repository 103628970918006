import React, { useState } from "react";
import Dashboard from "../../templates/Dashboard";
import ResellerAccountDetails from "../../modules/AccountDetails";
import GuestAccountDetails from "../../modules/GuestAccountDetails";
import { useKeycloak } from "@react-keycloak/web";
import { decodeToken } from 'react-jwt';
const Components = {
  ResellerAccountDetails,
  GuestAccountDetails
};

export default (props) => {
  const userDetail = JSON.parse(localStorage.getItem("userDetails"));
  const { keycloak, initialized } = useKeycloak();

  let token = decodeToken(keycloak?.token);
  let comp=token?.realm_access?.roles?.includes("paid_reseller") ?"ResellerAccountDetails":"GuestAccountDetails"
  const [loading, setLoading] = useState(false);
  const AccountDetailComp = Components[comp];
  return (
    <Dashboard load={loading}>
      <AccountDetailComp />
    </Dashboard>
  );
};




