
const titles = {
    PaymentEstimate: 'PaymentEstimate',
  };
  
  export const PaymentEstimate = [ 
    {
      icon:'KeySvgIcon',
      component: 'PaymentEstimate',
      path: '/payment-estimate',
      title: titles.PaymentEstimate,
      parent: false,
    },
  ]