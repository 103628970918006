import React from "react";
import { Card, Typography, Image, Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined spin />;
const { Title, Text } = Typography;

export default (props) => {
  const { stats, statsTitle, description, icon } = props;
  return (
    <Spin
      indicator={antIcon}
      size="large"
      spinning={stats && stats !== "undefined" ? false : true}
    >
      <Card hoverable={true} className="stats-card">
        <Space direction="horizontal" size={20} align="center">
          {icon}
          <Space direction="vertical" size={0}>
            {stats && stats !== "undefined" ? (
              <Title level={2} className="ag-fontSize24 mb-0">
                {`${stats}${statsTitle}`}
              </Title>
            ) : null}
            {description && <Text className="c-gray mt-0">{description}</Text>}
          </Space>
        </Space>
      </Card>
    </Spin>
  );
};
