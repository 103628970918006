import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Space,
  Button,
  Image,
  Dropdown,
  Card,
  Modal,
  Typography,
  Form,
  Input,
  message, notification,
} from "antd";
import { useHistory } from "react-router-dom";
import { LogoutOutlined, UserOutlined, LockOutlined,KeyOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import loginLogo from "../../../assets/img/logo.png";
import { decodeToken } from 'react-jwt';
import {
  BellIcon,
  LifeGuardIcon,
  DividerIcon,
  UserSvgIcon,
} from "../../atoms/CustomIcons";
import {useDispatch, useSelector} from "react-redux";
import {setUserOTP,disableUserOTP, verifyOTP} from "../../modules/SubUsers/ducks/services";
import {setUserData} from "Modules/Login/ducks/action";
const { Text } = Typography;
export default (props) => {
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();
  let token = decodeToken(keycloak?.token);
  let role = token?.realm_access?.roles?.includes("paid_reseller");
  const userData = useSelector((state) => state.userReducer.userData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const history = useHistory();
  const handleSupportClick = () => {
    const supportEmail = "support@purewl.com";
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${supportEmail}`;

    const mailtoLink = document.createElement("a");
    mailtoLink.href = `mailto:${supportEmail}`;
    mailtoLink.style.display = "none";

    // Append the mailto link to the body to open the default mail client
    document.body.appendChild(mailtoLink);
    mailtoLink.click();

    // Remove the appended mailto link
    document.body.removeChild(mailtoLink);

    // Open Gmail in a new tab
    window.open(gmailLink, "_blank");

  };
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const result = await disableOTP(values);
      if (result) {
        notification.success({
          message: 'Disable OTP',
          description: 'User OTP has been successfully disabled',
          placement: 'bottomRight',
        });
        form.resetFields();
        setIsModalVisible(false);
        dispatch(setUserData()); // Dispatch the action to set user data in Redux
      } else {
        notification.error({
          message: 'Disable OTP',
          description: 'Failed to disable OTP',
          placement: 'bottomRight',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
        placement: 'bottomRight',
      });
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onOTPHandler = () => {
    if(!userData?.totp){
      setOTP().then(r =>window.location.reload());
    }
    else {
      setIsModalVisible(true);
    }
  };
  const setOTP = async () => {
    const response =  await setUserOTP();
    return response
  };
  const disableOTP = async (values) => {
    try {
      const payload = {
        totp: values.totp,
        password: values.password
      }
      const response = await verifyOTP(payload);
      if (response?.data?.status == 200) {
        return await disableUserOTP();
      } else {
        notification.error({
          message: 'OTP Verification Failed',
          description: 'The provided OTP or password is incorrect',
          placement: 'bottomRight',
        });
        return false;
      }

    }
    catch (error) {
    }
  };
  const items = () => {
    const onLogoutHandler = () => {
      localStorage.clear();
      keycloak.logout();
    };

    let Comp = [
      role && {
        key: "1",
        label: (
          <Button
            onClick={() => history.push("/dashboard/profile")}
            type="link"
            className="btn-link"
            icon={<UserOutlined />}
          >
            Profile
          </Button>
        ),
      },
      {
        key: "2",
        label: (
            <Button
                onClick={onOTPHandler}
                type="link"
                className="btn-link"
                icon={<KeyOutlined />}
            >
              {userData?.totp ? 'Disable OTP' : 'Enable OTP'}
            </Button>
        ),
      },
      {
        key: "3",
        label: (
          <Button
            onClick={onLogoutHandler}
            type="link"
            className="btn-link"
            icon={<LogoutOutlined />}
          >
            Logout
          </Button>
        ),
      },

    ];
    return Comp;
  };

  const items2 = [
    {
      key: "1",
      label: <Text className="empty-notification">No New Notifications</Text>,
    },
  ];
  const items3 = [
    {
      key: "3",
      label: <Text className="empty-notification">Set User Permissions</Text>,
    },
  ];

  const headers1 = {
    "Content-Type": "application/json",
  };
  // useEffect(() => {
  //   fetch(`${BASE_URL}/api/app-status/${userDetail?.uuid}`, {
  //     method: "GET",
  //     headers1,
  //   })
  //     .then((response) => response.json())
  //     .catch((error) => message.error("Something went wrong"))
  //     .then((json) => {
  //       setStatus(json.data);
  //     });
  // }, []);

  return (
    <Card bordered={false} className="c-header">
      <Row gutter={[24, 15]} align="middle">
        <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
          <Image
            width={135}
            preview={false}
            alt="PureVpn"
            src={loginLogo}
            // onClick={() => history.push('/dashboard')}
          />
        </Col>
        <Col
          span={12}
          className="text-right"
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
        >
          <Space size={25} className="pl-0">
           {/* <Button
                onClick={onOTPHandler}
                type="link"
                className="btn-link"
                icon={<KeyOutlined />}
            >
            </Button>
            <DividerIcon />*/}

            { token?.realm_access?.roles?.includes("paid_reseller") &&
              !token?.realm_access?.roles?.includes("sub_user") && (
              <Button
                  onClick={() => history.push("/acl")}
                  type="link"
                  className="btn-link"
                  icon={<LockOutlined />}
              >
              </Button>
            )}
            {/* <LifeGuardIcon /> */}
            <DividerIcon />
            <Button
              type="link"
              className="btn-link"
              icon={<LifeGuardIcon />}
              onClick={handleSupportClick}
            />

            <DividerIcon />

            <Dropdown menu={{ items: items2 }}>
              <BellIcon />
            </Dropdown>

            <DividerIcon />
            <Dropdown menu={{ items: items() }}>
              <UserSvgIcon />
            </Dropdown>

          </Space>
        </Col>
      </Row>
      <Modal
          title="Disable OTP"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
              name="totp"
              label="OTP"
              rules={[
                { required: true, message: 'Please input your OTP!' },
                { len: 6, message: 'OTP must be exactly 6 characters' },
                { pattern: /^\d+$/, message: 'OTP must be a number' }
              ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};
