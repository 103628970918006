import React, { useState } from "react";
import Dashboard from "../../templates/Dashboard";
import KeyManagment from "../../modules/KeyManagment";
const Components = {
  KeyManagment,
};

export default (props) => {
  const [loading, setLoading] = useState(false);
  const ActiveAccComp = Components[props.Comp];
  return (
    <Dashboard load={loading}>
      <ActiveAccComp />
    </Dashboard>
  );
};
