import React, { useState, useEffect } from "react";
import { Spin, Row, Col, Space, Button, message, Form, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Headings from "../../../../atoms/Headigs";
import { InputField } from "../../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { updateJson } from "../../../../../assets/utils/common_functions";
import {
  getDomainVerifyStatus,
  getResellerPreferences,
  setDomainVerifyStatus,
  setResellerPreferences,
} from "../../ducks/services";
import { useSelector, useDispatch } from "react-redux";
import { updateStorageJSON } from "../../ducks/action";

const antIcon = <LoadingOutlined spin />;
const { Text } = Typography;
const AddUrls = (props) => {
  const { popUpDetail, onClosePopup, popUpVisibility } = props;
  const [load, setLoad] = useState(false);
  const [verifyDomain, setVerifyDemain] = useState(false);
  const [btnStatus, setBtnStatus] = useState(false);
  const dispatch = useDispatch();
  const parseJSON = useSelector(
    (state) => state.additionalDetail.accountDetailsJSON
  );
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const domainName = watch("domain_name");
  var urlRegex = /^(?!mailto:)(?:(?:http|https|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[0-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))|localhost)(?::\d{2,5})?(?:(\/|\?|#)[^\s]*)?$/;

  const regexPatterns = {
    org_name: /^[a-zA-Z0-9\s]*$/,
    domain_name: /^[a-zA-Z0-9\s]*$/,
    ios_bundle_identifier: /com\.[a-z]+\.[a-z]+/,
    // ios_bundle_identifier:/^com\.[a-zA-Z0-9]+\.(vpn|Vpn)\.ios$/,
    android_package_name: /com\.[a-z]+\.[a-z]+/,
    privacy_policy: urlRegex,
    terms_services: urlRegex,
    support_center: urlRegex,
    website: urlRegex,
  };

  const fieldNames = {
    org_name: "Organization Name",
    domain_name: "Subdomain Name",
    ios_bundle_identifier: "IOS Bundle Identifier",
    android_package_name: "Android Package Name",
    privacy_policy: "Privacy Policy URL",
    terms_services: "Terms of Service URL",
    support_center: "Support URL",
    website: "Website URL",
  };

  const fieldErrorMessage = {
    org_name: "Invalid format for Organization Name, input should not contain any special character",
    domain_name: "Invalid format for Subdomain Name, input should not contain any special characters",
    ios_bundle_identifier: "Invalid format for IOS Bundle Identifier",
    android_package_name: "Invalid format for Android Package Name",
    privacy_policy: "Invalid format for Privacy Policy URL",
    terms_services: "Invalid format for Terms of Service URL",
    support_center: "Invalid format for Support URL",
    website: "Invalid format for Website URL",
  };

  const onSubmit = (value) => {
    setLoad(true);
    let key = Object.keys(value)[0];


      // Use the corresponding regex for validation
    const regexPattern = regexPatterns[key];
    const errorMessage = fieldErrorMessage[key];

    // Check if the input matches the regular expression
    if (regexPattern && !regexPattern.test(value[key])) {
      message.error(`${errorMessage}`);
      setLoad(false);
      return;
    }
    let inputVal =
    key == "domain_name" ? `${value[key]}.csecure.com` : value[key];
    let updatedPrefJson = updateJson(parseJSON, key, inputVal);
    setResellerPreferences({
      source: "console",
      json: JSON.stringify(updatedPrefJson),
    })
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          message.success("Additional detail has been edit successfully");
          getResellerPreferences()
            .then((response1) => {
              if (response1?.data?.header?.code == 200) {
                let updateRes = JSON.parse(response1?.data?.body?.json);
                dispatch(updateStorageJSON(updateRes));
              }
            })
            .catch((error) => message.error("Something went wrong"));
        }
      })
      .catch((error) => message.error("Something went wrong"));
    setLoad(false);
    onClosePopup(true);
  };

  useEffect(() => {
    if (popUpDetail?.userDetail) {
      setValue(popUpDetail?.formFieldName, popUpDetail?.userDetail);
    }
  }, [popUpDetail?.userDetail]);

  useEffect(() => {
    if (domainName !== undefined) {
      setBtnStatus(true);
    }
    const delayTimer = setTimeout(() => {
      if (domainName && domainName !== "") {
        getDomainVerifyStatus(
          { domain_name: `${domainName}.csecure.com` }
        ).then((response) => {
          if (response?.data?.body?.isExists == true) {
            setVerifyDemain(true);
          } else {
            setBtnStatus(false);
            setVerifyDemain(false);
          }
        });
      }
    }, 1000); // Adjust the delay time as needed (1 second in this case)

    return () => {
      clearTimeout(delayTimer);
    };
  }, [domainName]);

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Headings
            title={popUpDetail?.Popuptitle}
            titleSize={2}

          />
        </Col>
        <Col span={24}>
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[24, 3]}>
              <Col span={18}>
                <InputField
                  addonAfter={popUpDetail?.subdomain && ".csecured.com"}
                  fieldname={`${popUpDetail?.formFieldName}`}
                  control={control}
                  iProps={{
                    size: "large",
                    placeholder: "input",
                  }}
                  rules={{
                    required: `${popUpDetail?.formFieldName} required`,
                  }}
                  validate={errors.popUpDetail?.formFieldName && "error"}
                  validMessage={
                    errors.popUpDetail?.formFieldName &&
                    errors.popUpDetail?.formFieldName.message
                  }
                />
                {verifyDomain && (
                  <Text type="danger" className="mb-0">
                    This domain is already taken!
                  </Text>
                )}
              </Col>
              <Col span={6}>
                <Button
                  className="w-100"
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={btnStatus}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default AddUrls;
