import { Login } from './Login';
import { Billing } from './Billing';
import { ActiveUsers } from './ActiveUsers';
import { ConnectedUsers } from './ConnectedUsers';
import { AccountDetails } from './AccountDetails';
import { DownloadAtomSDK } from './DownloadAtomSDK';
import { KeyManagment } from './KeyManagment';
import Roles from '../../configs/Roles';
import { PaymentEstimate } from './Payment';
import { PaymentInformation } from './PaymentInformation';
import { StatusScreens } from './StatusScreens';
import {SubUsers} from "./SubUsers";
const titles = {
  DASHBOARD: 'Dashboard',
  PROFILE: 'Profile',
  REPORTS: 'Reports',
  ADDITIONALDETAIL: 'AdditionalDetail',
};

export const routingArray = (roles) => {
  return [
    {
      component: 'Guestdashboard',
      path: '/dashboard',
      title: titles.DASHBOARD,
      menu: 'Dashboard',
      icon: 'DashboardSvgIcon',
      parent: true,
    },
    {
      component: 'profile',
      path: '/dashboard/profile',
      title: titles.PROFILE,
      menu: 'Dashboard',
      icon: 'DashboardSvgIcon',
      parent: false,
      permission: [roles?.RESELLER],
    },
    {
      component: 'Reports',
      path: '/reports',
      title: titles.REPORTS,
      menu: 'Reports',
      icon: 'ReportsSvgIcon',
      parent: true,
      // permission: [roles?.RESELLER],
    },
    {
      component: 'AdditionalDetail',
      path: '/additional-detail',
      title: titles.ADDITIONALDETAIL,
      menu: 'Additional Details',
      icon: 'ReportsSvgIcon',
      parent: false,
      // permission: [Roles.GUEST],
    },
    ...Login,
    ...Billing,
    ...ActiveUsers,
    ...ConnectedUsers,
    ...AccountDetails,
    ...DownloadAtomSDK,
    ...KeyManagment,
    ...PaymentEstimate,
    ...PaymentInformation,
    ...StatusScreens,
    ...SubUsers
  ];
};
