import React, { useState } from 'react';
import Dashboard from '../../templates/Dashboard';
import PortalDashboard from '../../modules/Dashboard';
import Guestdashboard from '../../modules/GuestDashboard';
import { useKeycloak } from "@react-keycloak/web";
import { decodeToken } from 'react-jwt';

const Components = {
  PortalDashboard,
  Guestdashboard,
};
export default (props) => {
  const { keycloak, initialized } = useKeycloak();
  let token = decodeToken(keycloak?.token);
  let comp=token?.realm_access?.roles?.includes("paid_reseller") ?"PortalDashboard":"Guestdashboard"
  const [loading, setLoading] = useState(false);
  const DashboardComp = Components[comp];

  return (
    <Dashboard load={loading}>
      <DashboardComp />
    </Dashboard>
  )
};
