import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Descriptions,
  Button,
  Dropdown,
  Space,
  Progress,
  Typography,
  Spin,
  message,
} from "antd";
import Headings from "../../atoms/Headigs";
import { Popup } from "../../atoms/Popup";
import AddUrl from "./Components/AddUrlsPopup";
import { getAppStatus } from '../AdditionalDetail/ducks/services';
import {
  MoreOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { updateJson } from "../../../assets/utils/common_functions";
import { useSelector, useDispatch } from "react-redux";
import { updateStorageJSON } from "./ducks/action";
import {
  getResellerPreferences,
  setResellerPreferences,
  updateAppStatus,
} from "./ducks/services";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const { Title } = Typography;
const items = [
  {
    key: "1",
    label: (
      <Button type="link" className="c-black font-500">
        Edit
      </Button>
    ),
  },
];
const items1 = [
  {
    key: "1",
    label: (
      <Button type="link" className="c-black font-500">
        Mark Completed
      </Button>
    ),
  },
];
const antIcon = <LoadingOutlined spin />;

const deleteVPNAccountPopup = ({
  popUpDetail,
  popUpVisibility,
  setPopUpVisibility,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility}
      />
    ),
  };
  return { ...popupContent };
};
const deleteVPNAccountPopup2 = ({
  popUpDetail,
  popUpVisibility2,
  setPopUpVisibility2,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility2,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility2(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility2(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility2}
      />
    ),
  };
  return { ...popupContent };
};

const deleteVPNAccountPopup3 = ({
  popUpDetail,
  popUpVisibility3,
  setPopUpVisibility3,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility3,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility3(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility3(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility3}
      />
    ),
  };
  return { ...popupContent };
};
const deleteVPNAccountPopup4 = ({
  popUpDetail,
  popUpVisibility4,
  setPopUpVisibility4,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility4,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility4(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility4(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility4}
      />
    ),
  };
  return { ...popupContent };
};
const deleteVPNAccountPopup5 = ({
  popUpDetail,
  popUpVisibility5,
  setPopUpVisibility5,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility5,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility5(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility5(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility5}
      />
    ),
  };
  return { ...popupContent };
};
const deleteVPNAccountPopup6 = ({
  popUpDetail,
  popUpVisibility6,
  setPopUpVisibility6,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility6,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility6(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility6(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility6}
      />
    ),
  };
  return { ...popupContent };
};
const deleteVPNAccountPopup7 = ({
  popUpDetail,
  popUpVisibility7,
  setPopUpVisibility7,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility7,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility7(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility7(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility7}
      />
    ),
  };
  return { ...popupContent };
};
const deleteVPNAccountPopup8 = ({
  popUpDetail,
  popUpVisibility8,
  setPopUpVisibility8,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility8,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility8(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility8(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility8}
      />
    ),
  };
  return { ...popupContent };
};
const deleteVPNAccountPopup9 = ({
  popUpDetail,
  popUpVisibility9,
  setPopUpVisibility9,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility9,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility9(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility9(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility9}
      />
    ),
  };
  return { ...popupContent };
};
const deleteVPNAccountPopup10 = ({
  popUpDetail,
  popUpVisibility10,
  setPopUpVisibility10,
}) => {
  const popupContent = {
    closable: true,
    visibility: popUpVisibility10,
    class: "popup-modal",
    width: 700,
    onCancel: () => setPopUpVisibility10(false),
    content: (
      <AddUrl
        onClosePopup={() => setPopUpVisibility3(false)}
        popUpDetail={popUpDetail}
        popUpVisibility={popUpVisibility10}
      />
    ),
  };
  return { ...popupContent };
};

const AdditoinalDetail = () => {



  useEffect(() => {
    
    if (Object.keys(preferenceJSON).length === 0){
      setLoad(true)
    getResellerPreferences()
     .then((res) => {
       if (res?.data?.header?.code == 200) {
         let updateRes = JSON?.parse(res?.data?.body?.json);
         dispatch(updateStorageJSON(updateRes));
         setLoad(false);
       }
     })
     .catch((error) => {
       message.error('Something went wrong');
       
       setLoad(false);
     });
    }
    
   }, []);



  const dispatch = useDispatch();
  const history = useHistory();
  const [load, setLoad] = useState(false);
  const [progress, setProgress] = useState(0);
  const [popUpVisibility, setPopUpVisibility] = useState(false);
  const [popUpVisibility2, setPopUpVisibility2] = useState(false);
  const [popUpVisibility3, setPopUpVisibility3] = useState(false);
  const [popUpVisibility4, setPopUpVisibility4] = useState(false);

  const [popUpVisibility5, setPopUpVisibility5] = useState(false);

  const [popUpVisibility6, setPopUpVisibility6] = useState(false);

  const [popUpVisibility7, setPopUpVisibility7] = useState(false);

  const [popUpVisibility8, setPopUpVisibility8] = useState(false);

  const [popUpVisibility9, setPopUpVisibility9] = useState(false);

  const [popUpVisibility10, setPopUpVisibility10] = useState(false);

  const [popUpDetail, setPopUpDetail] = useState({
    userDetail: "",
    Popuptitle: "",
    Popupdescription: "",
    formFieldName: "",
    subdomain: false,
  });
  const preferenceJSON = useSelector(
    (state) => state.additionalDetail.accountDetailsJSON
  );
  const popupContent = deleteVPNAccountPopup({
    popUpDetail,
    popUpVisibility,
    setPopUpVisibility,
  });
  const popupContent2 = deleteVPNAccountPopup2({
    popUpDetail,
    popUpVisibility2,
    setPopUpVisibility2,
  });
  const popupContent3 = deleteVPNAccountPopup3({
    popUpDetail,
    popUpVisibility3,
    setPopUpVisibility3,
  });
  const popupContent4 = deleteVPNAccountPopup4({
    popUpDetail,
    popUpVisibility4,
    setPopUpVisibility4,
  });
  const popupContent5 = deleteVPNAccountPopup5({
    popUpDetail,
    popUpVisibility5,
    setPopUpVisibility5,
  });
  const popupContent6 = deleteVPNAccountPopup6({
    popUpDetail,
    popUpVisibility6,
    setPopUpVisibility6,
  });
  const popupContent7 = deleteVPNAccountPopup7({
    popUpDetail,
    popUpVisibility7,
    setPopUpVisibility7,
  });
  const popupContent8 = deleteVPNAccountPopup8({
    popUpDetail,
    popUpVisibility8,
    setPopUpVisibility8,
  });
  const popupContent9 = deleteVPNAccountPopup9({
    popUpDetail,
    popUpVisibility9,
    setPopUpVisibility9,
  });
  const popupContent10 = deleteVPNAccountPopup10({
    popUpDetail,
    popUpVisibility10,
    setPopUpVisibility10,
  });

  const onMenuClick = (key, value) => {
    setLoad(true);
    let updatedJson = updateJson(preferenceJSON, key, value);
    setResellerPreferences({
      source: "console",
      json: JSON.stringify(updatedJson),
    }).then((response) => {
      if (response?.data?.header?.code == 200) {
        getResellerPreferences().then((response) => {
          if (response?.data?.header?.code == 200) {
            let updateRes = JSON.parse(response?.data?.body?.json);
            dispatch(updateStorageJSON(updateRes));
          }
        });
      }
    });
    setLoad(false);
  };
  const onSubmitProceedDev = () => {
    if (progress == 100) {
      setLoad(true);
      updateAppStatus({
        key: "app_details",
        status: "complete",
      })
        .then((response) => {
          if (response?.data?.header?.code == 200) {
            history.push("/dashboard");
            message.success("Details added successfully");
          }
        })
        .catch((error) => {
          message.error("Something went wrong");
        });
    }
  };
  useEffect(() => {
    let progress = {
      org_name: preferenceJSON?.app_settings?.org_name,
      ios_bundle_identifier:
        preferenceJSON?.app_settings?.ios_bundle_identifier,
      appstore_account_access:
        preferenceJSON?.app_settings?.appstore_account_access,
      android_package_name: preferenceJSON?.app_settings?.android_package_name,
      googleplay_account_access:
        preferenceJSON?.app_settings?.googleplay_account_access,
      privacy_policy: preferenceJSON?.app_urls?.external_links?.privacy_policy,
      terms_services: preferenceJSON?.app_urls?.external_links?.terms_services,
      support_center: preferenceJSON?.app_urls?.external_links?.support_center,
      website: preferenceJSON?.app_urls?.external_links?.website,
      domain_name: preferenceJSON?.app_settings?.domain_name,
    };
    const totalKeys = Object.keys(progress).length;
    let completedKeys = 0;

    for (const key in progress) {
      const value = progress[key];
      if (value === true || (typeof value === "string" && value.length > 0)) {
        completedKeys++;
      }
    }
    const completionPercentage = (completedKeys / totalKeys) * 100;
    setProgress(completionPercentage);
  }, [preferenceJSON, popUpVisibility]);

  // useEffect(() => {
  //   setLoad(true)
  //   getResellerPreferences(3)
  //     .then((res) => {
  //       if (res?.status == 200) {
  //         let updateRes = JSON?.parse(res?.data?.data?.json);
  //         dispatch(updateStorageJSON(updateRes));
  //         setLoad(false);
  //       }
  //     })
  //     .catch((error) => message.error("Something went wrong"));
  // }, [popUpVisibility]);

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Card className="design-card">
        <Row gutter={[24, 30]}>
          {/* <Col span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
      </Col> */}

          <Col span={24}>
            <Headings
              title="Additional details"
              titleSize={2}
              description={`Please fill the requested details below so that we can start the development process.`}
              space={5}
              descClass="mb-0 c-gray"
            />
          </Col>
          <Col span={24}>
            <Space direction="vertical" className="w-100" size={3}>
              <Title level={4} className="mb-0 RegularFont text-center">
                {`${Math.ceil(progress)}% completed`}
              </Title>
              <Progress
                percent={progress}
                showInfo={false}
                strokeColor="#6F5BF6"
              />
            </Space>
          </Col>
          <Col span={24}>
            <Card className="design-card-small">
              <Descriptions
                className="reqData"
                column={1}
                colon={false}
                contentStyle={{
                  justifyContent: "end",
                  fontWeight: "700",
                  alignItems: "center",
                }}
                labelStyle={{ color: "" }}
              >
                {/* {detailRequiredFields.map((value) => (
                  <DescriptionItem
                    descpTitle={value.title}
                    descpDetail={value.description}
                  />
                  // <Title>helllo</Title>
                ))} */}
                <Descriptions.Item
                  key={1}
                  className="item-width-100 link-cursor"
                  label={
                    <Space
                      onClick={() => {
                        setPopUpVisibility(true);
                        setPopUpDetail({
                          userDetail: preferenceJSON?.app_settings?.org_name,
                          Popuptitle: "Organization Name",
                          formFieldName: "org_name",
                          Popupdescription: "",
                        });
                      }}
                      size={25}
                      className="mt-1 w-100"
                    >
                      {preferenceJSON?.app_settings?.org_name ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                     <Headings
                        title="Organization Name"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={preferenceJSON?.app_settings?.org_name}
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {'Please enter the full and official name of your organization'}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          setPopUpVisibility(true);
                          setPopUpDetail({
                            userDetail: preferenceJSON?.app_settings?.org_name,
                            Popuptitle: "Organization Name",
                            formFieldName: "org_name",
                            Popupdescription: "",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={2}
                  className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      setPopUpVisibility2(true);
                          setPopUpDetail({  
                            userDetail:
                              preferenceJSON?.app_settings?.domain_name.replace(
                                /\.csecure\.com/g,
                                ""
                              ),
                            Popuptitle: "Subdomain Name",
                            formFieldName: "domain_name",
                            Popupdescription: "",
                            subdomain: true,
                          });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_settings?.domain_name ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="Subdomain Name"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={preferenceJSON?.app_settings?.domain_name}
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Choose a unique subdomain for your organization's dedicated access."}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          setPopUpVisibility2(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_settings?.domain_name.replace(
                                /\.csecure\.com/g,
                                ""
                              ),
                            Popuptitle: "Subdomain Name",
                            formFieldName: "domain_name",
                            Popupdescription: "",
                            subdomain: true,
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={3}
                  className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      setPopUpVisibility3(true);
                      setPopUpDetail({
                        userDetail:
                          preferenceJSON?.app_settings
                            ?.ios_bundle_identifier,
                        Popuptitle: "IOS Bundle Identifier",
                        formFieldName: "ios_bundle_identifier",
                        Popupdescription: "",
                      });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_settings?.ios_bundle_identifier ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="IOS Bundle Identifier"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={
                          preferenceJSON?.app_settings?.ios_bundle_identifier
                        }
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Enter the unique identifier for your iOS app, typically in a reverse-domain format. Example: com.domain.appname"}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          setPopUpVisibility3(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_settings
                                ?.ios_bundle_identifier,
                            Popuptitle: "IOS Bundle Identifier",
                            formFieldName: "ios_bundle_identifier",
                            Popupdescription: "",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={4}
                  // className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      setPopUpDetail({
                        Popuptitle: "Apple Developer Account Access",
                        Popupdescription: "",
                      });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_settings?.appstore_account_access ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="Apple Developer Account Access"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={
                          preferenceJSON?.app_settings?.appstore_account_access
                            ? "Completed"
                            : "Not Complete"
                        }
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Share access to your Apple Developer account on oem@purevpn.com so we can help with your app on the App Store."}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items: items1,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          onMenuClick("appstore_account_access", true);
                          setPopUpDetail({
                            Popuptitle: "Apple Developer Account Access",
                            Popupdescription: "",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={5}
                  className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      setPopUpVisibility4(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_settings
                                ?.android_package_name,
                            Popuptitle: "Android Package name",
                            Popupdescription: "",
                            formFieldName: "android_package_name",
                          });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_settings?.android_package_name ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="Android Package name"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={
                          preferenceJSON?.app_settings?.android_package_name
                        }
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Provide the unique package name for your Android application usually in a reverse domain format."}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          setPopUpVisibility4(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_settings
                                ?.android_package_name,
                            Popuptitle: "Android Package name",
                            Popupdescription: "",
                            formFieldName: "android_package_name",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={6}
                  // className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      onMenuClick("googleplay_account_access", true);
                      setPopUpDetail({
                        Popuptitle: "Google Play Account Access",
                        Popupdescription: "",
                      });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_settings
                        ?.googleplay_account_access ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="Google Play Account Access"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={
                          preferenceJSON?.app_settings
                            ?.googleplay_account_access
                            ? "Completed"
                            : "Not Complete"
                        }
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Provide permissions for us at oem@purevpn.com to manage and update your app on the Google Play Store."}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items: items1,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          onMenuClick("googleplay_account_access", true);
                          setPopUpDetail({
                            Popuptitle: "Google Play Account Access",
                            Popupdescription: "",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={7}
                  className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      setPopUpVisibility5(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_urls?.external_links
                                ?.privacy_policy,
                            Popuptitle: "Privacy Policy URL",
                            formFieldName: "privacy_policy",
                            Popupdescription: "",
                          });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_urls?.external_links
                        ?.privacy_policy ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="Privacy Policy URL"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={
                          preferenceJSON?.app_urls?.external_links
                            ?.privacy_policy
                        }
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Provide a link to your app's privacy policy."}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          setPopUpVisibility5(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_urls?.external_links
                                ?.privacy_policy,
                            Popuptitle: "Privacy Policy URL",
                            formFieldName: "privacy_policy",
                            Popupdescription: "",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={8}
                  className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      setPopUpVisibility6(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_urls?.external_links
                                ?.terms_services,
                            Popuptitle: "Terms of Service URL",
                            formFieldName: "terms_services",
                            Popupdescription: "",
                          });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_urls?.external_links
                        ?.terms_services ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="Terms of Service URL"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={
                          preferenceJSON?.app_urls?.external_links
                            ?.terms_services
                        }
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Insert the URL of the terms and conditions for users to review."}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          setPopUpVisibility6(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_urls?.external_links
                                ?.terms_services,
                            Popuptitle: "Terms of Service URL",
                            formFieldName: "terms_services",
                            Popupdescription: "",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={9}
                  className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      setPopUpVisibility7(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_urls?.external_links
                                ?.support_center,
                            Popuptitle: "Support URL",
                            formFieldName: "support_center",
                            Popupdescription: "",
                          });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_urls?.external_links
                        ?.support_center ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="Support URL"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={
                          preferenceJSON?.app_urls?.external_links
                            ?.support_center
                        }
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Enter the link where users can access help or customer support for your app."}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          setPopUpVisibility7(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_urls?.external_links
                                ?.support_center,
                            Popuptitle: "Support URL",
                            formFieldName: "support_center",
                            Popupdescription: "",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
                <Descriptions.Item
                  key={10}
                  className="item-width-100 link-cursor"
                  label={
                    <Space onClick={() => {
                      setPopUpVisibility8(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_urls?.external_links?.website,
                            Popuptitle: "Website URL",
                            formFieldName: "website",
                            Popupdescription: "",
                          });
                    }} size={25} className="mt-1 w-100">
                      {preferenceJSON?.app_urls?.external_links?.website ? (
                        <CheckCircleFilled
                          style={{ fontSize: "22px", color: "#6f5bf6" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ fontSize: "22px" }} />
                      )}
                      <Headings
                        title="Website URL"
                        titleSize={3}
                        titleClass="font-400"
                        space={0}
                        description={
                          preferenceJSON?.app_urls?.external_links?.website
                        }
                        descClass="c-gray"
                        icon = {QuestionCircleOutlined}
                        tooltipText = {"Provide the direct link to your official website."}
                      />
                    </Space>
                  }
                >
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key == 1) {
                          setPopUpVisibility8(true);
                          setPopUpDetail({
                            userDetail:
                              preferenceJSON?.app_urls?.external_links?.website,
                            Popuptitle: "Website URL",
                            formFieldName: "website",
                            Popupdescription: "",
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                    arrow={{ pointAtCenter: true }}
                  >
                    <MoreOutlined />
                  </Dropdown>
                </Descriptions.Item>
              </Descriptions>
              <Button
                type="primary"
                className="w-100 mt-1"
                size="large"
                disabled={progress == 100 ? false : true}
                onClick={onSubmitProceedDev}
              >
                Proceed to Development
              </Button>
            </Card>
          </Col>
          {/* <Col span={24}>
          </Col> */}
        </Row>
      </Card>
      <Popup {...popupContent} />
      <Popup {...popupContent2} />
      <Popup {...popupContent3} />
      <Popup {...popupContent4} />
      <Popup {...popupContent5} />
      <Popup {...popupContent6} />
      <Popup {...popupContent7} />
      <Popup {...popupContent8} />
      <Popup {...popupContent9} />
      <Popup {...popupContent10} />
    </Spin>
  );
};

export default AdditoinalDetail;
