import React, { useState } from "react";
import Dashboard from "../../templates/Dashboard";
import AdditionalDetail from "../../modules/AdditionalDetail";
const Components = {
    AdditionalDetail
};

export default (props) => {
  const [loading, setLoading] = useState(false);
  const AdditionalDetailComp = Components[props.Comp];
  return (
    <Dashboard load={loading}>
      <AdditionalDetailComp />
    </Dashboard>
  );
};
