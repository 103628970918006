// src/pages/SignUpFormOfAcl.js
import React from 'react';
import { Layout } from 'antd';
import SubUserForm from '../../modules/SubUsers/SubUsersForm';
import './SignupForm.css'; // Importing the CSS file

const { Header, Content, Footer } = Layout;
const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#001529', // Customize your theme color
    padding: '0 20px',
};
const SignUpFormOfAcl = () => {
    return (
        <div className='default-form-css'>
        <Layout style={{ minHeight: '100vh'}}>
            <Content style={{ padding: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', }}>
            <logo style={{margin:'0 auto', display: 'flex', justifyContent: 'center',}}>
            <img src='https://www.purewl.com/wp-content/uploads/2023/08/logo-placeholder-1.png' alt="Logo" style={{ height: 'white', textAlign: 'center', marginLeft:'20px', width: '50%', paddingTop:'10px' }} />
            </logo>
                <SubUserForm />
            </Content>
            <Footer style={{ textAlign: 'center', background: 'transparent', }}>
                2024 © PureWL. All Rights Reserved
            </Footer>
        </Layout>
        </div>
    );
};

export default SignUpFormOfAcl;
