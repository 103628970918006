import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Typography,
  Space,
  Button,
  Steps,
  Avatar,
  Spin,
} from "antd";
import Headings from "../../atoms/Headigs";
import {
  AdditionalDetalsIcon,
  AppStoreIcon,
  DesignSvgIcon,
  DevelopmentIcon,
  GoLiveIcon,
  GooglePlayIcon,
  PaymentIcon,
  UatIcon,
} from "../../atoms/CustomIcons";
import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Lottie from "lottie-react";
import live from "../../../assets/lottie/live.json";
import Dashboard from "../../templates/Dashboard";

const LiveScreen = (props) => {
  const { Paragraph, Text } = Typography;
  return (
    <Col span={24}>
      <Headings
        title="Live"
        titleSize={2}
        description={`Your apps have been Successfully published on the app stores.`}
        space={5}
      />
      <Col span={24} style={{ justifyContent: "center", display: "flex" }}>
        <Lottie
          animationData={live}
          loop={true}
          style={{ width: "20%", height: "20%" }}
        />
      </Col>
      <Col span={24} style={{ justifyContent: "center", display: "flex" }}>
        <Row>
          <Space direction="horizontal" size={"large"} style={{ padding: 20 }}>
            <GooglePlayIcon />
          </Space>
          <Space direction="horizontal" size={"large"} style={{ padding: 20 }}>
            <AppStoreIcon />
          </Space>
        </Row>
      </Col>
      <Col span={24} style={{ justifyContent: "center", display: "flex", padding: 20 }}>
        <Text className="center-align-text px-2 fontSize16Medium cursor-pointer">
          Download Now!
        </Text>
      </Col>
      <Paragraph className="c-gray center-align-text px-2 fontSize16Medium ">
        Your will be notified as soon as your app is ready for UAT. Please
        reachout to our support
        <Text className="text-base defaultFont"> support@purewl.com</Text> for
        any concern or query.
      </Paragraph>
    </Col>
  );
};
export default LiveScreen;
