import { defineAbility } from '@casl/ability';

export default function defineAbilityFor(token,userPermissions) {
    return defineAbility( (can) => {
        if (token?.realm_access?.roles?.includes("paid_reseller") &&
            token?.realm_access?.roles?.includes("sub_user")) {
            const jsonArray = JSON.parse(userPermissions);
            if(userPermissions){
                jsonArray.forEach(permission => {
                    can('read', permission.module.module_slug); // Example action for the Dashboard module

                });
            }

        } else if (token?.realm_access?.roles?.includes("paid_reseller") &&
            !token?.realm_access?.roles?.includes("sub_user")) {
            can('manage', 'all');
        }
        else {
            can('manage', 'all');
        }

    })
};
