import React, { useEffect } from "react";
import {
  Form,
  Input,
  Checkbox,
  Radio,
  Select,
  Tag,
  Typography,
  DatePicker,
  InputNumber,
} from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import ReactSelect from "react-select";
import OtpInput from "react-otp-input";
import { Controller } from "react-hook-form";
const { RangePicker } = DatePicker;

export const InputNumberField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;
  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);
  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.class}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          render={({ field: { onBlur, value, onChange } }) => (
            <InputNumber
              value={initValue ? initValue : value}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const InputField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    addonAfter
  } = props;
  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);
  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.class}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={({ field: { onBlur, value, onChange } }) => (
            <Input
              addonAfter = {addonAfter}
              value={value}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const InputPassword = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    testID,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={({ field: { onBlur, value, onChange } }) => (
            <Input.Password
              value={value}
              onChange={(e) => {
                onChange(e);
                props.onChange && props.onChange(e);
              }}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};
export const TextAreaField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={props.classes}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue || initValue == 0 ? initValue : ""}
        rules={rules}
        render={({ onBlur, value, onChange, field }) => (
          <Input.TextArea
            value={value}
            onChange={onChange}
            {...field}
            {...iProps}
          />
        )}
      />
    </Form.Item>
  );
};
export const InputRadio = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    noStyle,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);
  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      valuePropName="checked"
      noStyle={noStyle}
      className={props.class ? props.class : ""}
    >
      <Controller
        rules={rules}
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { onBlur, value, onChange } }) => (
          <Radio.Group
            value={value}
            options={props.options}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          ></Radio.Group>
        )}
      />
    </Form.Item>
  );
};
export const InputCheckbox = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      valuePropName="checked"
      noStyle
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ field: { value }, field: { onChange }, field }) => {
          return (
            <Checkbox
              {...field}
              checked={value}
              onChange={(e) => {
                onChange(e.target.checked);
              }}
            >
              {label}
            </Checkbox>
          );
        }}
      />
    </Form.Item>
  );
};
export const CheckboxGroup = (props) => {
  const {
    fieldname,
    label,
    option,
    control,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      validateStatus={validate}
      help={validMessage}
      label={label}
      className={props.class}
    >
      <Controller
        name={fieldname}
        control={control}
        rules={rules}
        defaultValue={initValue ? initValue : ""}
        render={({ value, field: { onChange }, field }) => (
          <Checkbox.Group
            {...field}
            value={value}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            className="w-100"
            options={option}
          />
        )}
      />
    </Form.Item>
  );
};
export const SelectField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    selectOption,
    isRequired,
    initValue,
    validate,
    validMessage,
    readOnly,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      noStyle={props.noStyle}
      className={props.class}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ""}
        rules={rules}
        render={({ onBlur, value, field: { onChange }, field }) => (
          <ReactSelect
            value={value}
            className="customSelect"
            isDisabled={readOnly}
            {...field}
            styles={{
              control: (val) => ({ ...val, minHeight: 32 }),
              menu: (provided, state) => ({
                ...provided,
                borderRadius: "10px",
                color: "black",
                border: "1px solid #403D3925",
              }),
              valueContainer: (vcontain) => ({
                ...vcontain,
                padding: "9px 15px",
                textTransform: "capitalize",
              }),
              dropdownIndicator: (icontain) => ({ ...icontain, padding: 5 }),
              indicatorSeparator: (icontain) => ({
                ...icontain,
                backgroundColor: "#000",
              }),
              option: (vcontain, state) => ({
                ...vcontain,
                padding: "10px",
                borderBottom: "1px solid #403D3925",
                textTransform: "capitalize",
                backgroundColor: state.isSelected ? "#403D3919" : "",
                color: "black",
              }),
              multiValue: (styles) => {
                return {
                  ...styles,
                  backgroundColor: "#F2F2F2",
                  borderRadius: 18,
                  padding: "0 4px",
                };
              },
              multiValueLabel: (styles) => ({
                ...styles,
                color: "#fff",
                paddingLeft: "0px",
              }),
              multiValueRemove: (styles) => ({
                ...styles,
                ":hover": {
                  cursor: "pointer",
                  backgroundColor: "transparent",
                },
              }),
              placeholder: (place) => ({ ...place, color: "rgba(0,0,0,.3)" }),
              noOptionsMessage: (styles) => ({
                ...styles,
                display: "none",
              }),
            }}
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
            onBlur={props.onBlur}
            {...iProps}
            options={selectOption}
            theme={(theme) => ({
              ...theme,
              borderRadius: 2,
              colors: { ...theme.colors, primary: "#6F5BF6" },
            })}
          />
        )}
      />
    </Form.Item>
  );
};

export const OTPField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
    readOnly,
    testID,
  } = props;
  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);
  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.class}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue || initValue == 0 ? initValue : ""}
          rules={rules}
          render={({ onBlur, value, onChange, field }) => (
            <OtpInput
              value={value}
              onChange={onChange}
              {...field}
              {...iProps}
            />
          )}
        />
      </Form.Item>
    </>
  );
};

export const TagSelectField = (props) => {
  const {
    fieldname,
    label,
    control,
    rules,
    isRequired,
    initValue,
    validate,
    validMessage,
    iProps,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, []);

  return (
    <Form.Item
      required={isRequired ? isRequired : false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      noStyle={props.noStyle}
      className={props.class}
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ? initValue : ""}
        rules={rules}
        render={({ onBlur, value, field: { onChange }, field }) => (
          <Select
            {...field}
            value={value}
            mode="tags"
            size="large"
            placeholder="Add email address here, separated after enter"
            {...iProps}
            removeIcon={
              <CloseCircleFilled
                style={{ fontSize: "11px", marginLeft: "7px" }}
              />
            }
            onChange={(e) => {
              onChange(e);
              props.onChange && props.onChange(e);
            }}
          >
            {props.options}
          </Select>
        )}
      />
    </Form.Item>
  );
};
export const DateField = (props) => {
  const {
    fieldname,
    label,
    control,
    iProps,
    rules,
    initValue,
    isRequired,
    validate,
    validMessage,
  } = props;

  useEffect(() => {
    props.valueGot && props.setValue(fieldname, props.valueGot);
  }, [props.valueGot]);

  return (
    <>
      <Form.Item
        required={isRequired ? isRequired : false}
        label={label}
        validateStatus={validate}
        help={validMessage}
        className={props.class}
      >
        <Controller
          name={fieldname}
          control={control}
          defaultValue={initValue ? initValue : ""}
          rules={rules}
          render={({ field: { onChange, value, onBlur } }) =>
            props.type == "range" ? (
              <RangePicker
                {...field}
                style={{ width: "100%" }}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  props.onChange && props.onChange(e);
                }}
                {...iProps}
              />
            ) : (
              <DatePicker
                style={{ width: "100%"}}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  props.onChange && props.onChange(e);
                }}
                {...iProps}
              />
            )
          }
        />
      </Form.Item>
    </>
  );
};
