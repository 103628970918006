/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import { createRoot } from 'react-dom/client';
import Root from "./routing/Root"; 

// ReactDOM.render(
//     <Root />,
//   document.getElementById("root")
// );

const root = createRoot(document.getElementById('root'));
root.render(<Root />);