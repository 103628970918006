import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Image, message, Modal, Row, Select, Space, notification} from "antd";
import {useParams} from "react-router-dom";
import {getSubUserPermission, getAllModules, createUpdateSubUserPermission} from "../ducks/services";
import {Option} from "antd/lib/mentions";
const ManagePermissions = ({subUserId,onClose}) => {
    //const { id } = useParams();
  const [userPermissions,setUserPermissions] = useState('');
  const [modules, setModules] = useState();
  const [selectedModules, setSelectedModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredModules, setFilteredModules] =useState('')
  const [permissionModules, setPermissionModules] = useState('')
    const [moduleLength, setModuleLength] =useState(0)
    const [filterLength, setFilterLength] = useState(0)
    useEffect(() => {
        getAllModules()
            .then((response) => {
                if (response?.status == 200) {
                    setModules(response?.data);
                    setLoading(false); // Set loading to false once data is fetched
                    setModuleLength(response?.data?.length)
                } else {
                    message.error(response?.data?.header?.message);
                }
            })
            .catch((error) => message.error('Something went wrong'));
    }, []);
    useEffect(() => {
        const params = {
        subUserId: subUserId,
    };
    getSubUserPermission(params)
        .then((response) => {
          if (response?.status == 200) {
            setUserPermissions(response?.data);
          } else {
            message.error(response?.data?.header?.message);
          }
        })
        .catch((error) => message.error('Something went wrong'));
  }, [subUserId]);
    useEffect(() => {
        // Effect for filtering modules based on user permissions
        if ((userPermissions && userPermissions.length > 0) && (modules && modules.length > 0)) {
            const subUserModuleIds = userPermissions.map(permission => permission.moduleId);
            const filteredModules = modules.filter(module => !subUserModuleIds.includes(module.id));
            setFilterLength(filteredModules?.length)
            const permissionModules = modules.filter(module => subUserModuleIds.includes(module.id));
            setFilteredModules(filteredModules);
            setPermissionModules(getModuleOptions(permissionModules))
        }
        else{
            setPermissionModules([])
            setFilteredModules(modules);
            setFilterLength(moduleLength)
        }
    }, [userPermissions]);
    const handleOk = () => {
        // Handle OK action if needed
        const payload = {moduleIds:selectedModules,subUserId:parseInt(subUserId)}
        createUpdateSubUserPermission(payload).then((response) => {
            if (response?.data?.status == 200) {
                setSelectedModules([]); // Clear the selected modules
                setFilterLength(filteredModules?.length)
                setModuleLength(modules?.length)

                notification.success({
                    message: 'User Permission',
                    description: 'User Permission has been added successfully.',
                    placement: 'bottomRight' // Adjust the placement as needed
                });
                onClose();
            } else {
                message.error(response?.data?.header?.message);
            }
        })
            .catch((error) => message.error('Something went wrong'));

    };
    const handleChange = (value) => {
        setSelectedModules(value);
        //setPermissionModules(value);value
        const new_filtered_values = modules.filter(module => !value.includes(module.id));
        const new_permission_values = modules.filter(module => value.includes(module.id));

        setFilteredModules(new_filtered_values);
        setPermissionModules(getModuleOptions(new_permission_values))
        // You can submit the form here, or trigger the submission as per your requirement
        // For example:
        submitForm(value);
    };

    const submitForm = (selectedValues) => {
        // Here you can perform the form submission with the selected values
        console.log('Form submitted with selected values:', selectedValues);
    };
    const getModuleOptions = (modules) => {
        if(modules)
        return modules.map(module => ({ key: module.id, value: module.id, label: module.module_name }));
        return [];
    };
  return (
      <div style={{ padding: '16px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
                  <div style={{ marginBottom: '16px' }}>
                      {filteredModules && modules && filterLength < moduleLength ? (
                          <>Update Permissions</>
                      ) : (
                          <>Add Permissions</>
                      )}
                      <Select
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="Please select"
                          value={permissionModules}
                          onChange={handleChange}
                          defaultValue={selectedModules}
                      >
                          {filteredModules && filteredModules.length > 0 &&
                              filteredModules.map((module) => (
                              <Option key={module.id} value={module.id}>
                                  {module.module_name}
                              </Option>
                          ))}
                      </Select>
                  <Button type="primary" style={{ margin: '12px 10px' }} onClick={handleOk}>
                      {filteredModules && modules && filterLength < moduleLength ? (
                          <>Update</>
                      ) : (
                          <>Submit</>
                      )}
                  </Button>
                  <Button onClick={onClose}>Cancel</Button>
              </div>
      </div>
  );
};
export default ManagePermissions;
