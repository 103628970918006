import * as action_types from "./constants";

export const updateStripeAmount = (amount) => {
    return (dispatch) => {
      dispatch({
        type: action_types.STRIPE_AMOUNT,
        data: amount,
      });
    };
  };

export const updateUserbase = (users) => {
  return (dispatch) => {
    dispatch({
      type: action_types.USER_BASE,
      data: users,
    });
  };
}