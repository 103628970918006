import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  url: process.env.KEY_CLOAK_BASEURL,
  realm:process.env.KEY_CLOAK_REALM,
  clientId: process.env.KEY_CLOAK_CLIENT_ID,
  onLoad: "check-sso",
  KeycloakResponseType: "code",
});
export default keycloak;
