import React, { useState} from "react";
import Dashboard from "../../templates/Dashboard";
import ConnectedUsers from "../../modules/ConnectedUsers";
import GuestConnectedUsers from "../../modules/GuestConnectedUsers";
import { useKeycloak } from "@react-keycloak/web";
import { decodeToken } from 'react-jwt';

const Components = {
    ConnectedUsers,
    GuestConnectedUsers
  };
export default (props) => {
    const [loading, setLoading] = useState(false);
    const { keycloak, initialized } = useKeycloak();

    let token = decodeToken(keycloak?.token);

    let comp=token?.realm_access?.roles?.includes("paid_reseller") ?"ConnectedUsers":"GuestConnectedUsers"
    const ConnectedUsersComp = Components[comp];
    return (
        <Dashboard load={loading}>
            <ConnectedUsersComp />
        </Dashboard>
    );
}