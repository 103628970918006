import React, { useState, useEffect } from "react";
import { Row, Col, Menu, Card } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import {routingArray} from "../../../routing/config/RoutingList";
import { useKeycloak } from '@react-keycloak/web';
import { decodeToken } from 'react-jwt';
import {
  DashboardSvgIcon,
  UserNavSvgIcon,
  UsersSvgIcon,
  TeamsNavSvgIcon,
  GatewaysSvgIcon,
  CustomizePlanSvgIcon,
  InvoicesNavSvgIcon,
  DownloadSdkSvgIcon,
  ReportsSvgIcon,
  KeySvgIcon,
  DashboardDetailSvg,
} from "../../atoms/CustomIcons";
import {useAbility} from "../../../configs/AbilityContext";
const IconList = {
  DashboardSvgIcon,
  UserNavSvgIcon,
  UsersSvgIcon,
  TeamsNavSvgIcon,
  GatewaysSvgIcon,
  CustomizePlanSvgIcon,
  InvoicesNavSvgIcon,
  DownloadSdkSvgIcon,
  ReportsSvgIcon,
  KeySvgIcon,
  DashboardDetailSvg,
};
export default (props) => {

  const { keycloak, initialized } = useKeycloak();
    let token = decodeToken(keycloak?.token);
    let role = token?.realm_access?.roles?.includes("paid_reseller");

  const [menuList, setMenuList] = useState([]);
  const history = useHistory();
  const location = useLocation().pathname;
  const subkey = location.split("/")[1];
  const selected =
    location.split("/").length > 1 ? `/${location.split("/")[1]}` : location;
  useEffect(() => {
    ModifyJson(routingArray());
  }, []);
  const ModifyJson = (data) => {
    var result = data.reduce(function (r, a) {
      if (a.sidebar !== false) {
        if (a.parent) {
          r[a["menu"]] = r[a["menu"]] || [];
          if (a.submenu) {
            r[a["menu"]].push(a);
          } else {
            r[a["menu"]] = a;
          }
        }
      }
      return r;
    }, Object.create(null));
    setMenuList(result);
  };

  const ExportIcon = (props) => {
    const ICON = IconList[props.val.icon];
    return <ICON />;
  };

  const items = [
    ...Object.entries(menuList).map(([key, val], index) => ({
      key: `${val.path}`,
      label: `${val.menu}`,
      className: "menu-item",
      // disabled: userDetail && userDetail?.roles?.includes("guest") ? true : false,
      icon: <ExportIcon val={val} />,
    })),
  ];

  const ability = useAbility();
  const filteredItems = items.filter(item => !(!role && (item.label === 'Download SDK' || item.label === 'Key Managment')));
  //to allow user only allowed pages
  const itemsWithPermissions = filteredItems
      .filter(item => {
        // Check if the user has permission to read the item
        return ability.can('read', item.key);
      })
      .map(item => {
        // Return the item if the user has permission to read it
        return item;
      });
  return (
    <Row gutter={[30, 24]}>
      <Col span={24}>
        <Card
          bordered={false}
          className="transparent-card"
          style={{ height: "calc(100vh - 127px)" }}
        >
          <Menu
            onClick={(item) => history.push(item.key)}
            mode="inline"
            defaultSelectedKeys={[selected]}
            defaultOpenKeys={[subkey]}
            className="main-menu"
            items={itemsWithPermissions}
          ></Menu>
        </Card>
      </Col>
    </Row>
  );
};
