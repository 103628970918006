// AbilityContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Ability } from '@casl/ability';
import defineAbilityFor from "../assets/utils/ability";
import {useKeycloak} from "@react-keycloak/web";
import {decodeToken} from "react-jwt";
import {getSubUserDetail, getSubUserPermission} from "Modules/SubUsers/ducks/services";
const AbilityContext = createContext();

export const AbilityProvider = ({ children }) => {
    const [ability, setAbility] = useState(new Ability());
    const { keycloak, initialized } = useKeycloak();
    const token = decodeToken(keycloak?.token)

    useEffect(() => {
        if(initialized){
            fetchUserPermissionData(token);
        }
    }, [initialized]);
    const fetchUserPermissionData = async (token) => {
        let responsePermission = null;
        if (!token) return; // Guard clause to handle undefined or falsy tokens
        const {data} =  await getSubUserDetail(token?.email);
        if(data?.status == 200 && data.data.sub_user){
            const param = {
                subUserId: data.data.subUserDetail.id
            }
             const response =  await getSubUserPermission(param);
            if(response?.status == 200){
                responsePermission = response?.data;
            }
        }
        const userAbility = defineAbilityFor(token,JSON.stringify(responsePermission))
        setAbility(userAbility);
    };

    return (
        <AbilityContext.Provider value={ability}>
            {children}
        </AbilityContext.Provider>
    );
};

export const useAbility = () => useContext(AbilityContext);
