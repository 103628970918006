import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Typography,
} from "antd";
import Headings from "../../atoms/Headigs";
import {
  AndroidIcon,
  IosIcon,
} from "../../atoms/CustomIcons";

const UATScreen = (props) => {
  const { Paragraph, Text, Title } = Typography;
  return (
    <Col span={24}>
      <Headings
        title="UAT"
        titleSize={2}
        description={`Your apps have been shared for testing. The apps will be rolled out to the stores in 3 days.`}
        space={5}
      />

      <Row style={{ marginTop: 60, marginBottom: 60 }} justify={"space-evenly"}>
        <Card className="small-card os-card" bordered>
          <Col span={24}>
            <Row style={{alignItems: 'baseline'}} justify={"center"}>
              <AndroidIcon />
              <Title style={{ padding: 5 }} level={2}>
                Android
              </Title>
            </Row>
            <Paragraph className="c-gray center-align-text fontSize12Small mt-2">
              The APK has been shared via email. Please use the provided link to
              download it and access it with your registered email address.
            </Paragraph>
          </Col>
        </Card>
        <Card className="small-card os-card" bordered>
          <Col span={24}>
            <Row style={{alignItems: 'flex-start'}} justify={"center"}>
              <IosIcon />
              <Title style={{ padding: 5 }} level={2}>
                iOS
              </Title>
            </Row>
            <Paragraph className="c-gray center-align-text fontSize12Small mt-2">
              Your app can be found on TestFlight. Access to this version
              alongside redeem code has been shared with you via your registered
              email address.
            </Paragraph>
          </Col>
        </Card>
      </Row>

      <Paragraph className="c-gray center-align-text px-2 fontSize16Medium ">
        Your will be notified as soon as your app is ready for UAT. Please
        reachout to our support
        <Text className="text-base defaultFont"> support@purewl.com</Text> for
        any concern or query.
      </Paragraph>
    </Col>
  );
};
export default UATScreen;
