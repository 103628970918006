import axios from "axios";
import {getUserInfoFromKeyCloak} from "Modules/Login/ducks/services";
import {message} from "antd";
const BASE_URL = process.env.REACT_APP_BASE_URL;
// actions/userActions.js
export const SET_USER_DATA = 'SET_USER_DATA';

export const setUserData = () => async (dispatch) => {
    try {
        const response = await getUserInfoFromKeyCloak();
        if (response?.data?.status == 200) {
            let resData = response?.data?.data;
            dispatch({
                type: SET_USER_DATA,
                data: resData,
            });
            //want to set this data on reduce
        } else {
            message.error(response?.data?.header?.message);
        }

    } catch (error) {}
};
export function postLogin(data) {
  return axios
    .post(`${BASE_URL}/api/admin/login`, {
      email: data.email,
      password: data.password,
      token: data.token,
      _csrf: data.csrfToken,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
