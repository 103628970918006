import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ROOT } from "../configs/routeNames";
import { Provider } from "react-redux";
import store from "../redux/store";
import "../assets/styles/antd.less";
import "../assets/styles/styles.scss";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "../configs/KeycloackContext";

import Pages from "./Pages";
import {AbilityProvider} from "../configs/AbilityContext";
import SignUpFormOfAcl from "../app/Pages/SubUsers/signUpFormOfAcl";

const Root = () => {
  return (
    <ReactKeycloakProvider authClient={keycloak}>
      <Provider store={store}>
          <AbilityProvider>
          <Router>
          <Switch>
              <Route path="/create-user" component={SignUpFormOfAcl} />
              <Route path={ROOT} component={Pages} />
          </Switch>
        </Router>
          </AbilityProvider>
      </Provider>
    </ReactKeycloakProvider>
  );
};

export default Root;
