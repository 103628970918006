import React, { useState, useEffect } from "react";
import { Row, Col, Space, message, Badge } from "antd";
import Headings from "../../../../../atoms/Headigs";
import ListCard from "../../../../../molecules/ListCard";
import { getLogs, getAllLogs } from "../../../ducks/services";
import { useSelector } from "react-redux";

const ListCol2 = [
  {
    title: "User Name",
    dataIndex: "username",
    key: "username",
    // ...getColumnSearchProps("UserName"),
  },
  {
    title: "Host",
    dataIndex: "HOST",
    key: "HOST",
    // ...getColumnSearchProps("CallingCountry"),
  },
  {
    title: "Server IP",
    dataIndex: "server_ip",
    key: "server_ip",
    // ...getColumnSearchProps("Protocol"),
  },
  {
    title: "Tunnel",
    dataIndex: "TunnelType",
    key: "TunnelType",
    // ...getColumnSearchProps("ConnectionDate"),
  },
  {
    title: "Protocol",
    dataIndex: "protocol",
    key: "protocol",
    // ...getColumnSearchProps("Dc_date"),
  },
];

const VPNAccountLogs = (props) => {
  const { accountDetails, onClose } = props;
  const [load, setLoad] = useState(true);
  const [connectedData, setConnectedData] = useState([]);
  const [allLogsData, setAllLogsData] = useState([]);
  const protocols = useSelector((state) => state.global.all_protocols);
  const ListCol = [
    {
      title: "User Name",
      dataIndex: "UserName",
      key: "UserName",
      // ...getColumnSearchProps("UserName"),
    },
    {
      title: "Connected Country",
      dataIndex: "CallingCountry",
      key: "CallingCountry",
      // ...getColumnSearchProps("CallingCountry"),
    },
    {
      title: "Protocol",
      dataIndex: "Protocol",
      key: "Protocol",
      render: (data) =>
        protocols.filter((val) => val?.number === data)[0]?.name,
      // ...getColumnSearchProps("Protocol"),
    },
    {
      title: "Connection Date",
      dataIndex: "ConnectionDate",
      key: "ConnectionDate",
      // ...getColumnSearchProps("ConnectionDate"),
    },
    {
      title: "Disconnect Date",
      dataIndex: "Dc_date",
      key: "Dc_date",
      // ...getColumnSearchProps("Dc_date"),
    },
    {
      title: "Acc Input",
      dataIndex: "AcctInputOctets",
      key: "AcctInputOctets",
      render: (data) => `${(data / Math.pow(10, 9)).toFixed(3)} GB`,
      // ...getColumnSearchProps("AcctInputOctets"),
    },
    {
      title: "Acc Output",
      dataIndex: "AcctOutputOctets",
      key: "AcctOutputOctets",
      render: (data) => `${(data / Math.pow(10, 9)).toFixed(3)} GB`,
      // ...getColumnSearchProps("AcctOutputOctets"),
    },
  ];

  useEffect(() => {
    if (accountDetails?.username) {
      getLogs(accountDetails?.username)
        .then((response) => {
          if (response?.data?.header?.code == 200) {
            setConnectedData(response?.data?.body);
          } else {
            message.error(response?.data?.header?.message);
          }
          setLoad(false);
        })
        .catch((error) => message.error("Something went wrong"));
      getAllLogs(accountDetails?.username)
        .then((response) => {
          if (response?.data?.header?.code == 200) {
            setAllLogsData(response?.data?.body);
          } else {
            message.error(response?.data?.header?.message);
          }
          setLoad(false);
        })
        .catch((error) => message.error("Something went wrong"));
    }
  }, [accountDetails]);

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Headings
          title={"Account Session Records"}
          titleSize={2}
          description={`${accountDetails?.username}`}
          descClass="mb-0 c-gray"
        />
      </Col>
      <Col span={24}>
        <Space className="mb-1" size={10}>
          <Headings title={"Current Data"} titleSize={3} />
          <Badge color="green" dot={true} className="status-dot" />
        </Space>
        <ListCard
          ListCol={ListCol}
          ListData={connectedData?.data}
          listClass="logs-table"
          loading={load}
          // pagination={{
          //   total: connectedData?.total_records,
          //   defaultCurrent: 1,
          //   showSizeChanger: false,
          //   pageSize: 10,
          // }}
        />
      </Col>
      <Col span={24}>
        <Space className="mb-1" size={10}>
          <Headings title={"Logs Data"} titleSize={3} />
          <Badge color="yellow" dot={true} className="status-dot" />
        </Space>
        <ListCard
          ListCol={ListCol2}
          ListData={allLogsData?.data}
          listClass="logs-table"
          loading={load}
          // pagination={{
          //   total: connectedData?.total_records,
          //   defaultCurrent: 1,
          //   showSizeChanger: false,
          //   pageSize: 10,
          // }}
        />
      </Col>
    </Row>
  );
};

export default VPNAccountLogs;
