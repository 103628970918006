import React, { useState } from "react";
import Payment from "../../templates/Payment";
import PaymentInformation from "../../modules/PaymentInformation";
import PaymentSuccess from "../../modules/PaymentSuccess";
const Components = {
  PaymentInformation,
  PaymentSuccess,
};
export default (props) => {
  const [loading, setLoading] = useState(false);
  const PaymentComp = Components[props.Comp];
  return props.Comp == "PaymentInformation" ? (
    <Payment>
      <PaymentComp />
    </Payment>
  ) : (
    <PaymentSuccess></PaymentSuccess>
  );
};
