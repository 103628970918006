import React, { useState } from "react";
import Payment from "../../templates/Payment";
import PaymentEstimate from "../../modules/PaymentEstimate";

export default (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <Payment>
      <PaymentEstimate />
    </Payment>
  );
};
