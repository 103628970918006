import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Spin, message } from "antd";
import { InputField, InputPassword } from "../../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { changePassword } from "../../ducks/services";
import { LoadingOutlined } from "@ant-design/icons";
const antIcon = <LoadingOutlined spin />;

const ChangePassword = (props) => {
  const { accountData } = props;
  const [load, setLoad] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  let checkPassword = watch("password");

  const onSubmit = (value) => {
    setLoad(true);
    const payload = {
      vpnUsername: accountData?.userDetails?.username,
      newPassword: value?.password,
      confirmNewPassword: value?.confirmpassword,
    };
    changePassword(payload).then((response) => {
      setLoad(false)
      if (response?.data?.header?.code == 200) {
        message.success(response?.data?.header?.message);
      } else {
        message.success(response?.data?.header?.message);
      }
    }).catch(error=>message.error('Something went wrong'));
  };

  useEffect(() => {
    if (accountData) {
      setValue("username", accountData?.userDetails?.username);
    }
  }, []);

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[24, 30]}>
              <Col span={24}>
                <InputField
                  label="Username"
                  fieldname="username"
                  control={control}
                  initValue=""
                  iProps={{
                    size: "large",
                    placeholder: "username",
                    disabled: true,
                  }}
                  rules={{
                    required: "username required",
                  }}
                  validate={errors.username && "error"}
                  validMessage={errors.username && errors.username.message}
                />
              </Col>

              <Col span={12}>
                <InputPassword
                  label="Password"
                  fieldname="password"
                  control={control}
                  initValue=""
                  iProps={{
                    size: "large",
                    placeholder: "Password",
                  }}
                  rules={{
                    required: "password required",
                    validate: (value) =>
                      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value) ||
                      "password should contain ",
                  }}
                  validate={errors.password && "error"}
                  validMessage={errors.password && errors.password.message}
                />
              </Col>
              <Col span={12}>
                <InputPassword
                  label="Confirm Password"
                  fieldname="confirmpassword"
                  control={control}
                  initValue=""
                  iProps={{
                    size: "large",
                    placeholder: "Confirm Password",
                  }}
                  rules={{
                    required: "confirm password required",
                    validate: (value) =>
                      value === checkPassword || "The passwords do not match",
                  }}
                  validate={errors.confirmpassword && "error"}
                  validMessage={
                    errors.confirmpassword && errors.confirmpassword.message
                  }
                />
              </Col>
              <Col span={24}>
                <Button type="primary" htmlType="submit" size="large">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default ChangePassword;
