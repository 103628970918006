import Roles from "../../configs/Roles";

const titles = {
    KEY_MANAGMENT: 'KeyManagment',
};

export const KeyManagment = [
    {
        icon: 'KeySvgIcon',
        component: 'KeyManagment',
        path: '/key-managment',
        title: titles.KEY_MANAGMENT,
        key: 'KeyManagment',
        menu: 'Key Managment',
        parent: true,
        permission:[Roles.RESELLER]
    },
];