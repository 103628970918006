import axios from "../../../../configs/axiosIntereptor";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const getSubUserPermission = (params) => {
  return axios.get(
    `${BASE_URL}/api/user-permissions/${params.subUserId}`
  );
};
export const getSubUserDetail = (email) => {
  return axios.get(
      `${BASE_URL}/api/acl/details?email=${email}`
  );
};
export const getResellerSubUserDetail = () => {
  return axios.get(
      `${BASE_URL}/api/acl`
  );
};
export const createSubUser = (payload) => {
  return axios.post(`${BASE_URL}/api/acl/create`, payload);
};
export const sendEmailInvite = (payload) => {
  return axios.post(`${BASE_URL}/api/acl/send-invites`, payload);
};
export const createUpdateSubUserPermission = (payload) => {
  return axios.post(`${BASE_URL}/api/user-permissions/upsert`, payload);
};
export const disableUserOTP = () => {
  return axios.put(`${BASE_URL}/api/user-permissions/disable-otp`);
};
export const setUserOTP = () => {
  return axios.put(`${BASE_URL}/api/user-permissions/set-otp`);
};
export const verifyOTP = (payload) => {
  return axios.post(`${BASE_URL}/api/user-permissions/verify-otp`, payload);
};
export const getAllModules = () => {
  return axios.get(`${BASE_URL}/api/modules`);
};
export const deactivateSubUser = (id) => {
  return axios.post(`${BASE_URL}/api/acl/deactivate/${id}`);
};
export const deleteSubUser = (id) => {
  return axios.delete(`${BASE_URL}/api/acl/delete/${id}`);
};

