import React, {useContext, useEffect, useState} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routingArray } from './config/RoutingList';
import Dashboard from '../app/Pages/Dashboard';
import Login from '../app/Pages/Login';
import Billing from '../app/Pages/Billing';
import ActiveUsers from '../app/Pages/ActiveUsers';
import ConnectedUsers from '../app/Pages/ConnectedUsers';
import Profile from '../app/Pages/Profile';
import SubUsers from '../app/Pages/SubUsers';
import AccountDetails from '../app/Pages/AccountDetails';
import Reports from '../app/Pages/Reports';
import DownloadAtomSDKs from '../app/Pages/DownloadSDKs';
import KeyManagment from '../app/Pages/KeyManagment';
import AdditionalDetail from '../app/Pages/AdditionalDetails';
import { useKeycloak } from '@react-keycloak/web';
import {checkPermission} from '../assets/utils/common_functions';
import PaymentEstimate from '../app/Pages/PaymentEstimate';
import PaymentInformation from '../app/Pages/PaymentInformation';
import DevelopmentScreen from '../app/modules/DevelopmentScreen';
import { decodeToken } from 'react-jwt';
import { useAbility } from '../configs/AbilityContext';
const KEY_CLOAK_ENV = process.env.KEY_CLOAK_ENV;
const KEY_CLOAK_CLIENT_ID = process.env.KEY_CLOAK_CLIENT_ID
const KEY_CLOAK_REALM = process.env.KEY_CLOAK_REALM
const KEY_CLOAK_BASEURL = process.env.KEY_CLOAK_BASEURL
// Pages
const compList = {
  Dashboard,
  Login,
  Billing,
  ActiveUsers,
  ConnectedUsers,
  AccountDetails,
  Profile,
  Reports,
  DownloadAtomSDKs,
  KeyManagment,
  AdditionalDetail,
  PaymentEstimate,
  PaymentInformation,
  DevelopmentScreen,
  SubUsers
};

const Pages = () => {
    const { keycloak, initialized } = useKeycloak();
    const ability = useAbility();
    const token = decodeToken(keycloak?.token)
    const rolesObjects = token?.realm_access?.roles?.reduce((acc, role) => {
      if (role === 'paid_reseller') {
          acc['RESELLER'] = role;
      } else {
          acc[role.toUpperCase()] = role;
      }
      return acc;
  }, {});
    const routeComponents = routingArray(rolesObjects).map(
      ({ title, component, path, permission }, key) => {
        return (

          <Route
            exact
            path={path}
            key={key}
            render={(props) => {
              // if not authenticated, redirect to login page
              if (keycloak?.authenticated == true) {
                if(ability.can('read', path)){
                      const MyComponent = compList[title];
                      if (!permission) {
                          return <MyComponent Comp={component} {...props} />;
                      } else {
                          if (checkPermission(permission,token?.realm_access?.roles)) {
                              return <MyComponent Comp={component} {...props} />;
                          } else {
                              return <Redirect to='/dashboard' />;
                          }
                      }
                  }
                 else {
                     return <Redirect to='/dashboard' />;
                 }
              } else {
                localStorage.clear();
                window.location.replace(
                  `${KEY_CLOAK_BASEURL}/realms/${KEY_CLOAK_REALM}/protocol/openid-connect/auth?client_id=${KEY_CLOAK_CLIENT_ID
                  }&scope=openid%20profile%20email&redirect_uri=${KEY_CLOAK_ENV}&response_type=code`
                );
              }
            }}
          />
        );
      }
    );
  return (
    initialized && (
      <Switch>
        <Route
          exact
          path='/'
          render={(props) => <Login Comp={'Login'} {...props} />}
        />
        {routeComponents}
      </Switch>
    )
  );
};

export default Pages;
