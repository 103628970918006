import React from "react";
import { Card, Row, Col, Image, Typography, Space, Button } from "antd";
import Headings from "../../atoms/Headigs";
import dummyReport from "../../../assets/img/dummyReports.png";

const SCHEDULE_CALL_LINK = process.env.SCHEDULE_CALL_LINK

const GuestReports = () => {
  const { Paragraph } = Typography;
  const handleButtonLink = () => {
    window.open(SCHEDULE_CALL_LINK, '_blank');
  }
  return (
    <Card className="design-card-payment">
      <Row gutter={[24, 30]}>
        <Col span={12}>
          <Headings
            title="Reports"
            titleSize={2}
            description={`Product Insight in a User-friendly Format`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={24} className="dummy-image-container-guest">
          <Image
            className="dummy-image-border"
            preview={false}
            width={1500}
            height={420}
            src={dummyReport}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Paragraph
          className="c-gray fontSize12Small"
        >
          The Report Section is where you can find important data and insights in a user-friendly report format. It facilitates data-driven decision-making and provides regular updates on the status of your system or business through informative reports, including Destination Country, Connected Users, Connected Protocols, Overall Bandwidth, and more.
        </Paragraph>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            className="mt-1-5"
            size="large"
            onClick={() => handleButtonLink()}
          >
            Schedule Call
          </Button>
        </Space>
      </Col>
    </Card>
  );
};

export default GuestReports;
