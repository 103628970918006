import React, { useState } from 'react';
import {Input, Tag, Form, Button, message, Tooltip} from 'antd';

const MultipleEmailInput = ({handleFormSubmit}) => {
    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState([]);

    const handleInputChange = (e) => {
        setEmailInput(e.target.value);
    };

    const isValidEmail = (email) => {
        // Simple email validation regex
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };
    const handleKeyPress = (e) => {
        if (e.key === ',' || e.key === 'Enter' ) {
            e.preventDefault(); // Prevent form submission
            handleInputConfirm();
        }
    };
    const handleInputConfirm = () => {
        if (emailInput && !emails.includes(emailInput)) {
            if (isValidEmail(emailInput)) {
                setEmails([...emails, emailInput]);
            } else {
                message.error('Invalid email address');
            }
        }
        setEmailInput('');
    };

    const handleRemoveEmail = (removedEmail) => {
        setEmails(emails.filter(email => email !== removedEmail));
    };
    const handleSubmit = () => {
        if (emails.length === 0) {
            message.error('Please add at least one email.');
            return;
        }
        handleFormSubmit(emails)
        setEmails([]); // Clear the emails state in parent

    };
    return (
        <Form onFinish={handleSubmit} style={styles.form}>
            <Form.Item>
                {emails.map((email, index) => (
                    <Tag
                        key={index}
                        closable
                        onClose={() => handleRemoveEmail(email)}
                        style={styles.tag}
                    >
                        {email}
                    </Tag>
                ))}
                <Tooltip title="Press Enter to add email. Add multiple emails separated by commas.">
                    <Input
                        type="text"
                        value={emailInput}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder="Press Enter to add email"
                        style={styles.input}
                    />
                </Tooltip>
                <div style={styles.note}>
                    Note: Add multiple emails separated by commas.
                </div>
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};
const styles = {
    form: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        border: '1px solid #eaeaea',
        borderRadius: '10px',
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    tag: {
        marginBottom: '8px',
    },
    input: {
        marginBottom: '8px',
    },
    note: {
        fontSize: '12px',
        color: '#888',
    },
};

export default MultipleEmailInput;
