import React, { useState } from "react";
import Dashboard from "../../templates/Dashboard";
import Profile from "../../modules/Profile";

export default (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <Dashboard load={loading}>
      <Profile />
    </Dashboard>
  );
};
