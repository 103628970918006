import React from "react";
import { Card, Row, Col, Image, Typography, Space, Button } from "antd";
import Headings from "../../atoms/Headigs";
import dummyAccountDetails from "../../../assets/img/dummyAccountDetails.png";

const SCHEDULE_CALL_LINK = process.env.SCHEDULE_CALL_LINK

const GuestAccountDetails = () => {
  const { Paragraph } = Typography;
  const handleButtonLink = () => {
    window.open(SCHEDULE_CALL_LINK, "_blank");
  };
  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        <Col span={12}>
          <Headings
            title="Account Details"
            titleSize={2}
            description={`Dive into the Specifics of Your Account`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={24} className="dummy-image-container-guest">
          <Image
            className="dummy-image-border"
            preview={false}
            width={1500}
            height={420}
            src={dummyAccountDetails}
          />
        </Col>
      </Row>
      <Col span={24}>
        <Paragraph
          // style={{ paddingLeft: 130, paddingRight: 130 }}
          className="c-gray fontSize12Small"
        >
          Explore the details of your account within this section. Check the
          overall count of accounts, total multi-logins, session limitations,
          assigned protocols, and the number of locations linked to your
          account.
        </Paragraph>
      </Col>
      <Space>
        <Button
          type="primary"
          htmlType="submit"
          className="mt-1-5"
          size="large"
          // style={{ marginLeft: 130 }}
          onClick={() => handleButtonLink()}
        >
          Schedule Call
        </Button>
      </Space>
    </Card>
  );
};

export default GuestAccountDetails;
