import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, message, Spin } from "antd";
import { InputField, SelectField } from "../../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { enableDisableVPNAccount } from "../../ducks/services";
import { LoadingOutlined } from "@ant-design/icons";
const options = [
  { label: "Enable", value: "1" },
  { label: "Disable", value: "2" },
];
const antIcon = <LoadingOutlined spin />;

const EnableDisable = (props) => {
  const { accountData } = props;
  const [load, setLoad] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (value) => {
    setLoad(true)
    const payload = {
      action: value?.protocols.label,
      vpnUsername: accountData?.userDetails?.username,
    };
    enableDisableVPNAccount(payload)
      .then((response) => {
        response?.data?.header?.code == 200
          ? message.success(response?.data?.header?.message)
          : message.error("Something went wrong");
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);

        message.error("Something went wrong");
      });
  };

  useEffect(() => {
    setValue("username", accountData?.userDetails?.username);
    setValue(
      "protocols",
      options.filter(
        (value) => value?.value === accountData?.userDetails?.status && value
      )
    );
  }, []);

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[24, 30]}>
              <Col span={24}>
                <InputField
                  label="Username"
                  fieldname="username"
                  control={control}
                  initValue=""
                  iProps={{
                    size: "large",
                    placeholder: "username",
                    disabled: true,
                  }}
                  rules={{
                    required: "username required",
                  }}
                  validate={errors.username && "error"}
                  validMessage={errors.username && errors.username.message}
                />
              </Col>

              <Col span={12}>
                <SelectField
                  fieldname="protocols"
                  label="Choose protocoles"
                  control={control}
                  class="mb-1"
                  iProps={{
                    placeholder: "Select protocols",
                  }}
                  rules={{
                    required: "protocols required",
                  }}
                  initValue=""
                  selectOption={options}
                  validate={errors.protocols && "error"}
                  validMessage={errors.protocols && errors.protocols.message}
                />
              </Col>
              <Col span={24}>
                <Button type="primary" htmlType="submit" size="large">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default EnableDisable;
