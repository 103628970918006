import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Tabs, Space, Button, Input, Tag, message } from "antd";
import Headings from "../../atoms/Headigs";
import { getAtomSDKs } from "./ducks/services";
import ListCard from "../../molecules/ListCard";
import { Popup } from "../../atoms/Popup";
import { SearchOutlined, TagOutlined } from "@ant-design/icons";

import moment from "moment";
import { useKeycloak } from "@react-keycloak/web";


const downloadOnlineJson = (url) => {
  window.open(url, "_blank");
};

const getColumnSearchProps = (dataIndex) => {
  
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const searchInput = useRef(null);
      return (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
              close();
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                close();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      dataIndex == "created"
        ? (record[dataIndex] = moment(record[dataIndex]).format("DD/MMM/YYYY"))
        : record[dataIndex];
      return record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value.toLowerCase());
    },
  };
};

const items = (load, data, setPopUpVisibility, setJsonData, keycloak) => {
  const ListCol = (url) => {
    return [
      {
        title: "Created at",
        dataIndex: "created",
        key: "created",
        ...getColumnSearchProps("created"),
        render: (date) => moment(date).format("DD/MMM/YYYY"),
      },
      {
        title: "Version",
        dataIndex: "version",
        key: "version",
        render: (data) => (
          <Tag key={"version"} color="processing" icon={<TagOutlined />}>
            {data}
          </Tag>
        ),
        ...getColumnSearchProps("version"),
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        align: "center",
        render: (text, record) => {
          return (
            <Space direction="horizontal" size={5}>
              <Button
                type="secondary"
                className="c-black font-500"
                onClick={() =>
                  downloadOnlineJson(
                    `https://github.com/AtomSDK/${url}${record?.version}`
                  )
                }
              >
                Download SDK
              </Button>
              <Button
                type="dashed"
                className="c-black font-500"
                onClick={() => {
                  setPopUpVisibility(true);
                  setJsonData(record?.release_notes);
                }}
              >
                Readmore
              </Button>
              <Button
                type="secondary"
                className="c-black font-500"
                onClick={() =>
                  downloadOnlineJson(
                    `https://assets-prod-a230.s3.serverwild.com/${record?.device_type}/${record?.version}/localdata_${keycloak.tokenParsed.reseller_id}.json`
                  )
                }
              >
                OfflineJson
              </Button>
            </Space>
          );
        },
      },
    ];
  };
  let comp = [
    {
      key: "1",
      label: `IOS`,
      children: (
        <ListCard
          ListCol={ListCol("atomsdk-demo-ios/releases/tag/v")}
          ListData={data?.ios}
          listClass="nospace-card"
          loading={load}
        />
      ),
    },
    {
      key: "2",
      label: `Android`,
      children: (
        <ListCard
          ListCol={ListCol("atomsdk-demo-android/releases/tag/")}
          ListData={data?.android}
          listClass="nospace-card"
          loading={load}
        />
      ),
    },
    {
      key: "3",
      label: `MacOS`,
      children: (
        <ListCard
          ListCol={ListCol("atomsdk-demo-mac/releases/tag/v")}
          ListData={data?.mac}
          listClass="nospace-card"
          loading={load}
        />
      ),
    },
    {
      key: "4",
      label: `Windows`,
      children: (
        <ListCard
          ListCol={ListCol("atomsdk-demo-windows/releases/tag/v")}
          ListData={data?.windows}
          listClass="nospace-card"
          loading={load}
        />
      ),
    },
    {
      key: "5",
      label: `Linux`,
      children: (
        <ListCard
          ListCol={ListCol("atomsdk-demo-linux")}
          ListData={data?.linux}
          listClass="nospace-card"
          loading={load}
        />
      ),
    },
  ];
  return comp;
};

const DownloadSDKs = () => {
  const { keycloak } = useKeycloak();
  const [sdksData, setSDKsData] = useState();
  const [load, setLoad] = useState(true);
  const [popUpVisibility, setPopUpVisibility] = useState(false);
  const [jsonData, setJsonData] = useState();
  function createMarkup() {
    return { __html: jsonData };
  }
  const popupContent = {
    closable: true,
    visibility: popUpVisibility,
    class: "popup-modal",
    width: 1000,
    onCancel: () => setPopUpVisibility(false),
    content: (
      <div className="contentClass" dangerouslySetInnerHTML={createMarkup()} />
    ),
  };

  useEffect(() => {
    getAtomSDKs().then((response) => {
      const groupedData = {};
      if (response?.data?.header?.code==200) {
        response?.data?.body?.data.forEach((item) => {
          const deviceType = item.device_type;
          if (!groupedData[deviceType]) {
            groupedData[deviceType] = [];
          }
          groupedData[deviceType].push(item);
        });
        setSDKsData(groupedData);
      } else {
      }
    }).catch((error)=>message.error('Something went wrong'));
    setLoad(false)
  }, []);

  return (
    <>
      <Card className="design-card">
        <Row gutter={[24, 30]}>
          {/* <Col span={24}>
          <Breadcrumb>
            <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
            <Breadcrumb.Item>Reports</Breadcrumb.Item>
          </Breadcrumb>
        </Col> */}
          <Col span={12}>
            <Headings
              title="Atom Software Development Kit"
              titleSize={2}
              description={`Manage atom software development kit`}
              space={5}
              descClass="mb-0 c-gray"
            />
          </Col>
          <Col span={24}>
            <Tabs
              className="custom-tabs"
              defaultActiveKey="1"
              items={items(load, sdksData, setPopUpVisibility, setJsonData, keycloak)}
            ></Tabs>
          </Col>
        </Row>
      </Card>
      <Popup {...popupContent} />
    </>
  );
};
export default DownloadSDKs;
