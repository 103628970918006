import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Breadcrumb,
  Typography,
  message,
  Slider,
  Divider,
  Space,
  List,
  Avatar,
  Button,
} from "antd";
import Headings from "../../atoms/Headigs";
import { CheckCircleFilled } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { updateStripeAmount, updateUserbase } from "../../Pages/PaymentEstimate/ducks/actions";
import { setLocalStorage } from "../../../assets/utils/common_functions";

const { Text, Title, Paragraph, Link } = Typography;
const PaymentEstimate = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState(1000);
  const [users, setUsers] = useState(500)
  const data = [
    {
      title: "Initial Deposit:",
      description:
        "An upfront charge of $1000 will be deducted as an advance deposit.",
    },
    {
      title: "Per User Charge:",
      description: "We charge $1.99 for each user you provide.",
    },
    {
      title: "Included Users:",
      description:
        "If your user count remains within the initial deposit of $999, there will be no additional charges.",
    },
    {
      title: "Exceeding Users:",
      description:
        "If your user count exceeds the initial deposit, the $1000 monthly bill will increase based on the $1.99 per user charge.",
    },
  ];
  const marks = {
    0: (
      <Space direction="vertical">
        <Divider style={{height: 20}} type="vertical" />
        <Text className="text-grey smallFont12">500</Text>
      </Space>
    ),
    5: (
      <Space direction="vertical">
        <Divider type="vertical" />
        <Text className="text-grey smallFont12">5K</Text>
      </Space>
    ),
    10: (
      <Space direction="vertical">
        <Divider type="vertical" />
        <Text className="text-grey smallFont12">10K</Text>
      </Space>
    ),
    15: (
      <Space direction="vertical">
        <Divider type="vertical" />
        <Text className="text-grey smallFont12">15K</Text>
      </Space>
    ),
    20: (
      <Space direction="vertical">
        <Divider type="vertical" />
        <Text className="text-grey smallFont12">20K</Text>
      </Space>
    ),
    25: (
      <Space direction="vertical">
        <Divider style={{height: 20}} type="vertical" />
        <Text className="text-grey smallFont12">25K</Text>
      </Space>
    ),
    30: (
      <Space direction="vertical">
        <Divider type="vertical" />
        <Text className="text-grey smallFont12">30K</Text>
      </Space>
    ),
    35: (
      <Space direction="vertical">
        <Divider type="vertical" />
        <Text className="text-grey smallFont12">35K</Text>
      </Space>
    ),
    40: (
      <Space direction="vertical">
        <Divider type="vertical" />
        <Text className="text-grey smallFont12">40K</Text>
      </Space>
    ),
    45: (
      <Space direction="vertical">
        <Divider type="vertical" />
        <Text className="text-grey smallFont12">45K</Text>
      </Space>
    ),
    50: (
      <Space style={{ width: 116 }} direction="vertical">
        <Divider style={{height: 20}} type="vertical" />
        <Text className="text-grey smallFont12">50K</Text>
      </Space>
    ),
    // 75: (
    //   <Space direction="vertical">
    //     <Divider type="vertical" />
    //     <Text className="text-grey smallFont12">75K</Text>
    //   </Space>
    // ),
    // 100: (
    //   <Space style={{ width: 116 }} direction="vertical">
    //     <Divider type="vertical" />
    //     <Text className="text-grey smallFont12">100K</Text>
    //   </Space>
    // ),
  };

  const onChangeSliderValue = (user) => {
    const stripeAmount = user === 0 ? 1000 : user * 1000 * 1.99;
    const userBase = user === 0 ? 500 : user * 1000
    setUsers(userBase)
    setAmount(stripeAmount);
  };

  const handleNextButtonClick = () => {
    // Add any other logic or navigation you need here
    dispatch(updateStripeAmount(amount));
    dispatch(updateUserbase(users));
    setLocalStorage('stripeUsers', users.toString(), true )
    history.push("/payment-information");
  };
  return (
    <Card className="design-card-payment" bordered={true}>
      <Row gutter={[24, 24]}>
        <Col span={11}>
          <List
            split={false}
            itemLayout="vertical"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<CheckCircleFilled style={{ color: "#6F5BF6" }} />}
                  title={<Title level={3}>{item.title}</Title>}
                  description={item.description}
                />
              </List.Item>
            )}
          />
        </Col>
        <Col span={2}>
          <Divider type="vertical" style={{ height: "100%" }} />
        </Col>
        <Col span={11}>
          <Row gutter={[24, 24]} justify={"space-between"}>
            <Col>
              <Headings
                title="Estimated Billing Calculator"
                titleSize={2}
                description={`This is an estimated amount of change if your user base exceeds 1000 in the future`}
                space={5}
                descClass="smallFont12"
              />
            </Col>
            <Col>
              <Headings
                title={`$${amount}`}
                titleSize={2}
                description={`Monthly Commitment`}
                space={5}
                descClass="smallFont12"
              />
            </Col>
            <Col span={24}>
              <Space className="w-100" direction="vertical" size={10}>
                {/* <Row style={{ marginTop: 45 }} justify={"space-between"}> */}
                <Space direction="horizontal" className="w-100 doubleVal">
                  <Text className="fontSize12Medium">
                    Slide to specify your user count
                  </Text>
                  <Text className="fontSize12Medium justify-end">
                    $ 1.99 / User
                  </Text>
                </Space>
                {/* </Row> */}
                <Space direction="vertical" size={50}>
                  <Slider
                    tooltip={{open: false}}
                    dots={false}
                    marks={marks}
                    step={5}
                    max={50}
                    defaultValue={0}
                    min={0}
                    // range={true}
                    onChange={onChangeSliderValue}
                  />
                  <Paragraph className="text-step smallFont14 center-align-text px-1">
                    To initiate your development, an advance deposit of $1000 is
                    required. This deposit will be applied towards your first
                    invoice upon completion of the live stage.
                  </Paragraph>
                </Space>
                <Space direction="vertical" size={"large"}>
                  <Button
                    onClick={handleNextButtonClick}
                    type="primary"
                    htmlType="submit"
                    className="w-100"
                    size="large"
                  >
                    Next
                  </Button>
                  <Paragraph className="c-gray center-align-text px-2 smallFont12 ">
                    By Continuing, You Agree To Our{" "}
                    <Text className="text-base smallFont12">
                      <Link href="http://purewl.com/terms" target="_blank">
                        Terms And Conditions
                      </Link>
                    </Text>
                    ,{" "}
                    <Text className="text-base smallFont12">
                      <Link
                        href="http://purewl.com/privacy-policy"
                        target="_blank"
                      >
                        Privacy Policy.
                      </Link>
                    </Text>
                  </Paragraph>
                </Space>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default PaymentEstimate;
