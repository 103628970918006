// reducers/userReducer.js
import { SET_USER_DATA } from './action';

const initialState = {
    userData: {},
};

const userReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case SET_USER_DATA:
            return {
                ...state,
                userData: data,
            };
        default:
            return state;
    }
};

export default userReducer;
