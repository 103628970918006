import React, { useState, useEffect, useCallback } from 'react';
import {
  Row,
  Card,
  Col,
  Typography,
  Space,
  Button,
  Steps,
  Avatar,
  Spin,
  message,
} from 'antd';
import Headings from '../../atoms/Headigs';
import {
  AdditionalDetalsIcon,
  DesignSvgIcon,
  DevelopmentIcon,
  GoLiveIcon,
  PaymentIcon,
  UatIcon,
} from '../../atoms/CustomIcons';
import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DevelopmentScreen from '../DevelopmentScreen';
import UATScreen from '../UATScreen';
import AppReviewScreen from '../AppReview';
import LiveScreen from '../LiveScreen';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import {
  getAppStatus,
  getResellerPreferences,
} from '../AdditionalDetail/ducks/services';
import { updateStorageJSON } from '../AdditionalDetail/ducks/action';
import axios from '../../../configs/axiosIntereptor';
import { useKeycloak } from '@react-keycloak/web';
const antIcon = <LoadingOutlined spin />;
const BASE_URL = process.env.REACT_APP_BASE_URL;
const WIZARD_LINK = process.env.WIZARD_LINK;
const KEY_CLOAK_ENV = process.env.KEY_CLOAK_ENV;
const { Paragraph, Text } = Typography;
const Guestdashboard = () => {
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const [stepData, setStepData] = useState();
  const [development, setDevelopment] = useState(false);
  const [uat, setUat] = useState(false);
  const [appReview, setAppReview] = useState(false);
  const [live, setLive] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const dispatch = useDispatch();

  const [load, setLoad] = useState(true);
  const steps = [
    'design',
    'app_details',
    'payment',
    'development',
    'uat',
    'go_live',
  ];
  const avatarIcons = {
    design: <DesignSvgIcon />,
    app_details: <AdditionalDetalsIcon />,
    payment: <PaymentIcon />,
    development: <DevelopmentIcon />,
    uat: <UatIcon />,
    go_live: <GoLiveIcon />,
  };
  const currentIndex = steps.findIndex(
    (step) => stepData?.[step] === 'incomplete'
  );

  // const onClickNextStep = () => {

  //   // stepData?.payment == "complete"
  //   //   ? history.push("/additional-detail")
  //   //   : history.push("/payment-estimate");
  // };

  // const updateStatus = useCallback(() => {
  //   if (userDetails) {
  //     axios
  //       .get(`${BASE_URL}/api/app-status/${userDetails?.uuid}`)
  //       .then((response) => {
  //         if (response?.data?.header?.code === 200) {
  //           if (response?.data?.body?.uat == "complete") {
  //             window.location.href = "http://localhost:8080";
  //           }
  //           setStepData(response?.data?.body);
  //           setLoad(false);
  //         }
  //       })
  //       .catch((error) => {
  //         message.error("Something went wrong");
  //       });
  //   }
  // }, [userDetails]);

  // useEffect(() => {
  //   // Initial status update
  //   updateStatus();

  //   // Periodically update status every 10 seconds
  //   const intervalId = setInterval(updateStatus, 60000);

  //   return () => {
  //     // Clear the interval when the component unmounts
  //     clearInterval(intervalId);
  //   };
  // }, [updateStatus]);

  useEffect(() => {
    getAppStatus()
      .then((response) => {
        if (response?.data?.header?.code === 200) {
          if (response?.data?.body?.uat == 'complete') {
            window.location.href = `${KEY_CLOAK_ENV}`;
          }
          setStepData(response?.data?.body);
          setLoad(false);
        }
      })
      .catch((error) => message.error('Something went wrong'));
    getResellerPreferences()
      .then((res) => {
        if (res?.data?.header?.code == 200) {
          let updateRes = JSON?.parse(res?.data?.body?.json);
          dispatch(updateStorageJSON(updateRes));
          setLoad(false);
        }
      })
      .catch((error) => {
        message.error('Something went wrong');
        setLoad(false);
      });
  }, []);

  useEffect(() => {
    if (stepData) {
      switch (true) {
        case stepData.development === 'inprogress':
          setDevelopment(true);
          setDashboard(false);
          setUat(false);
          setLive(false);
          break;
        case stepData.uat === 'inprogress':
          setUat(true);
          setDevelopment(false);
          setDashboard(false);
          setLive(false);
          break;
        case stepData.go_live === 'inprogress':
          // history.push("/");
          setLive(true);
          setDashboard(false);
          setDevelopment(false);
          setUat(false);
          break;
        default:
          setDashboard(true);
          setDevelopment(false);
          setUat(false);
          setLive(false);
          break;
      }
    }
  }, [stepData]);

  const onClickNextStep = () => {
    if (stepData.payment === 'inprogress') {
      history.push('/payment-estimate');
    } else if (stepData.app_details === 'inprogress') {
      history.push('/additional-detail');
    } else if (stepData.development === 'inprogress') {
      setDevelopment(true);
      setDashboard(false);
    } else if (stepData.uat === 'inprogress') {
      setUat(true);
      setDashboard(false);
    } else if (stepData.go_live === 'inprogress') {
      setLive(true);
      setDashboard(false);
    } else {
      history.push('/payment-estimate');
    }
  };

  const onClickDevelopmentCross = () => {
    setDashboard(true);
    setDevelopment(false);
    setUat(false);
    setAppReview(false);
    setLive(false);
  };
  return (
    <Spin indicator={antIcon} size='large' spinning={load}>
      <Card className='design-card-guest-dashboard'>
        {development && (
          <DevelopmentScreen onCrossClick={onClickDevelopmentCross} />
        )}
        {uat && <UATScreen onCrossClick={onClickDevelopmentCross} />}
        {appReview && (
          <AppReviewScreen onCrossClick={onClickDevelopmentCross} />
        )}

        {live && <LiveScreen onCrossClick={onClickDevelopmentCross} />}

        {dashboard && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Headings
                  title='Hey There,'
                  titleSize={4}
                  description={
                    !stepData.payment
                      ? `Please create your application first!`
                      : stepData.payment === 'inprogress'
                      ? `Bravo! App Details Completed!`
                      : 'Welcome to your PureWL Dashboard'
                  }
                  space={5}
                  descClass='dashboard-subtitle c-black'
                />
                <Paragraph className='c-gray'>
                  {!stepData.payment
                    ? 'Please click the below button to create your application.'
                    : stepData.paymet === 'inprogress'
                    ? "We're happy to let you know that you have successfully completed your app details steps for the PureWL-Business-Ready VPN Service. Now we just need complete 'Payment Process' to start the development process."
                    : 'You are just a few steps away from launching your application. All you have to do is to complete the remaining steps and go live'}
                </Paragraph>
                <Space>
                  <Button
                    type='primary'
                    htmlType='submit'
                    className='mt-1-5'
                    size='large'
                    onClick={
                      stepData.app_details
                        ? () => onClickNextStep()
                        : () => (window.location.href = WIZARD_LINK)
                    }
                  >
                    {stepData.app_details ? 'Next Step' : 'Create App'}
                  </Button>

                  {/* <Button
                    type="default"
                    htmlType="submit"
                    className="mt-1-5 ml-auto"
                    size="large"
                  >
                    Preview Design
                  </Button> */}
                </Space>
              </Col>
              <Col span={12} className='text-right'>
                <Space direction='vertical' size={'middle'} align='center'>
                  <iframe
                    width='430'
                    height='242'
                    src={`https://www.youtube.com/embed/xT8H7xhkE04`}
                    frameBorder='0'
                    className='video-frame'
                  />
                  <Paragraph className='c-gray center-align-text px-1 '>
                    PureWL effortlessly brings your ideas to life, elevating
                    your brand without hefty tech investments, thanks to
                    PureWL's perfect process. Exciting, easy, and
                    transformative!
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Col span={24} className='text-center'>
              <Card className='design-card-xsmall' bordered>
                <Space className='w-100' direction='vertical'>
                  {/* <Col className="center-align-text mt-70" span={24}></Col> */}
                  <Headings
                    title='Track Your App Status'
                    titleSize={2}
                    space={5}
                  />
                  <Steps
                    initial={0}
                    className='step-style'
                    current={
                      stepData.app_details
                        ? currentIndex === -1
                          ? 5
                          : currentIndex - 1
                        : -1
                    }
                    labelPlacement='vertical'
                  >
                    {steps.map((step, index) => (
                      <Steps.Step
                        key={step}
                        title={
                          <Space direction='vertical'>
                            <Avatar
                              style={{ backgroundColor: '#F5F5F5' }}
                              size={36}
                              icon={avatarIcons[step]}
                            />
                            <Text className='fontSize13Medium text-step'>
                              {step === 'uat'
                                ? 'UAT'
                                : step
                                    .split('_')
                                    .map(
                                      (word) =>
                                        word.charAt(0).toUpperCase() +
                                        word.slice(1)
                                    )
                                    .join(' ')}
                            </Text>{' '}
                          </Space>
                        }
                        icon={
                          stepData && stepData[step] === 'inprogress' ? (
                            // <Col span={24}>
                            <CheckCircleFilled
                              color='#B4BBC3'
                              style={{ color: '#B4BBC3' }}
                            />
                          ) : (
                            // <Lottie animationData={dots} loop={true} style={{si}} />
                            // </Col>
                            <CheckCircleFilled />
                          )
                        }
                      />
                    ))}
                  </Steps>
                </Space>
              </Card>
            </Col>
          </>
        )}
      </Card>
    </Spin>
  );
};
export default Guestdashboard;
