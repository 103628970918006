import axios from "../../../../configs/axiosIntereptor";
import * as action_types from "./constant";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const getUserList = (params) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${BASE_URL}/api/vpn/user-details?sUsername=${params.username}`
    );
    dispatch({
      type: action_types.VPNACCOUNT_DETAILS,
      data: data,
    });
  } catch (error) {}
};

export const getResellerDetals = () => async (dispatch) => {
  try {
    const data = await axios.get(
      `${BASE_URL}/api/profile/account-details`
    );
    dispatch({
      type: action_types.RESELLER_DETAILS,
      data: data?.data?.body,
    });
  } catch (error) {}
};
