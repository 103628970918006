import React, { useState } from "react";
import Login from "../../templates/Login";
import PortalLogin from "../../modules/Login";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// const CAPTCHA_SITE_KEY = process.env.CAPTCHA_SITE_KEY;

export default (props) => {
  const [loading, setLoading] = useState(false);
  return (
    <Login load={loading}>
      {props.Comp == "Login" && (
        // <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_SITE_KEY}>
          <PortalLogin setLoading={setLoading} />
        // </GoogleReCaptchaProvider>
      )}
    </Login>
  );
};
