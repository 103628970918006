import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, message, Spin, Modal } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  InputField,
  InputRadio,
  DateField,
  SelectField,
} from '../../../../atoms/FormElement';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { assignDedicatedIp } from '../../ducks/services';

const antIcon = <LoadingOutlined spin />;

const options = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];

const AssignIp = (props) => {
  const { accountData } = props;
  const [load, setLoad] = useState(false);
  const [dedicatedIpDetail, setDedicatedIpDetail] = useState();
  const [dedicatedIpModal, setdedicatedIpModal] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const resellerDetails = useSelector(
    (state) => state.activeUsers.resellerDetails
  );
  useEffect(() => {
    if (accountData) {
      setValue('username', accountData?.userDetails?.username);
      setValue(
        'period',
        accountData?.userDetails?.expires_at
          ? moment(accountData?.userDetails?.expires_at)
          : ''
      );
      setValue(
        'concurent_login',
        accountData?.preferences?.shared?.multi_login?.value
      );
    }
  }, [accountData]);

  const onSubmit = (value) => {
    setLoad(true);
    let endDate = moment(value?.period);
    let startDate = new Date();
    const timeDiff = startDate - endDate;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const remainingDays = Math.ceil(timeDiff / millisecondsPerDay);
    const payload = {
      vpnUsername: value?.username,
      dedicatedCountry: value?.country?.value,
      dedicatedIp: value?.dedicated_ip,
      period: (remainingDays * -1 + 1).toString(),
      ddosProtected: value?.ddosProtected,
    };
    assignDedicatedIp(payload)
      .then((response) => {
        setLoad(false);
        if (response?.data?.header?.code == 200) {
          setDedicatedIpDetail(response?.data?.body);
          setdedicatedIpModal(true)
          message.success(response?.data?.header?.message);
        } else {
          message.error(response?.data?.header?.message);
        }
      })
      .catch((error) => {
        setLoad(false);
        message.error('Something went wrong');
      });
  };
  return (
    <Spin indicator={antIcon} size='large' spinning={load}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form layout='vertical' onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[24, 30]}>
              <Col span={9}>
                <InputField
                  label='Username'
                  fieldname='username'
                  control={control}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'username',
                    disabled: true,
                  }}
                  rules={{
                    required: 'username required',
                  }}
                  validate={errors.name && 'error'}
                  validMessage={errors.name && errors.name.message}
                />
              </Col>
              <Col span={9}>
                <DateField
                  label='Period'
                  fieldname='period'
                  control={control}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'period',
                    disabledDate: (current) => {
                      return current && current < moment().endOf('day');
                    },
                    format: 'MMMM D, YYYY',
                  }}
                  rules={{
                    required: 'period required',
                    validate: (value) => {
                      let currentDate = new Date();
                      return value <= currentDate
                        ? 'given must be greater than current date'
                        : true;
                    },
                  }}
                  validate={errors.period && 'error'}
                  validMessage={errors.period && errors.period.message}
                />
              </Col>
              <Col span={6}>
                <InputRadio
                  label='DDoS Protected'
                  fieldname='ddosProtected'
                  control={control}
                  options={options}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: '',
                  }}
                  rules={{
                    required: 'required',
                  }}
                  validate={errors.ddosProtected && 'error'}
                  validMessage={
                    errors.ddosProtected && errors.ddosProtected.message
                  }
                />
              </Col>
              <Col span={12}>
                <InputField
                  label='Dedicated ip'
                  fieldname='dedicated_ip'
                  control={control}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'dedicated ip',
                  }}
                  // rules={{
                  //   required: "dedicated ip required",
                  // }}
                  // validate={errors.dedicated_ip && "error"}
                  // validMessage={
                  //   errors.dedicated_ip && errors.dedicated_ip.message
                  // }
                />
              </Col>
              <Col span={12}>
                <SelectField
                  fieldname='country'
                  label='Choose country'
                  control={control}
                  class='mb-1'
                  iProps={{
                    isClearable: false,
                    placeholder: 'Select country',
                  }}
                  // rules={{
                  //   required: "country required",
                  // }}
                  initValue=''
                  selectOption={resellerDetails?.dedicatedProtocolData?.countryProtocols.map(
                    (value) => ({
                      label: value?.sCountry,
                      value: value?.sIso2,
                    })
                  )}
                  // validate={errors.country && "error"}
                  // validMessage={errors.country && errors.country.message}
                />
              </Col>

              <Col span={24}>
                <Button type='primary' htmlType='submit' size='large'>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Modal
        title='Assign DedicatedIp'
        open={dedicatedIpModal}
        footer={null}
        onCancel={() => setdedicatedIpModal(false)}
      >
        <p>Username: {dedicatedIpDetail?.vpnUsername}</p>
        <p>Dedicated Ip: {dedicatedIpDetail?.dedicatedIp}</p>
        <p>Host Name: {dedicatedIpDetail?.hostName}</p>
      </Modal>
    </Spin>
  );
};

export default AssignIp;
