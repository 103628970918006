import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Breadcrumb,
  message,
  Input,
  Space,
  Button,
} from "antd";
import { getConnectedUsers } from "./ducks/action";
import Headings from "../../atoms/Headigs";
import ListCard from "../../molecules/ListCard";
import { SearchOutlined } from "@ant-design/icons";

const getColumnSearchProps = (dataIndex) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const searchInput = useRef(null);
      return (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
              close();
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                close();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
  };
};

const ListCol = [
  {
    title: "User Name",
    dataIndex: "UserName",
    key: "UserName",
    ...getColumnSearchProps("UserName"),
  },
  {
    title: "Connected Country",
    dataIndex: "CallingCountry",
    key: "CallingCountry",
    ...getColumnSearchProps("CallingCountry"),
  },
  {
    title: "Subscription Type",
    dataIndex: "SubscriptionType",
    key: "SubscriptionType",
    ...getColumnSearchProps("SubscriptionType"),
  },
  {
    title: "Total Connection",
    dataIndex: "total_connection",
    key: "total_connection",
    ...getColumnSearchProps("total_connection"),
  },
];

const options = { year: "numeric", month: "long" };
const formattedDate = new Date().toLocaleString("en-US", options);
const ConnectedUsers = () => {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const onTableChange = (pagination, filters, sorter) => {
    setLoading(true);
    setPage(pagination.current);
    getConnectedUsers(pagination.current).then((response) => {
      if (response?.data?.header?.code == 200) {
        setListData(response?.data?.body);
      } else {
        message.error(response?.header?.message);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getConnectedUsers(1).then((response) => {
      if (response?.data?.header?.code == 200) {
        setListData(response?.data?.body);
      } else {
        // message.error(response?.data?.header?.message);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Card className="design-card">
      <Row gutter={[24, 30]}>
        {/* <Col span={24}>
        <Breadcrumb>
          <Breadcrumb.Item>Disrupt</Breadcrumb.Item>
          <Breadcrumb.Item>Connected Users</Breadcrumb.Item>
        </Breadcrumb>
      </Col> */}
        <Col span={12}>
          <Headings
            title="Connected Users "
            titleSize={2}
            description={`Connected users in ${formattedDate}`}
            space={5}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={12}></Col>
        <Col span={24}>
          <ListCard
            ListCol={ListCol}
            ListData={listData?.connectedData}
            listClass="nospace-card"
            onChange={onTableChange}
            loading={loading}
            pagination={{
              total: listData?.total_records,
              showSizeChanger: false,
              defaultCurrent: page,
              pageSize: 10,
            }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ConnectedUsers;
