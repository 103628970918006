import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Button, Image, Space } from "antd";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Headings from "../../atoms/Headigs";
import { Popup } from "../../atoms/Popup";
import paymentSuccessIcon from "../../../assets/img/paymentSuccessIcon.svg"

const KEY_CLOAK_ENV = process.env.KEY_CLOAK_ENV;

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [popUpVisibility, setPopUpVisibility] = useState(false)

  const paymentSuccessData = {
    closable: false,
    visibility: popUpVisibility,
    class: "popup-modal",
    width: 502,
    onCancel: () => setPopUpVisibility(false),
    content: (
      <div className="text-center">
       <Space direction="vertical" >
        <Image
            preview={false}
           
            src={paymentSuccessIcon}
          />
          
        <Space align="center">
        <Headings
        title="Payment Successfull"
        titleSize={1}
        titleClass="font-600"
        space={4}
        description="Thank you for your payment"
        descClass="c-gray"
        align="center"
        />
        </Space>
        </Space>
        
       
      </div>)
  }


  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = props.clientSecret;
    if (!clientSecret) {
      return;
    }
  }, [stripe]);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
 
    const response = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${KEY_CLOAK_ENV}/payment-information/payment-success`,
      },
      redirect: 'if_required'      
    });
   
    if(response?.error){
      if (response?.error.type === "card_error" || response?.error.type === "validation_error") {
        setMessage(response.error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    }else{
    
      //success
      setPopUpVisibility(true)
      function paymentSuccessRedirection() {
        window.location.href = `${KEY_CLOAK_ENV}/payment-information/payment-success?payment_intent=${response.paymentIntent.id}&payment_intent_client_secret=${response.paymentIntent.client_secret}&redirect_status="true"`;
      }
      setTimeout(paymentSuccessRedirection, 2000);
      
    }
    
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <React.Fragment>
   
    {popUpVisibility && <Popup {...paymentSuccessData}/>}
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <Space className="w-100 mt-2" direction="vertical" size={10}>
        <Button
          type="primary"
          disabled={isLoading || !stripe || !elements}
          htmlType="submit"
          className="w-100"
          size="large"
        >
          I’m Ready to Pay
        </Button>
      </Space>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
    </React.Fragment>
  );
}
