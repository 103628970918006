import React, { useState} from "react";
import Dashboard from "../../templates/Dashboard";
import Billing from "../../modules/Billing";
import GuestBilling from "../../modules/GuestBilling"
import { useKeycloak } from "@react-keycloak/web";
import { decodeToken } from 'react-jwt';

const Components = {
    Billing,
    GuestBilling
  };
export default (props) => {
    const userDetail = JSON.parse(localStorage.getItem("userDetails"));
    const { keycloak, initialized } = useKeycloak();

    let token = decodeToken(keycloak?.token);

    let comp=token?.realm_access?.roles?.includes("paid_reseller") ?"Billing":"GuestBilling"
    const [loading, setLoading] = useState(false);
    const BillingComp = Components[comp];


    return (
        <Dashboard load={loading}>
            <BillingComp />
        </Dashboard>
    );
}


