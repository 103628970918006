import React, { useState } from "react";
import Dashboard from "../../templates/Dashboard";
import Reports from "../../modules/Reports";
import GuestReports from "../../modules/GuestReports"
import { useKeycloak } from "@react-keycloak/web";
import { decodeToken } from 'react-jwt';


const Components = {
  Reports,
  GuestReports
};

export default (props) => {

  const { keycloak, initialized } = useKeycloak();
  let token = decodeToken(keycloak?.token);

  let comp=token?.realm_access?.roles?.includes("paid_reseller") ?"Reports":"GuestReports"
  const [loading, setLoading] = useState(false);
  const ReportsComp = Components[comp];
  return (
    <Dashboard load={loading}>
      <ReportsComp />
    </Dashboard>
  );
};
