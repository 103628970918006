import React from 'react';
import {Button, Modal} from 'antd';

const SuccessOrErrorModalComponent = ({ visible, title, onCancel,onOk, errorMessage, successMessage }) => {
    return (
        <Modal
            visible={visible}
            title={title}
            onCancel={onCancel}
            footer={[
                <Button key="okay" type="primary" onClick={onOk}>
                    Okay
                </Button>
            ]}
        >
            {errorMessage && <div style={{ color: 'red', marginBottom: '16px' }}>{errorMessage}</div>}
            {successMessage && <p style={{ color: 'green', marginBottom: '16px' }}>{successMessage}</p>}
        </Modal>
    );
};

export default SuccessOrErrorModalComponent;
