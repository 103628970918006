import axios from "../../../../configs/axiosIntereptor";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getUserActivity = (
  pagination,
  sort_key,
  sort_type,
  search_value
) => {
  return axios.get(
    `${BASE_URL}/api/billing/active-users?page=${pagination}&sSort=${sort_key}&sOrder=${sort_type}&sKeyword=${search_value}`
  );
};
export const getVPNAccountDetails = (params) => {
  return axios.get(
    `${BASE_URL}/api/vpn/user-details?sUsername=${params.acc_id}`
  );
};

export const enableDisableVPNAccount = (payload) => {
  return axios.post(`${BASE_URL}/api/vpn/enable-disable`, payload);
};

export const changePassword = (payload) => {
  return axios.post(`${BASE_URL}/api/vpn/change-password`, payload);
};

export const renewVPNUser = (payload) => {
  return axios.post(`${BASE_URL}/api/vpn/renew`, payload);
};

export const updateSubscription = (payload) => {
  return axios.post(`${BASE_URL}/api/vpn/update-subscription`, payload);
};

export const getAccountDedicatedIp = (query) => {
  return axios.get(`${BASE_URL}/api/vpn/get-dedicatedip?sUsername=${query}`);
};

export const releaseDedicatedIp = (payload) => {
  return axios.post(`${BASE_URL}/api/vpn/release-dedicated-ip`, payload);
};

export const deleteVPNAccount = (payload) => {
  return axios.post(`${BASE_URL}/api/vpn/delete`, payload);
};

export const assignDedicatedIp = (payload) => {
  return axios.post(`${BASE_URL}/api/vpn/assign-dedicated-ip`, payload);
};

export const getLogs = (query) => {
  return axios.get(`${BASE_URL}/api/dashboard/vpn-logs?username=${query}`);
};

export const getAllLogs = (query) => {
  return axios.get(`${BASE_URL}/api/dashboard/all-logs?username=${query}`);
};
