import React, {useEffect, useState} from "react";
import { Spin, Row, Col, Space, Button, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Headings from "../../../../app/atoms/Headigs";
import { getSubUserDetail, deleteSubUser} from "../ducks/services";

const antIcon = <LoadingOutlined spin />;

const Index = (props) => {
    const {subUserEmail, onClose} = props;
    const [load, setLoad] = useState(false);
    const [userDetails, setUserDetails] = useState('')
    useEffect(() => {
        fetchSubUserData(subUserEmail).then(r => {
                if (r?.status == 200) {
                    const {data} = r;
                    setUserDetails(data?.data?.subUserDetail)
                }
            }
        );
    }, [subUserEmail]);

    const fetchSubUserData = async (email) => {
        const response =  await getSubUserDetail(email);
        return response

    };
    const deleteUser = () => {
        setLoad(true);
        deleteSubUser(userDetails.id)
            .then((response) => {
                const {data} = response

                if (data?.status == 200) {
                    setLoad(false);
                    onClose();
                    message.success(data?.message);
                }
                else
                {
                    message.error(response?.data?.header?.message)
                }
            })
            .catch((error) => {
                setLoad(false);
                onClose();
                message.error("Something went wrong");
            });
    };

    return (
        <Spin indicator={antIcon} size="large" spinning={load}>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Headings
                        title={"Delete Account"}
                        titleSize={2}
                        description={`Are you sure you want to delete ${userDetails?.username}`}
                        descClass="mb-0 c-gray"
                    />
                </Col>
                <Col span={24} className="text-right">
                    <Space align="center" direction="horizontal">
                        <Button className="w-100" size="large" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            className="w-100"
                            size="large"
                            onClick={deleteUser}
                        >
                            Delete
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Spin>
    );
};

export default Index;
