import { intersection } from "lodash";
import {Buffer} from 'buffer';

export const updateJson = (json, keyToUpdate, newValue) => {
  for (const prop in json) {
    if (json.hasOwnProperty(prop)) {
      if (typeof json[prop] === "object") {
        updateJson(json[prop], keyToUpdate, newValue);
      } else if (prop === keyToUpdate) {
        json[prop] = newValue;
      }
    }
  }
  return json;
};
const conversion1 = (roles) => {
  // const userDetail = JSON.parse(localStorage.getItem("userDetails"));
  // if (userDetail) {
    let temp =roles?.map((value)=>value)
    return temp;
 // }
};
export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}
export const checkPermission = (permission,roles) => {
  let permit = conversion1(roles);
  if (!isArrayWithLength(permission)) return true;
  else return intersection(permission, permit).length;
};

//ENCODED LOCAL STORAGE
export const setLocalStorage = function (key, val, encoded = true) {
  let ret;
  if (encoded) {
      ret = localStorage.setItem(Buffer.from(key).toString('base64'), Buffer.from(val).toString('base64'));
  } else {
      ret = localStorage.setItem(key, val);
  }
  return ret;
};
export const removeLocalStorage = function (key, encoded = true) {
  if (encoded) {
      return localStorage.removeItem(Buffer.from(key).toString('base64'));
  } else {
      return localStorage.removeItem(key);
  }
};
export const getLocalStorage = function (key, encoded = true) {
  try {
      if (encoded) {
          key = Buffer.from(key).toString('base64');
      }
      let ret = localStorage.getItem(key);
      if (encoded && ret !== null) {
          ret = Buffer.from(ret, 'base64').toString();
      }
      return ret;
  } catch (e) {
      return logout();
      //return <Redirect to={'/login'}/>
  }
};

export const validateJSON = (string, defaultVal = {}) => {
  try {
      return JSON.parse(string);
  } catch (er) {
      return defaultVal;
  }
}