import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Select, message, Spin } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  InputField,
  InputRadio,
  DateField,
  InputNumberField,
  SelectField,
} from '../../../../atoms/FormElement';
import { useForm } from 'react-hook-form';
import { renewVPNUser, updateSubscription } from '../../ducks/services';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined spin />;

const options = [
  { label: 'Paid', value: 'paid' },
  { label: 'Trial', value: 'trial' },
];

const Renew = (props) => {
  const { accountData } = props;
  const [load, setLoad] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const resellerDetails = useSelector(
    (state) => state.activeUsers.resellerDetails
  );
  const allProtocols = useSelector((state) => state.global.all_protocols);

  useEffect(() => {
    if (accountData) {
      setValue('username', accountData?.userDetails?.username);
      setValue(
        'period',
        accountData?.userDetails?.expires_at
          ? moment(accountData?.userDetails?.expires_at)
          : ''
      );
      setValue('package', accountData?.userDetails?.subscription_type);
      setValue(
        'concurent_login',
        accountData?.preferences?.[accountData?.userDetails?.account_type]
          ?.multi_login?.value
      );
      setValue(
        'session_day',
        accountData?.preferences?.[accountData?.userDetails?.account_type]
          ?.session_limit?.value
      );
      setValue(
        'country',
        accountData?.preferences?.[
          accountData?.userDetails?.account_type
        ]?.country?.map((value) => ({
          label: value?.country_name,
          value: value?.id,
        }))
      );
      setValue(
        'protocols',
        accountData?.preferences?.[
          accountData?.userDetails?.account_type
        ]?.protocol?.map((value) => ({
          label: value?.protocol_name,
          value: value?.id,
        }))
      );
    }
  }, [accountData]);

  const onSubmit = (value) => {
    setLoad(true);
    let endDate = moment(value?.period);
    let startDate = new Date();
    const timeDiff = startDate - endDate;
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const remainingDays = Math.ceil(timeDiff / millisecondsPerDay);
    const prefData = {
      multi_login: value?.concurent_login.toString(),
      session_limit: value?.session_day,
      protocol: value?.protocols?.map((value) => value?.value),
      country: value?.country?.map((value) => value?.value),
    };
    const payload = {
      vpnUsername: accountData?.userDetails?.username,
      period: (remainingDays * -1 + 1).toString(),
      preferences: JSON.stringify(prefData),
      preferences_format: 'keys',
    };
    renewVPNUser(payload)
      .then((res) => {
        if (res?.data?.header?.code == 200) {
          const subPayload = {
            vpnUsername: accountData?.userDetails?.username,
            subscriptionType: value?.package,
          };
          updateSubscription(subPayload).then((res2) => {
            if (res2?.data?.header?.code == 200) {
              message.success(res2?.data?.header?.message);
            } else {
              message.error(res2?.data?.header?.message);
            }
          });
        } else {
          message.error(res?.data?.header?.message);
        }
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        message.error('Something went wrong');
      });
  };
  return (
    <Spin indicator={antIcon} size='large' spinning={load}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Form layout='vertical' onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[24, 30]}>
              <Col span={9}>
                <InputField
                  label='Username'
                  fieldname='username'
                  control={control}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'username',
                    disabled: true,
                  }}
                  rules={{
                    required: 'username required',
                  }}
                  validate={errors.name && 'error'}
                  validMessage={errors.name && errors.name.message}
                />
              </Col>
              <Col span={9}>
                <DateField
                  label='Period'
                  fieldname='period'
                  control={control}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'period',
                    disabledDate: (current) => {
                      return current && current < moment().endOf('day');
                    },
                    format: 'MMMM D, YYYY',
                  }}
                  rules={{
                    required: 'period required',
                    validate: (value) => {
                      let currentDate = new Date();
                      return value <= currentDate
                        ? 'given must be greater than current date'
                        : true;
                    },
                  }}
                  validate={errors.period && 'error'}
                  validMessage={errors.period && errors.period.message}
                />
              </Col>
              <Col span={6}>
                <InputRadio
                  label='Select Package'
                  fieldname='package'
                  control={control}
                  options={options}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'package',
                  }}
                  rules={{
                    required: 'package required',
                  }}
                  validate={errors.package && 'error'}
                  validMessage={errors.package && errors.package.message}
                />
              </Col>
              <Col span={12}>
                <InputNumberField
                  label='Allowed concurrent login'
                  fieldname='concurent_login'
                  control={control}
                  initValue=''
                  class='width100percent'
                  iProps={{
                    size: 'large',
                    placeholder: 'concurent_login',
                    max: 5,
                  }}
                  rules={{
                    required: 'concurent_login required',
                  }}
                  validate={errors.concurent_login && 'error'}
                  validMessage={
                    errors.concurent_login && errors.concurent_login.message
                  }
                />
              </Col>
              <Col span={12}>
                <InputField
                  label='Allowed sessions per day'
                  fieldname='session_day'
                  control={control}
                  initValue=''
                  iProps={{
                    size: 'large',
                    placeholder: 'session_day',
                  }}
                  rules={{
                    required: 'session_day required',
                  }}
                  validate={errors.session_day && 'error'}
                  validMessage={
                    errors.session_day && errors.session_day.message
                  }
                />
              </Col>
              <Col span={12}>
                <SelectField
                  fieldname='country'
                  label='Choose country'
                  control={control}
                  class='mb-1'
                  iProps={{
                    isClearable: false,
                    isMulti: true,
                    placeholder: 'Select country',
                  }}
                  rules={{
                    required: 'country required',
                  }}
                  initValue=''
                  selectOption={resellerDetails?.sharedProtocolData?.countryProtocols.map(
                    (value) => ({
                      label: value?.sCountry,
                      value: value?.iCountryId,
                    })
                  )}
                  validate={errors.country && 'error'}
                  validMessage={errors.country && errors.country.message}
                />
              </Col>
              <Col span={12}>
                <SelectField
                  fieldname='protocols'
                  label='Choose protocols'
                  control={control}
                  class='mb-1'
                  iProps={{
                    isClearable: false,
                    isMulti: true,
                    placeholder: 'Select protocol',
                  }}
                  rules={{
                    required: 'protocols required',
                  }}
                  initValue=''
                  selectOption={allProtocols?.map((value) => ({
                    label: value?.name,
                    value: value?.id,
                  }))}
                  validate={errors.protocols && 'error'}
                  // onChange={(e) => getCities(e)}
                  validMessage={errors.protocols && errors.protocols.message}
                />
              </Col>
              <Col span={24}>
                <Button type='primary' htmlType='submit' size='large'>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default Renew;
