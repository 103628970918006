import Roles from "../../configs/Roles";

const titles = {
    Billing: 'Billing',
};

export const Billing = [
    {
        icon: 'InvoicesNavSvgIcon',
        component: 'Billing',
        path: '/billing',
        title: titles.Billing,
        key: 'billing',
        menu: 'Billing',
        parent: false,
        // permission:[Roles.RESELLER]
    },
];
