// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 600px) {
    .default-form-css .ant-spin-nested-loading {
      width: 25%;
    }
    }
    
    .default-form-css form#subUserForm {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 20px;
      border-radius: 10px;
      margin-top: 30px;
    }
    
    .default-form-css form#subUserForm  span.ant-input-affix-wrapper.ant-input-password {
      padding: 11.5px 15px;
    }

    .default-form-css form#subUserForm  button.ant-btn.ant-btn-primary {
      width: 100%;
      height: 50.5px;
      margin-top:15px;
    }
    
    @media only screen and (max-width: 600px) {
    
      .default-form-css .ant-spin-nested-loading {
        width: 340px;
    }
    }
`, "",{"version":3,"sources":["webpack://./src/app/Pages/SubUsers/SignupForm.css"],"names":[],"mappings":"AAAA;IACI;MACE,UAAU;IACZ;IACA;;IAEA;MACE,2CAA2C;MAC3C,aAAa;MACb,mBAAmB;MACnB,gBAAgB;IAClB;;IAEA;MACE,oBAAoB;IACtB;;IAEA;MACE,WAAW;MACX,cAAc;MACd,eAAe;IACjB;;IAEA;;MAEE;QACE,YAAY;IAChB;IACA","sourcesContent":["@media only screen and (min-width: 600px) {\n    .default-form-css .ant-spin-nested-loading {\n      width: 25%;\n    }\n    }\n    \n    .default-form-css form#subUserForm {\n      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\n      padding: 20px;\n      border-radius: 10px;\n      margin-top: 30px;\n    }\n    \n    .default-form-css form#subUserForm  span.ant-input-affix-wrapper.ant-input-password {\n      padding: 11.5px 15px;\n    }\n\n    .default-form-css form#subUserForm  button.ant-btn.ant-btn-primary {\n      width: 100%;\n      height: 50.5px;\n      margin-top:15px;\n    }\n    \n    @media only screen and (max-width: 600px) {\n    \n      .default-form-css .ant-spin-nested-loading {\n        width: 340px;\n    }\n    }\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
