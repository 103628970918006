import React, { useState } from 'react';
import { Form, Input, Button, notification, Spin } from 'antd';
import { createSubUser } from "./ducks/services";
import {Redirect, useLocation} from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const KEY_CLOAK_ENV = process.env.KEY_CLOAK_ENV;

const SubUserForm = () => {
    const [form] = Form.useForm();
    const query = useQuery(); // Call useQuery at the top level of the component
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        try {
            setLoading(true);
            const link = query.get('link');
            const payload = { ...values, link };
            // Make a POST request to your API endpoint with the form values
            const { data } = await createSubUser(payload);
            if (data.status == 200) {
              notification.success({
                message: 'Account Created Successfully',
                description: 'This user has been added successfully.',
                placement: 'bottomRight' // Adjust the placement as needed
              });
              form.resetFields();
                setTimeout(() => {
                    window.location.href = `${KEY_CLOAK_ENV}`;
                }, 2000); // Delay redirection by 3 seconds
            }
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Failed to Add User',
                description: error?.response?.data?.message ?? 'An error occurred while adding the user. Please try again later.',
                placement: 'bottomRight' // Adjust the placement as needed
            });
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <Spin spinning={loading}>
        <Form
            form={form}
            name="subUserForm"
            onFinish={onFinish}
            layout="vertical"
        >
            <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: 'Please enter a first name' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: false, message: 'Please enter a last name' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="username"
                label="Username"
                rules={[{ required: true, message: 'Please enter a username' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please enter a password' }]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
        </Spin>
    );
};

export default SubUserForm;
