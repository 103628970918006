import Roles from "../../configs/Roles";

const titles = {
    DOWNLOAD_ATOM_SDKS: 'DownloadAtomSDKs',
};

export const DownloadAtomSDK = [
    {
        icon: 'DownloadSdkSvgIcon',
        component: 'DownloadAtomSDKs',
        path: '/download-sdk',
        title: titles.DOWNLOAD_ATOM_SDKS,
        key: 'DownloadAtomSDKs',
        menu: 'Download SDK',
        parent: true,
        permission:[Roles.RESELLER]
    },
];