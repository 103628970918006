import Roles from "../../configs/Roles";
import { DashboardDetailSvg } from "../../app/atoms/CustomIcons";

const titles = {
    ACCOUNTDETAILS: 'AccountDetails',
};

export const AccountDetails = [
    {
        icon: 'DashboardDetailSvg',
        component: 'ResellerAccountDetails',
        path: '/account-details',
        title: titles.ACCOUNTDETAILS,
        key: 'AccountDetails',
        menu: 'Account Details',
        parent: true,
        
        // permission:[Roles.RESELLER]
    },
];