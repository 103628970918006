import React from "react";
import { Row, Space, Typography, Tooltip } from "antd";
const Headings = (props) => {
  const { title, description, space, titleClass, descClass, titleSize } = props;
  const { Title, Text } = Typography;
  const InfoIcon = props.icon && props.icon;
  return (
    <Space direction="vertical" size={space}>
      {title && (
        <Row>
          <Title level={titleSize} className={`${titleClass} mb-0`}>
            {title}
          </Title>
          {props.icon && (
            <Tooltip overlayStyle={{maxWidth: 500}} className="ml-5" placement="right" title={props.tooltipText}>
              <InfoIcon className="defaultFont"/>
            </Tooltip>
          )}
        </Row>
      )}
      {description && <Text className={descClass}>{description}</Text>}
    </Space>
  );
};

export default Headings;
