import * as action_type from "./constant";

const initialState = {
  accountDetailsJSON: {}
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_type.ACCOUNT_DETAILS_JSON:
      return { ...state, accountDetailsJSON: data };
    default:
      return state;
  }
};
