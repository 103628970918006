import Roles from "../../configs/Roles";

const titles = {
    ACTIVEUSERS: 'ActiveUsers',
};

export const ActiveUsers = [
    {
        icon: 'UserNavSvgIcon',
        component: 'ActiveUsers',
        path: '/active-users',
        title: titles.ACTIVEUSERS,
        key: 'activeuser',
        menu: 'VPN Users',
        parent: true,
        // permission:[Roles.RESELLER]
    },
    {
        component: 'DetailVPNAccount',
        path: '/active-users/detail/:id',
        title: titles.ACTIVEUSERS,
        key: 'activeuser',
        menu: 'VPN Users',
        parent: false,
        permission:[Roles.RESELLER]
    },
    {
        component: 'ManageVPNAccount',
        path: '/active-users/manage/:id',
        title: titles.ACTIVEUSERS,
        key: 'activeuser',
        menu: 'VPN Users',
        parent: false,
        permission:[Roles.RESELLER]
    },
];