import * as action_types from "./constants";

const initialState = {
  amount: false,
  users: 5000,
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.STRIPE_AMOUNT:
      return { ...state, amount: data };
    case action_types.USER_BASE:
      return { ...state, users: data };
    default:
      return state;
  }
};
