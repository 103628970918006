import React, { useState, useEffect, useRef } from "react";
import { Typography, Input, Button, Space, message } from "antd";
import { getBilling } from "../../ducks/action";
import ListCard from "../../../../molecules/ListCard";
import { SearchOutlined } from "@ant-design/icons";

const renderActionColumn = (text, record) => {
  if (record.status === "paid") {
    return (
      <a href={text} target="_blank" rel="noopener noreferrer">
        View Invoice
      </a>
    );
  } else {
    return (
      <a href={text} target="_blank" rel="noopener noreferrer">
        Pay Invoice
      </a>
    );
  }
};

const renderStatusColumn = (text, record) => {
  if (record.status) {
    const capitalizedStatus =
      record.status.charAt(0).toUpperCase() + record.status.slice(1);

    return <Typography>{capitalizedStatus}</Typography>;
  }
};
const getColumnSearchProps = (dataIndex) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      const searchInput = useRef(null);
      return (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
              close();
            }}
            style={{
              marginBottom: 8,
              display: "block",
            }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                confirm();
                close();
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close();
              }}
            >
              close
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ?.toString()
        ?.toLowerCase()
        ?.includes(value?.toLowerCase()),
  };
};
const columns = [
  {
    title: "Invoice #",
    dataIndex: "invoice_uid",
    key: "invoice_uid",
    sorter: true,
    ...getColumnSearchProps("invoice_uid"),
  },
  {
    title: "Amount",
    dataIndex: "total_amount",
    key: "total_amount",
    sorter: true,
    ...getColumnSearchProps("total_amount"),
  },
  {
    title: "Invoice month",
    dataIndex: "created_at",
    key: "created_at",
    sorter: true,
    ...getColumnSearchProps("created_at"),
  },
  {
    title: "Payment Date",
    dataIndex: "payment_date",
    key: "payment_date",
    sorter: true,
    ...getColumnSearchProps("payment_date"),
  },
  {
    title: "Status",
    dataIndex: "status",
    sorter: true,
    render: renderStatusColumn,
    ...getColumnSearchProps("status"),
  },
  {
    title: "Action",
    dataIndex: "action",
    render: renderActionColumn,
  },
];

const index = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const onTableChange = (pagination, filters, sorter) => {
    let order =
      sorter?.order == "descend"
        ? "desc"
        : sorter?.order == "ascend"
        ? "asc"
        : "";
    setLoading(true);
    setPage(pagination.current);
    let skip = (pagination.current - 1) * 25;
    getBilling(skip, sorter?.field ? sorter?.field : "", order)
      .then((responseData) => {
        if (responseData?.data?.header?.code == 200) {
          setData(responseData?.data?.body);
        } else {
          setData([]);
        }
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        setLoading(false); // Set loading to false on success
      });
  };

  useEffect(() => {
    getBilling(0, "", "")
      .then((responseData) => {
        if (responseData?.data?.header?.code == 200) {
          setData(responseData?.data?.body?.data);
        } else {
          setData([]);
        }
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        setLoading(false); // Set loading to false on success
      });
  }, []);

  return (
    // <Table columns={columns} dataSource={data} />
    <ListCard
      ListCol={columns}
      ListData={data?.data}
      onChange={onTableChange}
      listClass="nospace-card"
      loading={loading}
      pagination={{
        total: data?.total_records,
        defaultCurrent: page,
        showSizeChanger: false,
        pageSize: 10,
      }}
    />
  );
};

export default index;
