import React, { useState } from "react";
import { Spin, Row, Col, Space, Button, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Headings from "../../../../../atoms/Headigs";
import { deleteVPNAccount } from "../../../ducks/services";

const antIcon = <LoadingOutlined spin />;

const DeleteVPNAccountPopup = (props) => {
  const { accountDetails, onClose } = props;
  const [load, setLoad] = useState(false);
  const deleteUser = (values) => {
    setLoad(true);
    const payload = {
      vpnUsername: accountDetails?.username,
    };
    deleteVPNAccount(payload)
      .then((response) => {
        if (response?.data?.header?.code == 200) {
          setLoad(false);
          let data = response?.data?.body?.data;
          if (data?.status == "1") {
            onClose();
            // message.success(data?.message);
          } else {
            // message.error(data?.message);
          }
        }
        else
        {
          message.error(response?.data?.header?.message)
        }
      })
      .catch((error) => {
        setLoad(false);
        onClose();
        message.error("Something went wrong");
      });
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={load}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Headings
            title={"Delete Account"}
            titleSize={2}
            description={`Are you sure you want to delete ${accountDetails?.username}`}
            descClass="mb-0 c-gray"
          />
        </Col>
        <Col span={24} className="text-right">
          <Space align="center" direction="horizontal">
            <Button className="w-100" size="large" onClick={onClose}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="w-100"
              size="large"
              onClick={deleteUser}
            >
              Delete
            </Button>
          </Space>
        </Col>
      </Row>
    </Spin>
  );
};

export default DeleteVPNAccountPopup;
